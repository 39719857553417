import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromAsset from '../store/asset/asset.reducer';
import * as fromBvuser from '../store/bvuser/bvuser.reducer';
import * as fromAdmin from '../store/administrator/administrator.reducer';
import * as fromAssetOwner from '../store/assets-owner/assets-owner.reducer';
import * as fromMeasurementPoint from '../store/measurement-point/measurement-point.reducer';
import * as fromAssetType from '../store/asset-type/asset-type.reducer';
import * as fromDevice from '../store/device/device.reducer';
import * as fromDeviceType from '../store/device-type/device-type.reducer';
import * as fromAssociation from '../store/association/association.reducer';
import * as fromAlertType from '../store/alert-type/alert-type.reducer';
import * as fromSortFilter from '../store/sort-filter/sort-filter.reducer';
import { Association } from '../models/association.model';

export interface State {
  admins: fromAdmin.State;
  alertType: fromAlertType.State;
  assets: fromAsset.State;
  assetType: fromAssetType.State;
  assetOwners: fromAssetOwner.State;
  associations: fromAssociation.State;
  bvuser: fromBvuser.State;
  devices: fromDevice.State;
  deviceType: fromDeviceType.State;
  measurementPoints: fromMeasurementPoint.State;
  sortFilter: fromSortFilter.State
}

export const reducers: ActionReducerMap<State> = {
  admins: fromAdmin.reducer,
  alertType: fromAlertType.reducer,
  assets: fromAsset.reducer,
  assetType: fromAssetType.reducer,
  assetOwners: fromAssetOwner.reducer,
  associations: fromAssociation.reducer,
  bvuser: fromBvuser.reducer,
  devices: fromDevice.reducer,
  deviceType: fromDeviceType.reducer,
  measurementPoints: fromMeasurementPoint.reducer,
  sortFilter: fromSortFilter.reducer,
};


// Sort filter device features
export const selectSortFilterState = createFeatureSelector<State, fromSortFilter.State>('sortFilter');

export const selectFeatureSortFilterDevice = createSelector(
  selectSortFilterState,
  (state: fromSortFilter.State) => state.device
);

//BvUserFeatures
export const selectBvuserState = createFeatureSelector<State, fromBvuser.State>('bvuser');
 
export const selectFeatureBvuserEntity = createSelector(
  selectBvuserState,
  (state: fromBvuser.State) => state.bvuserEntity
);

export const selectFeatureBvuserType = createSelector(
  selectBvuserState,
  (state: fromBvuser.State) => state.bvuserType
);

export const selectFeatureBvuserPreferences = createSelector(
  selectBvuserState,
  (state: fromBvuser.State) => state.preferences
);

export const selectFeatureBvuserPreferencesState = createSelector(
  selectBvuserState,
  (state: fromBvuser.State) => {
    return { state: state.prefState, errorMessage: state.errorMessage }
  }
);

export const selectFeatureBvuserIsAuthenticated = createSelector(
  selectBvuserState,
  (state: fromBvuser.State) => state.isAuthenticated
);

export const selectFeatureBvuserErrorMessage = createSelector(
  selectBvuserState,
  (state: fromBvuser.State) => state.errorMessage
);

export const selectFeatureBvuserAdminOrganizationsEntities = createSelector(
  selectBvuserState,
  (state: fromBvuser.State) => state.bvuserEntity && state.bvuserEntity.adminOrganizationEntities? 
      state.bvuserEntity.adminOrganizationEntities.sort((a, b) => a.name.localeCompare(b.name)) : null
);

export const selectFeatureBvuserManagingOrganizations = createSelector(
  selectBvuserState,
  (state: fromBvuser.State) => state.bvuserEntity && state.bvuserEntity.managingOrganizations? 
      state.bvuserEntity.managingOrganizations.sort((a, b) => a.name.localeCompare(b.name)) : null
);

// Administrator features
export const selectAdminState = createFeatureSelector<State, fromAdmin.State>('admins');

export const selectFeatureAdminEntities = createSelector(
  selectAdminState,
  (state: fromAdmin.State) => state.entities
);

export const selectFeatureAdminIds = createSelector(
  selectAdminState,
  (state: fromAdmin.State) => state.ids
)

export const selectFeatureAdminStateAndError = createSelector(
  selectAdminState, 
  (state: fromAdmin.State) => {
    return { state: state.isLoading, error: state.errorMsg }
  }
)

export const selectFeatureSingleAdminEntity = createSelector(
  selectAdminState,
  (state: fromAdmin.State, props) => state.entities[props.id]
);

export const selectFeatureAdminOrganizationEntitiesOfSingleAdminById = createSelector(
  selectAdminState,
  (state: fromAdmin.State, props) => state.entities[props.id]? state.entities[props.id].adminOrganizationEntities : null
);

// entities - à voir si l'on a besoin de conserver
export const {
  selectIds: getAdminsIds,
  selectEntities: getAdminsEntities,
  selectAll: getAllAdmins,
  selectTotal: getTotalAdmins,
} = fromAdmin.adapter.getSelectors(selectAdminState);

export const selectFeatureAdminEntitiesAsArray = createSelector(
  selectFeatureAdminEntities,
  selectFeatureAdminIds,
  (entities, ids:string[]) => {
    return ids
      .map(id => entities[id])
      //.filter((asset) => asset != null);
  }
);

// Asset owner features
export const selectAssetOwnerState = createFeatureSelector<State, fromAssetOwner.State>('assetOwners');

export const selectFeaturAssetOwnerEntities = createSelector(
  selectAssetOwnerState,
  (state: fromAssetOwner.State) => state.entities
);

export const selectFeatureAssetOwnerIds = createSelector(
  selectAssetOwnerState,
  (state: fromAssetOwner.State) => state.ids
)

export const selectFeatureAssetsOwnerStateAndError = createSelector(
  selectAssetOwnerState, 
  (state: fromAssetOwner.State) => {
    return { state: state.isLoading, error: state.errorMsg }
  }
)

export const selectFeatureSingleAssetOwnerEntity = createSelector(
  selectAssetOwnerState,
  (state: fromAssetOwner.State, props) => state.entities[props.id]
);

export const selectFeatureAdminOrganizationEntitiesOfSingleAssetOwnerById = createSelector(
  selectAssetOwnerState,
  (state: fromAssetOwner.State, props) => state.entities[props.id]? state.entities[props.id].adminOrganizationEntities : null
);

// entities - à voir si l'on a besoin de conserver
export const {
  selectIds: getAssetOwnerIds,
  selectEntities: getAssetOwnerEntities,
  selectAll: getAllAssetOwners,
  selectTotal: getTotalAssetOwners,
} = fromAssetOwner.adapter.getSelectors(selectAssetOwnerState);

export const selectFeatureAssetOwnerEntitiesAsArray = createSelector(
  selectFeaturAssetOwnerEntities,
  selectFeatureAssetOwnerIds,
  (entities, ids:string[]) => {
    return ids
      .map(id => entities[id])
      //.filter((asset) => asset != null);
  }
);

// Asset type features
export const selectAssetTypeState = createFeatureSelector<State, fromAssetType.State>('assetType');
 
export const selectFeatureAssetTypeEntities = createSelector(
  selectAssetTypeState,
  (state: fromAssetType.State) => state.entities
);

//Assets features
export const selectAssetsState = createFeatureSelector<State, fromAsset.State>('assets');
 
export const selectFeatureAssetsEntities = createSelector(
  selectAssetsState,
  (state: fromAsset.State) => state.entities
);

export const selectFeatureAssetsEntitiesAndLoadState = createSelector(
  selectAssetsState,
  (state: fromAsset.State) => {
    return { entities: state.entities, state: state.isLoading }
  }
);

export const selectFeatureAssetsIds = createSelector(
  selectAssetsState,
  (state: fromAsset.State) => state.ids
);

export const selectFeatureAssetsLoadState = createSelector(
  selectAssetsState,
  (state: fromAsset.State) => state.isLoading
);

export const selectFeatureSingleAssetById = createSelector(
  selectAssetsState,
  (state: fromAsset.State, props) => state.entities[props.id]
);

// entities - à voir si l'on a besoin de conserver
export const {
  selectIds: getAssetsIds,
  selectEntities: getAssetsEntities,
  selectAll: getAllAssets,
  selectTotal: getTotalAssets,
} = fromAsset.adapter.getSelectors(selectAssetsState);

export const selectFeatureAssetsEntitiesAsArray = createSelector(
  selectFeatureAssetsEntities,
  selectFeatureAssetsIds,
  (entities, ids:string[]) => {
    return ids
      .map(id => entities[id])
      .sort((a, b) => a.reference.localeCompare(b.reference))
  }
);

export const selectFeatureAssetsIsLoading = createSelector(
  selectAssetsState,
  (state: fromAsset.State) => state.isLoading
);

export const selectFeatureAssetsSelectedAssetId = createSelector(
  selectAssetsState,
  (state: fromAsset.State) => state.selectedId
);

//MeasurementPoint features
export const selectMeasurementPointsState = createFeatureSelector<State, fromMeasurementPoint.State>('measurementPoints');
 
export const selectFeatureMeasurementPointsEntities = createSelector(
  selectMeasurementPointsState,
  (state: fromMeasurementPoint.State) => state.entities
);

export const selectFeatureMeasurementPointsIds = createSelector(
  selectMeasurementPointsState,
  (state: fromMeasurementPoint.State) => state.ids
);

export const selectFeatureSingleMeasurementPointsById = createSelector(
  selectMeasurementPointsState,
  (state: fromMeasurementPoint.State, props) => state.entities[props.id]
);

// entities - à voir si l'on a besoin de conserver
export const {
  selectIds: getMeasurementPointsIds,
  selectEntities: getMeasurementPointsEntities,
  selectAll: getAllMeasurementPoints,
  selectTotal: getTotalMeasurementPoints,
} = fromMeasurementPoint.adapter.getSelectors(selectMeasurementPointsState);

export const selectFeatureMeasurementPointsEntitiesAsArray = createSelector(
  selectFeatureMeasurementPointsEntities,
  selectFeatureMeasurementPointsIds,
  (entities, ids:string[]) => {
    return ids
      .map(id => entities[id])
      .sort((a,b) => a.name.localeCompare(b.name))
      //.filter((asset) => asset != null);
  }
);

export const selectFeatureMeasurementPointsIsLoading = createSelector(
  selectMeasurementPointsState,
  (state: fromMeasurementPoint.State) => state.isLoading
);

//DeviceType type features
export const selectDeviceTypeState = createFeatureSelector<State, fromDeviceType.State>('deviceType');
 
export const selectFeatureDeviceTypeEntities = createSelector(
  selectDeviceTypeState,
  (state: fromDeviceType.State) => state.entities
);

//Device features
export const selectDevicesState = createFeatureSelector<State, fromDevice.State>('devices');
 
export const selectFeatureDevicesEntities = createSelector(
  selectDevicesState,
  (state: fromDevice.State) => state.entities
);

export const selectFeatureDevicesIds = createSelector(
  selectDevicesState,
  (state: fromDevice.State) => state.ids
);

export const selectFeatureSingleDeviceById = createSelector(
  selectDevicesState,
  (state: fromDevice.State, props) => state.entities[props.id]
);

export const selectFeatureDevicesLoadState = createSelector(
  selectDevicesState,
  (state: fromDevice.State) => state.isLoading
);

// entities - à voir si l'on a besoin de conserver
export const {
  selectIds: getDevicesIds,
  selectEntities: getDevicesEntities,
  selectAll: getAllDevices,
  selectTotal: getTotalDevices,
} = fromDevice.adapter.getSelectors(selectDevicesState);

export const selectFeatureDevicesEntitiesAsArray = createSelector(
  selectFeatureDevicesEntities,
  selectFeatureDevicesIds,
  (entities, ids:string[]) => {
    return ids
      .map(id => entities[id])
      //.filter((asset) => asset != null);
  }
);

export const selectFeatureDevicesIsLoading = createSelector(
  selectDevicesState,
  (state: fromDevice.State) => state.isLoading
);

export const selectFeatureDevicesEntitiesAsArrayAndLoadState = createSelector(
  selectFeatureDevicesEntities,
  selectFeatureDevicesIds,
  selectDevicesState,
  (entities, ids:string[], state: fromDevice.State) => {
    return { 
      devices: ids.map(id => entities[id]),
      state: state.isLoading
    }
  }
)

//Association features
export const selectAssociationsState = createFeatureSelector<State, fromAssociation.State>('associations');
 
export const selectFeatureAssociationsEntities = createSelector(
  selectAssociationsState,
  (state: fromAssociation.State) => state.entities
);

export const selectFeatureAssociationsIds = createSelector(
  selectAssociationsState,
  (state: fromAssociation.State) => state.ids
);

export const selectFeatureSingleAssociationById = createSelector(
  selectAssociationsState,
  (state: fromAssociation.State, props) => state.entities[props.id]
);

// entities - à voir si l'on a besoin de conserver
export const {
  selectIds: getAssociationsIds,
  selectEntities: getAssociationEntities,
  selectAll: getAllAssociations,
  selectTotal: getTotalAssociations,
} = fromAssociation.adapter.getSelectors(selectAssociationsState);

export const selectFeatureAssociationsEntitiesAsArray = createSelector(
  selectFeatureAssociationsEntities,
  selectFeatureAssociationsIds,
  (entities, ids:string[]) => {
    return ids
      .map(id => entities[id])
      //.filter((asset) => asset != null);
  }
);

export const selectFeatureAssociationsEntitiesAsArrayByMeasurementPoint = createSelector(
  selectFeatureAssociationsEntities,
  selectFeatureAssociationsIds,
  (entities, ids:string[], props) => {
    return ids
      .map(id => entities[id])
      .filter((association: Association) => (association.measurementPoint != null))
      .filter((association) => (association.measurementPoint.identifier == props.measurementPointId))
  }
);

export const selectFeatureAssociationsEntitiesAsArrayByAsset = createSelector(
  selectFeatureAssociationsEntities,
  selectFeatureAssociationsIds,
  selectFeatureAssetsSelectedAssetId,
  (entities, ids:string[], assetId: string) => {
    return ids
      .map(id => entities[id])
      .filter((association: Association) => association.asset.identifier == assetId);
  }
);

export const selectFeatureAssociationsIsLoading = createSelector(
  selectAssociationsState,
  (state: fromAssociation.State) => state.isLoading
);

// AlertType Feature
export const selectAlertTypeState = createFeatureSelector<State, fromAlertType.State>('alertType');
 
export const selectFeatureAlertTypeEntities = createSelector(
  selectAlertTypeState,
  (state: fromAlertType.State) => state.entities
);

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}


export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger] : [];
