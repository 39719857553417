import { Administrator, AssetsOwner } from "../models/bvuser.model";
import { Organization } from "../models/organization.model";


export class BvuserMapHelper {
    
    static checkIfManagingOrganizationsMatched(bvuserManagingOrganizations: Organization[], targetUserManagingOrganizations: Organization[]): boolean {
        if(bvuserManagingOrganizations === null || bvuserManagingOrganizations.length === 0) return false;
        const IDs: string[] = bvuserManagingOrganizations.map(org => org.identifier);
        return targetUserManagingOrganizations.every(org => IDs.includes(org.identifier))
    }

    static updateAdministrators(bvuserManagingOrganizations: Organization[], administrator: Administrator): Administrator {
        const IDs: string[] = administrator.managingOrganizations.map(org => org.identifier);
        let organizationEntities: Organization[] = [];
        bvuserManagingOrganizations.forEach(org => {
            if(IDs.includes(org.identifier)) {
                organizationEntities.push(org);
            }
        })
        administrator.managingOrganizations = organizationEntities;
        return administrator;
    }

    static updateAssetOwners(bvuserManagingOrganizations: Organization[], assetowner: AssetsOwner): AssetsOwner {
        const IDs: string[] = assetowner.managingOrganizations.map(org => org.identifier);
        let organizationEntities: Organization[] = [];
        bvuserManagingOrganizations.forEach(org => {
            if(IDs.includes(org.identifier)) {
                organizationEntities.push(org);
            }
        })
        assetowner.managingOrganizations = organizationEntities;
        return assetowner;
    }

    static getAllOrganizationNames(organizations: Organization[]): string {
        let orgString: string = "";
        if(organizations && organizations.length) {
            organizations.forEach(org => {
                orgString = orgString + org.name + ", " 
            })
            return orgString.substring(0, orgString.length-2)
        }
        return orgString;
    }

    
}