import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Alert } from '../../models/alert.model';
import { AlertParameter, AlertTemplate } from 'src/app/models/alert-template.model';
import { GlobalVariablesService } from 'src/app/shared/global-variables.service';
import { PRESSURE_UNITS, TEMPERATURE_UNITS, Utils } from 'src/app/shared/common';

@Component({
  selector: 'app-alert-acknowledge-dialog',
  templateUrl: './alert-acknowledge-dialog.component.html',
  styleUrls: ['./alert-acknowledge-dialog.component.scss']
})
export class AlertAcknowledgeDialogComponent implements OnInit {

  alertManagementForm: FormGroup
  timeToSuspendOptions: string[]
  timeUnits: string[] = ['day(s)', 'week(s)']
  alertTemplateString: string
  units: string[] = ['Bar', '°C']

  constructor(
    public dialogRef: MatDialogRef<AlertAcknowledgeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { type: string, alert: Alert, alertTemplate: AlertTemplate },
    private globalVariables: GlobalVariablesService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    if(this.globalVariables.getPressureUnit() != PRESSURE_UNITS[0]) {
      this.units[0] = 'Psi';
    }
    if(this.globalVariables.getTemperatureUnit() != TEMPERATURE_UNITS[0]) {
      this.units[1] = '°F';
    }
    if(this.data.alertTemplate) {
      let comparator: string
      let threshold: string
      if(this.data.alertTemplate.parameters) {
        this.data.alertTemplate.parameters.forEach((parameter: AlertParameter) => {
          if(parameter.key == 'comparator')
            comparator = parameter.value == '>'?'more than':'less than'
          if(parameter.key == 'threshold')
            threshold = parameter.value
        })
      }
      let unit: string = this.data.type=='2'?this.units[0]:this.units[1]
      if(this.data.type == '2' && this.data.alertTemplate.alertType && this.data.alertTemplate.alertType.identifier 
        && this.data.alertTemplate.alertType.identifier == 'assets_monitoring@alert_type-threshold_2') {
          unit = this.units[1];
      }
      if(this.units[0] == 'Psi') {
        threshold = Utils.barToPsi(Number(threshold)) + ''
      } else if (this.units[0] == '°F') {
        threshold = Utils.celsiusToFahrenheit(Number(threshold)) + '';
      }
      this.alertTemplateString = '(' + comparator + ' ' + threshold + ' ' + unit + ')' 
    }

    this.alertManagementForm = this.fb.group({
      suspendCheck: [false],
      timeToSuspend: [{value: '1', disabled: true}],
      timeUnit: [{value:'day(s)', disabled: true}]
    });

    this.timeToSuspendOptions = Array.from(Array(30).keys()).map(
      item => (item + 1).toString()
    )

    this.alertManagementForm.controls.suspendCheck.valueChanges
    .subscribe(value => {
        if(value) {
          this.alertManagementForm.controls.timeToSuspend.enable()
          this.alertManagementForm.controls.timeUnit.enable()
        } else {
          this.alertManagementForm.controls.timeToSuspend.disable()
          this.alertManagementForm.controls.timeUnit.disable()
        }
      }
    )
    
    this.alertManagementForm.controls.timeUnit.valueChanges
    .subscribe(value => {
      if(value == 'day(s)') {
        this.timeToSuspendOptions = Array.from(Array(30).keys()).map(
          item => (item + 1).toString()
        )
      } else {
        this.timeToSuspendOptions = Array.from(Array(8).keys()).map(
          item => (item + 1).toString()
        )
      }
    })
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    let result: any = {
      suspendCheck: this.alertManagementForm.controls.suspendCheck.value,
      timeToSuspendInDays: this.alertManagementForm.controls.suspendCheck.value? 
        this.alertManagementForm.controls.timeUnit.value == 'week(s)'? this.alertManagementForm.controls.timeToSuspend.value * 7 :
        this.alertManagementForm.controls.timeToSuspend.value : null
    }
    this.dialogRef.close(result)
  }

}
