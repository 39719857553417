import { SortFilterDevice } from "src/app/models/device.model"
import { PAGE_SIZE_OPTIONS, SORT } from "src/app/shared/common"
import { SortFilterActions, SortFilterActionTypes } from "./sort-filter.actions"

// ----- Device sort and filter state -----
export interface State {
    device: SortFilterDevice
}
  
export const initialSortFilterState = {
    device: { search: '', serial: SORT.DESC, type: 'all', status: 'all', deviceType: 'assets_monitoring@device_type-bumblebee', organization: 'all', asset: 'all', pageIndex: 0, pageSize: PAGE_SIZE_OPTIONS[0]}
} 

export function reducer(state = initialSortFilterState, action: SortFilterActions): State {
    switch(action.type) {
        case SortFilterActionTypes.SortFilterDevice: {
            return Object.assign({}, state, { device: action.payload })
        }
        default: {
            return state;
        }
    }
}
  