import { Dictionary } from '@ngrx/entity';
import { Device } from '../models/device.model';
import { Asset } from '../models/asset.model';
import { Association } from '../models/association.model';
import { MeasurementPoint } from '../models/measurement-point.model';

export class DeviceAssociationHelpers {

  static updateDeviceWithCurrentAssetAndMp(device: Device, assets: Dictionary<Asset>) {
    let currentAssociation = this.getDeviceCurrentAssociation(device)
    if (currentAssociation && currentAssociation.asset) {
      device.currentAsset = assets[currentAssociation.asset.identifier]
      if(currentAssociation.measurementPoint && device.currentAsset && device.currentAsset.measurementPoints) {
        device.currentAsset.measurementPoints.forEach((mp: MeasurementPoint) => {
          if(mp.identifier == currentAssociation.measurementPoint.identifier) {
            device.currentMeasurementPoint = mp
          }
        });
      }
    }
    return device;
  }

  static getDeviceCurrentAssociation(device: Device): Association {
    if (device.monitored && device.associations) {
      let filteredAssociations = device.associations.filter((association: Association) => !(association.hasOwnProperty('end')))
      if (filteredAssociations.length) {
        return filteredAssociations[0]
      }
    }
    return null;
  }

  static getDeviceMeasurementPointFromCurrentAssociation(device: Device): MeasurementPoint {
    let currentAssociation = this.getDeviceCurrentAssociation(device)
    if (currentAssociation && currentAssociation.measurementPoint) {
      return currentAssociation.measurementPoint;
    }
    return null;
  }
}
