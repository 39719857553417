import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { 
  LoadAssociationsSuccess,
  LoadAssociationsFailure,
  AddAssociationSuccess,
  AddAssociationFailure,
  UpdateAssociationSuccess,
  UpdateAssociationFailure,
  DeleteAssociationSuccess,
  DeleteAssociationFailure,
  AssociationActionTypes,
  AssociationActions
} from './association.actions';
import { ApiService } from '../../services/api-service.service'
import { Association } from '../../models/association.model';
import {
  DeviceActionTypes,
  DeviceActions
} from '../device/device.actions';
import { Device } from '../../models/device.model';
import {
  LoadMeasurementPointsFailure,
  LoadMeasurementPoints,
  GetMeasurementPointSuccess,
  GetMeasurementPointFailure
} from '../measurement-point/measurement-point.actions';
import { MeasurementPoint } from '../../models/measurement-point.model';
import { Store } from '@ngrx/store';
import * as mainReducers from '../../reducers/index';

import { MatSnackBar } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';



@Injectable()
export class AssociationsEffects {

  @Effect()
  loadAssociations$ = this.actions$.pipe(
    ofType(DeviceActionTypes.LoadDevicesSuccess),
    map(action => action.payload),
    concatMap((devices: Device[]) =>
      this.apiService.getAssociationsFromDevices(devices)
      .pipe(
        map((associations: Association[]) => new LoadAssociationsSuccess(associations)),
        catchError(error => of(new LoadAssociationsFailure({ error }))))
    )
  );

  @Effect()
  addAssociation$ = this.actions$.pipe(
    ofType(AssociationActionTypes.AddAssociation),
    map(action => action.payload),
    withLatestFrom(this.store),
    concatMap(([payload, state]) =>
      this.apiService.addAssociation(state.assets.selectedId, payload.measurementPointId, payload.association)
      .pipe(
        map((association: Association) => new AddAssociationSuccess(association)),
        catchError(error => of(new AddAssociationFailure({ error }))))
    )
  );

  @Effect()
  addAssociationSuccessGetLastMeasurementPoint$ = this.actions$.pipe(
    ofType(AssociationActionTypes.AddAssociationSuccess),
    map(action => action.payload),
    withLatestFrom(this.store),
    concatMap(([association, state]) =>
      this.apiService.getMeasurementPoint(state.assets.selectedId, association.measurementPoint.identifier)
      .pipe(
        map((measurementPoint: MeasurementPoint) => new GetMeasurementPointSuccess(measurementPoint)),
        catchError(error => of(new GetMeasurementPointFailure({ error }))))
    )
  );

  @Effect({ dispatch: false })
  handleAddAssociationError = this.actions$.pipe(
    ofType(AssociationActionTypes.AddAssociationFailure),
    map(action => {
      // Setting the timeout, so that angular would re-run change detection.
      setTimeout(
        () =>
          this.errorSnackBar.open('Error associating device : ' + (action.payload.error as HttpErrorResponse).error.message, null, {
            duration: 3000,
          }),
        0
      );
    })
  );

  @Effect()
  updateAssociation$ = this.actions$.pipe(
    ofType(AssociationActionTypes.UpdateAssociation),
    map(action => action.payload),
    withLatestFrom(this.store),
    concatMap(([payload, state]) =>
      this.apiService.updateAssociation(state.assets.selectedId, payload.measurementPointId, payload.association)
      .pipe(
        map((association: Association) => new UpdateAssociationSuccess({id: association.identifier , changes: association})),
        catchError(error => of(new UpdateAssociationFailure({ error }))))
    )
  );

  // Not used
  @Effect()
  deleteAssociation$ = this.actions$.pipe(
    ofType(AssociationActionTypes.DeleteAssociation),
    map(action => action.payload),
    withLatestFrom(this.store),
    concatMap(([payload, state]) =>
      this.apiService.deleteAssociation(state.assets.selectedId, payload.measurementPointId, payload.association)
      .pipe(
        map((association: Association) => new DeleteAssociationSuccess({id: payload.association.identifier})),
        catchError(error => of(new DeleteAssociationFailure({ error }))))
    )
  );

  @Effect()
  deleteAssociationSuccessClearLastMeasurementPoint$ = this.actions$.pipe(
    ofType(AssociationActionTypes.DeleteAssociationSuccess),
    map(action => action.payload),
    withLatestFrom(this.store),
    concatMap(([association, state]) =>
    of(association).pipe(
        // POURRAIT ETRE AMELIORE EN PASSANT L'ID du MP DANS DELETE SUCCESS CE QUI EVITERAIT DE TOUT RECHARGER (AVEC CODE CI-DESSUS)
        map(() => new LoadMeasurementPoints({ assetId: state.assets.selectedId, measurementPoints: state.assets.entities[state.assets.selectedId].measurementPoints })),
        catchError(error => of(new LoadMeasurementPointsFailure({ error }))))
    )
  );

  constructor(
    private actions$: Actions<AssociationActions|DeviceActions>,
    private apiService: ApiService,
    private store: Store<mainReducers.State>,
    private errorSnackBar: MatSnackBar
    ) {}

}
