import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatMenuTrigger } from '@angular/material';
import { Store } from '@ngrx/store';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { Organization } from 'src/app/models/organization.model';
import { BvuserMapHelper } from 'src/app/shared/bvuser-map-helper';
import { OrganizationDialogComponent } from '../organization-dialog/organization-dialog.component';
import * as mainReducers from '../../reducers/index';
import * as bvuserActions from '../../store/bvuser/bvuser.actions';
import { UploadDialogComponent } from '../upload-dialog/upload-dialog.component';
import { of } from 'rxjs';
import { ApiService } from 'src/app/services/api-service.service';
import { AppComponent } from 'src/app/app.component';
import { OrganizationComponent } from 'src/app/containers/organization/organization.component';

@Component({
  selector: '[organization-line]',
  templateUrl: './organization-line.component.html',
  styleUrls: ['./organization-line.component.scss']
})
export class OrganizationLineComponent implements OnInit {
  @ViewChild(MatMenuTrigger) moreActions: MatMenuTrigger;
  @Input() organization: Organization;

  public isSuperAdmin: boolean = false;
  public organizationPhoto: string
  public organizationParent: string;
  public organizationChildren: string

  constructor(
    private store: Store<mainReducers.State>,
    private apiService: ApiService,
    private dialog: MatDialog,
    private uploadDialog: MatDialog,
    private appComponent: AppComponent,
    private organizationComponent: OrganizationComponent
  ) {}

  ngOnInit() {
    if(this.organization.partOf && this.organization.partOf.identifier) {
      this.organizationParent = this.organization.partOf.name
    }
    if(this.organization.photo && this.organization.photo.identifier) {
      this.organizationPhoto = this.organization.photo.identifier
    }
    this.isSuperAdmin = this.organizationComponent.isSuperAdmin
    this.organizationChildren = BvuserMapHelper.getAllOrganizationNames(this.organization.children);
  }
  
  editOrganization(): void {
    // let org: Organization = this.organization;
    const dialog = this.dialog.open(OrganizationDialogComponent, {
      width: '450px',
      data: { action: 'edit', entity: this.organization }
    })
    dialog.afterClosed()
    .pipe(
      filter(result => result != null)
    )
    .subscribe((result: Organization) => {
      this.store.dispatch(new bvuserActions.UpdateOrganization(result))
    });
  }

  uploadPhoto(fileType = 'organization-photo') {
    const dialogRef = this.uploadDialog.open(UploadDialogComponent, {
      width: '500px',
      data: { identifier: this.organization.identifier, fileType: fileType }
    });
    
    dialogRef.afterClosed()
      .pipe(
        filter(result => result === true),
        catchError(error => of(error)),
        switchMap(
          result => this.apiService.getOrganizationPhoto(this.organization.identifier)
        ),
      ).subscribe(
        res => this.appComponent.organizationPhoto = this.appComponent.sanitizePhotoSrc(res),
        err => console.log(err)
      )
  }


}
