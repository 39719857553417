import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OrganizationDialogComponent } from 'src/app/components/organization-dialog/organization-dialog.component';
import { UserType } from 'src/app/models/bvuser.model';
import { Organization } from 'src/app/models/organization.model';
import * as mainReducers from '../../reducers/index';
import * as bvuserActions from '../../store/bvuser/bvuser.actions';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  public isSuperAdmin: boolean = false;
  public organizations$: Observable<Organization[]>;

  constructor(
    private store: Store<mainReducers.State>,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.store.select(mainReducers.selectFeatureBvuserType) 
      .subscribe(userType => this.isSuperAdmin = userType === UserType.SUPER_ADMIN)
    this.organizations$ = this.store.select(mainReducers.selectFeatureBvuserManagingOrganizations);
  }

  newOrganization() {
    const dialogRef = this.dialog.open(OrganizationDialogComponent, {
      width: '450px',
      data: { action: 'new' }
    });

    dialogRef.afterClosed()
    .pipe(
      filter(result => result != null)
    )
    .subscribe((result: Organization) => {
      this.store.dispatch(new bvuserActions.CreateOrganization(result));
    });
  }

}
