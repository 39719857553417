import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { MeasurementPoint } from '../../models/measurement-point.model';

export enum MeasurementPointActionTypes {
  LoadMeasurementPoints = '[MeasurementPoint] Load MeasurementPoints',
  LoadMeasurementPointsSuccess = '[MeasurementPoint] Load MeasurementPoints Success',
  LoadMeasurementPointsFailure = '[MeasurementPoint] Load MeasurementPoints Failure',
  AddMeasurementPoint = '[MeasurementPoint] Add MeasurementPoint',
  AddMeasurementPointSuccess = '[MeasurementPoint] Add MeasurementPoint Success',
  AddMeasurementPointFailure = '[MeasurementPoint] Add MeasurementPoint Failure',
  GetMeasurementPoint = '[MeasurementPoint] Get MeasurementPoint',
  GetMeasurementPointSuccess = '[MeasurementPoint] Get MeasurementPoint Success',
  GetMeasurementPointFailure = '[MeasurementPoint] Get MeasurementPoint Failure',
  AddMeasurementPoints = '[MeasurementPoint] Add MeasurementPoints',
  UpdateMeasurementPoint = '[MeasurementPoint] Update MeasurementPoint',
  UpdateMeasurementPointSuccess = '[MeasurementPoint] Update MeasurementPoint Success',
  UpdateMeasurementPointFailure = '[MeasurementPoint] Update MeasurementPoint Failure',
  UpsertMeasurementPoint = '[MeasurementPoint] Upsert MeasurementPoint',
  UpsertMeasurementPoints = '[MeasurementPoint] Upsert MeasurementPoints',
  UpdateMeasurementPointsUI = '[MeasurementPoint] Update MeasurementPoints UI',
  DeleteMeasurementPoint = '[MeasurementPoint] Delete MeasurementPoint',
  DeleteMeasurementPointSuccess = '[MeasurementPoint] Delete MeasurementPoint Success',
  DeleteMeasurementPointFailure = '[MeasurementPoint] Delete MeasurementPoint Failure',
  DeleteMeasurementPoints = '[MeasurementPoint] Delete MeasurementPoints',
  ClearMeasurementPoints = '[MeasurementPoint] Clear MeasurementPoints',
  AttachMeasurementPoint = '[MeasurementPoint] Attach MeasurementPoint',
  AttachMeasurementPointFailure = '[MeasurementPoint] Attach MeasurementPoint Failure',
  DetachMeasurementPoint = '[MeasurementPoint] Detach MeasurementPoint',
  DetachMeasurementPointFailure = '[MeasurementPoint] Detach MeasurementPoint Failure',
}


export class LoadMeasurementPoints implements Action {
  readonly type = MeasurementPointActionTypes.LoadMeasurementPoints;
  constructor(public payload: { assetId: string, measurementPoints: MeasurementPoint[]}) {}
}

export class LoadMeasurementPointsSuccess implements Action {
  readonly type = MeasurementPointActionTypes.LoadMeasurementPointsSuccess;
  constructor(public payload: MeasurementPoint[]) {}
}

export class LoadMeasurementPointsFailure implements Action {
  readonly type = MeasurementPointActionTypes.LoadMeasurementPointsFailure;
  constructor(public payload: { error: any }) {}
}

export class AddMeasurementPoint implements Action {
  readonly type = MeasurementPointActionTypes.AddMeasurementPoint;
  constructor(public payload: MeasurementPoint) {}
}

export class AddMeasurementPointSuccess implements Action {
  readonly type = MeasurementPointActionTypes.AddMeasurementPointSuccess;
  constructor(public payload: MeasurementPoint) {}
}

export class AddMeasurementPointFailure implements Action {
  readonly type = MeasurementPointActionTypes.AddMeasurementPointFailure;
  constructor(public payload: { error: any }) {}
}

export class GetMeasurementPoint implements Action {
  readonly type = MeasurementPointActionTypes.GetMeasurementPoint;
  constructor(public payload: MeasurementPoint) {}
}

export class GetMeasurementPointSuccess implements Action {
  readonly type = MeasurementPointActionTypes.GetMeasurementPointSuccess;
  constructor(public payload: MeasurementPoint) {}
}

export class GetMeasurementPointFailure implements Action {
  readonly type = MeasurementPointActionTypes.GetMeasurementPointFailure;
  constructor(public payload: { error: any }) {}
}

export class AddMeasurementPoints implements Action {
  readonly type = MeasurementPointActionTypes.AddMeasurementPoints;
  constructor(public payload: { measurementPoints: MeasurementPoint[] }) {}
}

export class UpdateMeasurementPoint implements Action {
  readonly type = MeasurementPointActionTypes.UpdateMeasurementPoint;
  constructor(public payload: { assetId: string, measurementPoint: Partial<MeasurementPoint> }) {}
}

export class UpdateMeasurementPointSuccess implements Action {
  readonly type = MeasurementPointActionTypes.UpdateMeasurementPointSuccess;
  constructor(public payload: Update<MeasurementPoint>) {}
}

export class UpdateMeasurementPointFailure implements Action {
  readonly type = MeasurementPointActionTypes.UpdateMeasurementPointFailure;
  constructor(public payload: {error: any}) {}
}

export class UpsertMeasurementPoint implements Action {
  readonly type = MeasurementPointActionTypes.UpsertMeasurementPoint;
  constructor(public payload: { measurementPoint: MeasurementPoint }) {}
}

export class UpsertMeasurementPoints implements Action {
  readonly type = MeasurementPointActionTypes.UpsertMeasurementPoints;
  constructor(public payload: { measurementPoints: MeasurementPoint[] }) {}
}

export class UpdateMeasurementPointsUI implements Action {
  readonly type = MeasurementPointActionTypes.UpdateMeasurementPointsUI;
  constructor(public payload: Update<MeasurementPoint>[]) {}
}

export class DeleteMeasurementPoint implements Action {
  readonly type = MeasurementPointActionTypes.DeleteMeasurementPoint;
  constructor(public payload: { assetId: string, measurementPoint: MeasurementPoint }) {}
}

export class DeleteMeasurementPointSuccess implements Action {
  readonly type = MeasurementPointActionTypes.DeleteMeasurementPointSuccess;
  constructor(public payload: { id: string }) {}
}

export class DeleteMeasurementPointFailure implements Action {
  readonly type = MeasurementPointActionTypes.DeleteMeasurementPointFailure;
  constructor(public payload: {error: any}) {}
}

export class DeleteMeasurementPoints implements Action {
  readonly type = MeasurementPointActionTypes.DeleteMeasurementPoints;
  constructor(public payload: { ids: string[] }) {}
}

export class ClearMeasurementPoints implements Action {
  readonly type = MeasurementPointActionTypes.ClearMeasurementPoints;
}

export class AttachMeasurementPoint implements Action {
  readonly type = MeasurementPointActionTypes.AttachMeasurementPoint
  constructor(public payload: { valveMeasurementPoint: MeasurementPoint, bumblebeeMeasurementPoint: MeasurementPoint }) {}
}

export class AttachMeasurementPointFailure implements Action {
  readonly type = MeasurementPointActionTypes.AttachMeasurementPointFailure
  constructor(public payload: { error: any }) {}
}

export class DetachMeasurementPoint implements Action {
  readonly type = MeasurementPointActionTypes.DetachMeasurementPoint
  constructor(public payload: { valveMeasurementPoint: MeasurementPoint, bumblebeeMeasurementPoint: MeasurementPoint }) {}
}

export class DetachMeasurementPointFailure implements Action {
  readonly type = MeasurementPointActionTypes.DetachMeasurementPointFailure
  constructor(public payload: { error: any }) {}
}

export type MeasurementPointActions =
 LoadMeasurementPoints
 | LoadMeasurementPointsSuccess
 | LoadMeasurementPointsFailure
 | AddMeasurementPoint
 | AddMeasurementPointSuccess
 | AddMeasurementPointFailure
 | GetMeasurementPoint
 | GetMeasurementPointSuccess
 | GetMeasurementPointFailure
 | AddMeasurementPoints
 | UpdateMeasurementPoint
 | UpdateMeasurementPointSuccess
 | UpdateMeasurementPointFailure
 | UpsertMeasurementPoint
 | UpsertMeasurementPoints
 | UpdateMeasurementPointsUI
 | DeleteMeasurementPoint
 | DeleteMeasurementPointSuccess
 | DeleteMeasurementPointFailure
 | DeleteMeasurementPoints
 | ClearMeasurementPoints
 | AttachMeasurementPoint
 | AttachMeasurementPointFailure
 | DetachMeasurementPoint
 | DetachMeasurementPointFailure;
