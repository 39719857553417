import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { 
  LoadMeasurementPointsSuccess,
  LoadMeasurementPointsFailure,
  AddMeasurementPointSuccess,
  AddMeasurementPointFailure,
  MeasurementPointActionTypes,
  MeasurementPointActions,
  UpdateMeasurementPointSuccess,
  UpdateMeasurementPointFailure,
  DeleteMeasurementPointSuccess,
  DeleteMeasurementPointFailure,
  UpdateMeasurementPoint,
  AttachMeasurementPointFailure,
  DetachMeasurementPointFailure,
} from './measurement-point.actions';
import { ApiService } from '../../services/api-service.service'
import { MeasurementPoint } from '../../models/measurement-point.model';
import { Store } from '@ngrx/store';
import * as mainReducers from '../../reducers/index';
import { LoadAsset } from '../asset/asset.actions';

@Injectable()
export class MeasurementPointsEffects {

  @Effect()
  loadMeasurementPoints$ = this.actions$.pipe(
    ofType(MeasurementPointActionTypes.LoadMeasurementPoints),
    map(action => action.payload),
    concatMap((payload: {assetId: string, measurementPoints: MeasurementPoint[]}) =>
      this.apiService.getMeasurementPointsWithLastAndAlerts(payload.assetId, payload.measurementPoints)
      .pipe(
        map((measurementPoints: MeasurementPoint[]) => new LoadMeasurementPointsSuccess(measurementPoints)),
        catchError(error => of(new LoadMeasurementPointsFailure({ error }))))
    )
  );

  @Effect()
  getMeasurementPointSuccess$ = this.actions$.pipe(
    ofType(MeasurementPointActionTypes.GetMeasurementPointSuccess),
    map(action => action.payload),
    concatMap((measurementPoint: MeasurementPoint) => 
      this.apiService.getLastMeasurement(measurementPoint, measurementPoint.asset.identifier).pipe(
        map((measurementPoint: MeasurementPoint) => new UpdateMeasurementPointSuccess({ id: measurementPoint.identifier, changes: measurementPoint })),
        catchError(error => of(new LoadMeasurementPointsFailure({ error })))
      )
    )
  )

  @Effect()
  addMeasurementPoint$ = this.actions$.pipe(
    ofType(MeasurementPointActionTypes.AddMeasurementPoint),
    map(action => action.payload),
    withLatestFrom(this.store),
    concatMap(([measurementPoint, state]) =>
      this.apiService.addMeasurementPoint(state.assets.selectedId, measurementPoint)
      .pipe(
        map((newMeasurementPoint: MeasurementPoint) => new AddMeasurementPointSuccess(newMeasurementPoint)),
        catchError(error => of(new AddMeasurementPointFailure({ error }))))
    )
  );

  @Effect() 
  updateMeasurementPoint$ = this.actions$.pipe(
    ofType(MeasurementPointActionTypes.UpdateMeasurementPoint),
    map(action => action.payload),
    concatMap((payload: {assetId: string, measurementPoint: Partial<MeasurementPoint>}) => 
      this.apiService.updateMeasurementPoint(payload.assetId, payload.measurementPoint).pipe(
        map((mp: MeasurementPoint) => new UpdateMeasurementPointSuccess({id: mp.identifier, changes: mp})),
        catchError(error => of(new UpdateMeasurementPointFailure({ error })))
      )
    )
  );

  @Effect()
  deleteMeasurementPoint$ = this.actions$.pipe(
    ofType(MeasurementPointActionTypes.DeleteMeasurementPoint),
    map(action => action.payload),
    concatMap((payload: { assetId: string, measurementPoint: MeasurementPoint }) => 
      this.apiService.deleteMeasurementPoint(payload.assetId, payload.measurementPoint).pipe(
        map((mp: MeasurementPoint) => new DeleteMeasurementPointSuccess({ id: mp.identifier })),
        catchError(error => of(new DeleteMeasurementPointFailure({ error })))
      )
    )
  );

  @Effect()
  attachMeasurementPoint$ = this.actions$.pipe(
    ofType(MeasurementPointActionTypes.AttachMeasurementPoint),
    map(action => action.payload),
    concatMap((payload: { valveMeasurementPoint: MeasurementPoint, bumblebeeMeasurementPoint: MeasurementPoint }) => 
      this.apiService.attachMeasurementPoint(payload.valveMeasurementPoint.asset.identifier, payload.valveMeasurementPoint.identifier, payload.bumblebeeMeasurementPoint).pipe(
        map((mp: MeasurementPoint) => new LoadAsset(mp.asset.identifier)), 
        catchError(error => of(new AttachMeasurementPointFailure({ error })))
      )
    )
  )

  @Effect()
  detachMeasurementPoint$ = this.actions$.pipe(
    ofType(MeasurementPointActionTypes. DetachMeasurementPoint),
    map(action => action.payload),
    concatMap((payload: { valveMeasurementPoint: MeasurementPoint, bumblebeeMeasurementPoint: MeasurementPoint }) => 
      this.apiService.detachMeasurementPoint(payload.valveMeasurementPoint.asset.identifier, payload.valveMeasurementPoint.identifier, payload.bumblebeeMeasurementPoint).pipe(
        map((mp: MeasurementPoint) => new LoadAsset(mp.asset.identifier)), 
        catchError(error => of(new DetachMeasurementPointFailure({ error })))
      )
    )
  )

  constructor(private actions$: Actions<MeasurementPointActions>, private apiService: ApiService, private store: Store<mainReducers.State>) {}

}
