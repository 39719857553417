import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { DeviceActions, DeviceActionTypes } from './device.actions';
import { Device } from '../../models/device.model';

export enum DeviceState {
  NOT_STARTED,
  LOADING_DEV,
  LOAD_DEV_OK,
  LOAD_DEV_KO,
  ADDING, 
  ADD_OK, 
  ADD_KO,
  UPDATING,
  UPDATE_OK,
  UPDATE_KO,
  VALVE_SWITCH,
  VALVE_SWITCH_OK,
  VALVE_SWITCH_KO,
  UNDEFINED
}

// ----- Device state -----
export interface State extends EntityState<Device> {
  // additional entities state properties
  path: string
  isLoading: DeviceState
}

export const adapter: EntityAdapter<Device> = createEntityAdapter<Device>({
  selectId: selectDeviceId,
  sortComparer: sortBySerial,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  path: null,
  isLoading: DeviceState.NOT_STARTED
});

export function selectDeviceId(device: Device): string {
  return device.identifier;
}

export function sortBySerial(a: Device, b: Device): number {
  return a.serial.localeCompare(b.serial);
}

// ----- Device reducer -----
export function reducer(state = initialState, action: DeviceActions): State {
    switch (action.type) {
      case DeviceActionTypes.LoadDevices: {
        return Object.assign({}, state, {
          isLoading: DeviceState.LOADING_DEV,
        });
      }
        
      case DeviceActionTypes.LoadDevicesSuccess: {
        return Object.assign({}, adapter.addAll(action.payload, state), {
          isLoading: DeviceState.LOAD_DEV_OK,
        });
      }

      case DeviceActionTypes.LoadDevicesFailure: {
        return Object.assign({}, state, {
          isLoading: DeviceState.LOAD_DEV_KO,
        });
      }

      case DeviceActionTypes.AddDevice: {
        return Object.assign({}, state, {
          isLoading: DeviceState.ADDING,
        });
      }

      case DeviceActionTypes.AddDeviceSuccess: {
        return Object.assign({}, adapter.addOne(action.payload, state), {
          isLoading: DeviceState.ADD_OK,
        });
      }

      case DeviceActionTypes.AddDeviceFailure: {
        return Object.assign({}, state, {
          isLoading: DeviceState.ADD_KO,
        });
      }

      case DeviceActionTypes.UpdateDevice: {
        return Object.assign({}, state, {
          isLoading: DeviceState.UPDATING,
        });
      }

      case DeviceActionTypes.UpdateDeviceSuccess: {
        return Object.assign({}, adapter.updateOne(action.payload, state), {
          isLoading: DeviceState.UPDATE_OK,
        });
      }

      case DeviceActionTypes.UpdateDeviceFailure: {
        return Object.assign({}, state, {
          isLoading: DeviceState.UPDATE_KO,
        });
      }
      
      // NOT USED
      case DeviceActionTypes.UpsertDevice: {
        return Object.assign({}, adapter.upsertOne(action.payload.device, state), {
          isLoading: DeviceState.UNDEFINED,
        });
      }

      case DeviceActionTypes.AddDevices: {
        return Object.assign({}, adapter.addMany(action.payload.devices, state), {
          isLoading: DeviceState.UNDEFINED,
        });
      }

      case DeviceActionTypes.UpsertDevices: {
        return Object.assign({}, adapter.upsertMany(action.payload.devices, state), {
          isLoading: DeviceState.UNDEFINED,
        });
      }

      case DeviceActionTypes.UpdateDevices: {
        return Object.assign({}, adapter.updateMany(action.payload.devices, state), {
          isLoading: DeviceState.UNDEFINED,
        });
      }

      case DeviceActionTypes.DeleteDevice: {
        return Object.assign({}, adapter.removeOne(action.payload.id, state), {
          isLoading: DeviceState.UNDEFINED,
        });
      }

      case DeviceActionTypes.DeleteDevices: {
        return Object.assign({}, adapter.removeMany(action.payload.ids, state), {
          isLoading: DeviceState.UNDEFINED,
        });
      }

      case DeviceActionTypes.ClearDevices: {
        return Object.assign({},  adapter.removeAll(state), {
          isLoading: DeviceState.UNDEFINED,
        });
      }

      case DeviceActionTypes.ValveSwitch: {
        return Object.assign({}, state, {
          isLoading: DeviceState.VALVE_SWITCH,
        });
      }
        
      case DeviceActionTypes.ValveSwitchSuccess: {
        return Object.assign({}, state, {
          path: action.payload.path,
          isLoading: DeviceState.VALVE_SWITCH_OK,
        });
      }

      case DeviceActionTypes.LoadDevicesFailure: {
        return Object.assign({}, state, {
          isLoading: DeviceState.VALVE_SWITCH_KO,
        });
      }


      default: {
        return Object.assign({},  state, {
          isLoading: DeviceState.UNDEFINED,
        });
      }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

