import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Asset } from '../../models/asset.model';

export enum AssetActionTypes {
  LoadAssets = '[Asset] Load Assets',
  LoadAssetsSuccess = '[Asset] Load Assets Success',
  LoadAssetsFailure = '[Asset] Load Assets Failure',
  SetSelectedAsset = '[Asset] Set Asset as selected',
  UnsetSelectedAsset = '[Asset] Unset Asset as selected',
  LoadAssetPhoto = '[Asset] Load Single Asset Photo',
  LoadAssetPhotoSuccess = '[Asset] Load Single Asset Photo Success',
  LoadAssetPhotoFailure = '[Asset] Load Single Asset Photo Failure',
  LoadAssetSchema = '[Asset] Load Single Asset Schema',
  LoadAssetSchemaSuccess = '[Asset] Load Single Asset Schema Success',
  LoadAssetSchemaFailure = '[Asset] Load Single Asset Schema Failure',
  LoadAsset = '[Asset] Load Asset',
  LoadAssetFailure = '[Asset] Load Asset Failure',
  AddAsset = '[Asset] Add Asset',
  AddAssetSuccess = '[Asset] Add Asset Success',
  AddAssetFailure = '[Asset] Add Asset Failure',
  UpdateAsset = '[Asset] Update Asset',
  UpdateAssetSuccess = '[Asset] Update Asset Success',
  UpdateAssetFailure = '[Asset] Update Asset Failure',
  DeleteAsset = '[Asset] Delete Asset',
  DeleteAssetSuccess = '[Asset] Delete Asset Success',
  DeleteAssetFailure = '[Asset] Delete Asset Failure',
  

  //à adapter
  UpsertAsset = '[Asset] Upsert Asset',
  AddAssets = '[Asset] Add Assets',
  UpsertAssets = '[Asset] Upsert Assets',
  UpdateAssets = '[Asset] Update Assets',
  DeleteAssets = '[Asset] Delete Assets',
  ClearAssets = '[Asset] Clear Assets'
}

export class LoadAssets implements Action {
  readonly type = AssetActionTypes.LoadAssets;
}

export class LoadAssetsSuccess implements Action {
  readonly type = AssetActionTypes.LoadAssetsSuccess;
  constructor(public payload: Asset[]) {}
}

export class LoadAssetsFailure implements Action {
  readonly type = AssetActionTypes.LoadAssetsFailure;
  constructor(public payload: { error: any }) {}
}

// Select Asset
export class SetSelectedAsset implements Action {
  readonly type = AssetActionTypes.SetSelectedAsset;
  constructor(public payload: Asset) {}
}

// Select Asset
export class UnsetSelectedAsset implements Action {
  readonly type = AssetActionTypes.UnsetSelectedAsset;
}

// Single Asset Photo
export class LoadAssetPhoto implements Action {
  readonly type = AssetActionTypes.LoadAssetPhoto;
}

export class LoadAssetPhotoSuccess implements Action {
  readonly type = AssetActionTypes.LoadAssetPhotoSuccess;
  constructor(public payload: Asset[]) {}
}

export class LoadAssetPhotoFailure implements Action {
  readonly type = AssetActionTypes.LoadAssetPhotoFailure;
  constructor(public payload: { error: any }) {}
}

// Single Asset Schema
export class LoadAssetSchema implements Action {
  readonly type = AssetActionTypes.LoadAssetSchema;
}

export class LoadAssetSchemaSuccess implements Action {
  readonly type = AssetActionTypes.LoadAssetSchemaSuccess;
  constructor(public payload: Asset[]) {}
}

export class LoadAssetSchemaFailure implements Action {
  readonly type = AssetActionTypes.LoadAssetSchemaFailure;
  constructor(public payload: { error: any }) {}
} 

// Load just one Asset by assetId
export class LoadAsset implements Action {
  readonly type = AssetActionTypes.LoadAsset
  constructor(public payload: string){}
}

export class LoadAssetFailure implements Action {
  readonly type = AssetActionTypes.LoadAssetFailure;
  constructor(public payload: { error: any }) {}
}

// Add Asset
export class AddAsset implements Action {
  readonly type = AssetActionTypes.AddAsset;
  constructor(public payload: Asset) {}
}

export class AddAssetSuccess implements Action {
  readonly type = AssetActionTypes.AddAssetSuccess;
  constructor(public payload: Asset) {}
}

export class AddAssetFailure implements Action {
  readonly type = AssetActionTypes.AddAssetFailure;
  constructor(public payload: { error: any }) {}
}

// Update Asset
export class UpdateAsset implements Action {
  readonly type = AssetActionTypes.UpdateAsset;
  constructor(public payload: Asset) {}
}    

export class UpdateAssetSuccess implements Action {
  readonly type = AssetActionTypes.UpdateAssetSuccess;
  constructor(public payload: Update<Asset>) {}
}    

export class UpdateAssetFailure implements Action {
  readonly type = AssetActionTypes.UpdateAssetFailure;
  constructor(public payload: { error: any }) {}
}  

// Delete Asset
export class DeleteAsset implements Action {
  readonly type = AssetActionTypes.DeleteAsset;
  constructor(public payload: { id: string }) {}
}

export class DeleteAssetSuccess implements Action {
  readonly type = AssetActionTypes.DeleteAssetSuccess;
  constructor(public payload: { id: string }) {}
}

export class DeleteAssetFailure implements Action {
  readonly type = AssetActionTypes.DeleteAssetFailure;
  constructor(public payload: { error: any }) {}
}

// default


export class UpsertAsset implements Action {
  readonly type = AssetActionTypes.UpsertAsset;
  constructor(public payload: { asset: Asset }) {}
}

export class AddAssets implements Action {
  readonly type = AssetActionTypes.AddAssets;
  constructor(public payload: { assets: Asset[] }) {}
}

export class UpsertAssets implements Action {
  readonly type = AssetActionTypes.UpsertAssets;
  constructor(public payload: { assets: Asset[] }) {}
}

export class UpdateAssets implements Action {
  readonly type = AssetActionTypes.UpdateAssets;
  constructor(public payload: { assets: Update<Asset>[] }) {}
}

export class DeleteAssets implements Action {
  readonly type = AssetActionTypes.DeleteAssets;
  constructor(public payload: { ids: string[] }) {}
}

export class ClearAssets implements Action {
  readonly type = AssetActionTypes.ClearAssets;
}

export type AssetActions =
 LoadAssets
 | LoadAssetsSuccess
 | LoadAssetsFailure
 | SetSelectedAsset
 | UnsetSelectedAsset
 | LoadAssetPhoto
 | LoadAssetPhotoSuccess
 | LoadAssetPhotoFailure
 | LoadAssetSchema
 | LoadAssetSchemaSuccess
 | LoadAssetSchemaFailure
 | LoadAsset
 | LoadAssetFailure
 | AddAsset
 | AddAssetSuccess
 | AddAssetFailure
 | UpdateAsset
 | UpdateAssetSuccess
 | UpdateAssetFailure
 | DeleteAsset
 | DeleteAssetSuccess
 | DeleteAssetFailure
 | UpsertAsset
 | AddAssets
 | UpsertAssets
 | UpdateAssets
 | DeleteAssets
 | ClearAssets;
