import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { GlobalVariablesService } from '../shared/global-variables.service';
import { AuthService } from './auth-service.service';
import { Administrator, AssetsOwner, Bvuser, UserPreferences } from '../models/bvuser.model';
import { Observable, forkJoin, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Asset } from '../models/asset.model';
import { MeasurementPoint } from '../models/measurement-point.model';
import { Organization } from '../models/organization.model';
import { AssetType } from '../models/asset-type.model';
import { Device } from '../models/device.model';
import { Association } from '../models/association.model';
import { LastMeasurement, Measurement } from '../models/measurement.model';
import { DeviceType } from '../models/device-type.model';
import { Alert } from '../models/alert.model';
import { AlertTemplate, AlertParameter } from '../models/alert-template.model';
import { PRESSURE_UNITS, TEMPERATURE_UNITS, Utils } from '../shared/common';
import { DeviceAssociationHelpers } from '../shared/device-association-helpers';
import { Dictionary } from '@ngrx/entity';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient, 
    private globalVariables: GlobalVariablesService,
    private authService: AuthService
  ) {}

  makeHttpOptionsHeader(method: string ='GET', accessToken: string = null): HttpHeaders {
    let headers: HttpHeaders;
    if (accessToken == null) {
      accessToken = this.authService.getStoredAccessToken().access_token;
    }

    if (method == 'POST') {
      headers = new HttpHeaders({
        //'Content-Type':  'application/x-www-form-urlencoded', 
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      });
    } else if (method == 'UPLOAD') {
        headers = new HttpHeaders({
          'Content-Type': 'application/octet-stream',
          'Authorization': 'Bearer ' + accessToken
        });
    } else {
        headers = new HttpHeaders({
          //'Accept':  'application/json', 
          'Authorization': 'Bearer ' + accessToken
        });
    }

    return headers   
  }

  lastMeasurementFieldCodes(deviceType?: string): HttpParams {
    if(deviceType && deviceType=='Valve') {
      return new HttpParams()
        .append("fieldCodes", "rssi")
        .append("fieldCodes", "actuator")
        .append("fieldCodes", "tamper")
        .append("fieldCodes", "cable")
        .append("fieldCodes", "di0")
        .append("fieldCodes", "di1")
        .append("fieldCodes", "leak")
        .append("fieldCodes", "fraud")
        .append("fieldCodes", "class_mode")
        .append("fieldCodes", "power") 
        .append("fieldCodes", "temp")
        .append("fieldCodes", "hygro")
    } else {
      return new HttpParams()
        .append("fieldCodes", "rssi")
        .append("fieldCodes", "receipt_date")
        .append("fieldCodes", "pressure")
        .append("fieldCodes", "battery")
        .append("fieldCodes", "temperature")
        .append("fieldCodes", "config_error")
        .append("fieldCodes", "battery_error")
        .append("fieldCodes", "battery_status")
        .append("fieldCodes", "status")
        .append("fieldCodes", "device_type")
        .append("fieldCodes", "pressure_sensor_temperature")
    }
    
  }

  // fileType = asset-photo, asset-schema, device-photo or organization-photo
  getUploadFileUrl(identifier: string, fileType: string): any {
    let paths: string[] = fileType.split('-');
    return { url: this.globalVariables.getApiUrl() + '/' + paths[0] + '/' + identifier + '/' + paths[1], headers: {
        'Authorization': 'Bearer ' + this.authService.getStoredAccessToken().access_token
      }
    }
  }

  // USER 
  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  getUser(): Observable<Bvuser> {
    return this.http.get<Bvuser>(this.globalVariables.getApiUrl() + '/user?expandManagingOrganizations=true', { headers: this.makeHttpOptionsHeader()})
  }

  getUserPreferences(): Observable<UserPreferences> {
    return this.http.get<UserPreferences>(this.globalVariables.getApiUrl() + '/user/preference/', { headers: this.makeHttpOptionsHeader()})
  }

  saveUserPreferences(preference: UserPreferences): Observable<UserPreferences> {
    return this.http.post<UserPreferences>(this.globalVariables.getApiUrl() + '/user/preference/', preference,  { headers: this.makeHttpOptionsHeader()})
  }

  // ORGANIZATIONS 
  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  getAdminOrganizations(bvuser: Bvuser): Observable<Bvuser> {
    return forkJoin(
      bvuser.adminOrganizations.map(
        organization => this.http.get<Organization>(this.globalVariables.getApiUrl() + '/organization/' + organization.identifier + '/tree', { headers: this.makeHttpOptionsHeader()})
      )
    ).pipe(
      switchMap(res => {
        bvuser.adminOrganizationEntities = res
        return of(bvuser)
      })
    )
  }
  
  createOrganization(organization: Organization): Observable<Organization> {
    return this.http.post<Organization>(this.globalVariables.getApiUrl() + '/organization/', organization, { headers: this.makeHttpOptionsHeader('POST') });
  }

  updateOrganization(organization: Organization): Observable<Organization> {
    return this.http.post<Organization>(this.globalVariables.getApiUrl() + '/organization/' + organization.identifier, organization, { headers: this.makeHttpOptionsHeader('POST') });
  }

  getOrganizationPhoto(identifier: string): Observable<Blob> {
    return this.http.get<Blob>(this.globalVariables.getApiUrl() + '/organization/' + identifier + '/photo', { headers: this.makeHttpOptionsHeader(), responseType: 'blob' as 'json'})
  }


  // ASSET OWNER - ADMINISTRATOR
  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  getAdministrators(size = '2000'): Observable<Administrator[]> {
    let params = new HttpParams().set("size", size);
    return this.http.get<{ links: any, content: Administrator[]}>(this.globalVariables.getApiUrl() + '/assets-owner/search/', { headers: this.makeHttpOptionsHeader(), params: params })
            .pipe(
              map(res => res.content.filter(ao => ao.asAdmin))
            )
  }

  addAdministrator(admin: Administrator): Observable<Administrator> {
    return this.http.post<Administrator>(this.globalVariables.getApiUrl() + '/assets-owner?appUrl=' + this.globalVariables.getRedirectUri(), admin, { headers: this.makeHttpOptionsHeader('POST')})
  }

  updateAdministrator(admin: Administrator): Observable<Administrator> {
    return this.http.post<Administrator>(this.globalVariables.getApiUrl() + '/assets-owner/' + admin.identifier, admin, { headers: this.makeHttpOptionsHeader('POST')})
  }

  suspendAdministrator(adminId: string): Observable<Administrator> {
    return this.http.post<Administrator>(this.globalVariables.getApiUrl() + '/assets-owner/' + adminId + '/suspend', null, { headers: this.makeHttpOptionsHeader('POST')})
  }

  activateAdministrator(adminId: string): Observable<Administrator> {
    return this.http.post<Administrator>(this.globalVariables.getApiUrl() + '/assets-owner/' + adminId + '/activate/', null, { headers: this.makeHttpOptionsHeader('POST')})
  }

  // ASSET OWNER - TECHNICIAN  
  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  getAssetsOwners(size = '2000'): Observable<AssetsOwner[]> {
    let params = new HttpParams().set("size", size);
    return this.http.get<{ links: any, content: AssetsOwner[] }>(this.globalVariables.getApiUrl() + '/assets-owner/search/', { headers: this.makeHttpOptionsHeader(), params: params })
            .pipe(
              map(res => res.content.filter(ao => !ao.asAdmin))
            )
  }

  addAssetsOwner(assetsOwner: AssetsOwner): Observable<AssetsOwner> {
    return this.http.post<AssetsOwner>(this.globalVariables.getApiUrl() + '/assets-owner?appUrl=' + this.globalVariables.getRedirectUri(), assetsOwner, { headers: this.makeHttpOptionsHeader('POST')})
  }

  updateAssetsOwner(assetsOwner: AssetsOwner): Observable<AssetsOwner> {
    return this.http.post<AssetsOwner>(this.globalVariables.getApiUrl() + '/assets-owner/' + assetsOwner.identifier, assetsOwner, { headers: this.makeHttpOptionsHeader('POST')})
  }

  suspendAssetsOwner(assetsOwnerId: string): Observable<AssetsOwner> {
    return this.http.post<AssetsOwner>(this.globalVariables.getApiUrl() + '/assets-owner/' + assetsOwnerId + '/suspend', null, { headers: this.makeHttpOptionsHeader('POST')})
  }

  activateAssetsOwner(assetsOwnerId: string): Observable<AssetsOwner> {
    return this.http.post<AssetsOwner>(this.globalVariables.getApiUrl() + '/assets-owner/' + assetsOwnerId + '/activate/', null, { headers: this.makeHttpOptionsHeader('POST')})
  }

  // ASSET TYPE
  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  getAssetTypes(size = '200'): Observable<AssetType[]> {
    let params = new HttpParams()
      .set("size", size);
    return this.http.get<{ links: any, content: AssetType[]}>(this.globalVariables.getApiUrl() + '/asset-type/search/', { headers: this.makeHttpOptionsHeader()})
            .pipe(
              map(res => res.content)
            )
  }

  // ASSETS
  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  getAssets(size = '2000'): Observable<Asset[]> {
    let params = new HttpParams()
      .set("size", size)
      .set("sort", 'reference,asc')
      .set('withMeasurementPoints', 'true')
    return this.http.get<{ links: any, content: Asset[]}>(this.globalVariables.getApiUrl() + '/asset/search', { headers: this.makeHttpOptionsHeader(), params: params })
            .pipe(
              map(res => res.content),
            )
  }

  getAssetPhoto(identifier: string): Observable<Blob> {
    return this.http.get<Blob>(this.globalVariables.getApiUrl() + '/asset/' + identifier + '/photo', { headers: this.makeHttpOptionsHeader(), responseType: 'blob' as 'json'})
  }

  getAssetSchema(identifier: string): Observable<Blob> {
    return this.http.get<Blob>(this.globalVariables.getApiUrl() + '/asset/' + identifier + '/schema', { headers: this.makeHttpOptionsHeader(), responseType: 'blob' as 'json'})
  }

  getAssetWithMeasurementPointAndData(assetId: string): Observable<Asset> {
    return this.http.get<Asset>(this.globalVariables.getApiUrl() + '/asset/' + assetId + '/measurement-points-and-data', { headers: this.makeHttpOptionsHeader() })
  }

  addAsset(asset: Asset): Observable<Asset> {
    return this.http.post<Asset>(this.globalVariables.getApiUrl() + '/asset/', asset, { headers: this.makeHttpOptionsHeader('POST')})
  }

  updateAsset(asset: Asset): Observable<Asset> {
    return this.http.post<Asset>(this.globalVariables.getApiUrl() + '/asset/' + asset.identifier, asset, { headers: this.makeHttpOptionsHeader('POST')})
  }

  deleteAsset(assetId: string): Observable<Asset> {
    return this.http.delete<Asset>(this.globalVariables.getApiUrl() + '/asset/' + assetId,  { headers: this.makeHttpOptionsHeader('POST')})
  }
  
  // MEASUREMENT POINT
  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  getMeasurementPointsWithLastAndAlerts(assetId: string, measurementPoints: MeasurementPoint[]): Observable<MeasurementPoint[]> {
    return forkJoin(
      measurementPoints.map(mp => forkJoin(
        // last measurements handling
        this.getLastMeasurement(mp, assetId),
        // alert templates handling
        this.getAlertTemplates(mp),
        // alerts handling
        this.getAlerts(mp)
      ).pipe(
        map(arrayOfMP => Object.assign(arrayOfMP[0], arrayOfMP[1], arrayOfMP[2])),
      ))
    )
  }
  
  private getAlertTemplates(measurementPoint: MeasurementPoint): Observable<MeasurementPoint> {
    let paramsForAlert = new HttpParams()
    if(measurementPoint.alertTemplates) {
      return this.http.get<AlertTemplate[]>(this.globalVariables.getApiUrl() + '/measurement-point/' + measurementPoint.identifier + '/alert-templates/', { headers: this.makeHttpOptionsHeader(), params: paramsForAlert})
        .pipe(
          // Wraps with MP activeAlerts property
          map((alertTemplates: AlertTemplate[]) => <MeasurementPoint>Object.assign(measurementPoint, { alertTemplates: alertTemplates }))
        )
    } else {
      return of(measurementPoint)
    }
  }
  
  private getAlerts(measurementPoint: MeasurementPoint): Observable<MeasurementPoint> {
    let paramsForAlert = new HttpParams()
    if (measurementPoint.activeAlerts) {
      return this.http.get<Alert[]>(this.globalVariables.getApiUrl() + '/measurement-point/' + measurementPoint.identifier + '/alerts/', { headers: this.makeHttpOptionsHeader(), params: paramsForAlert})
        .pipe(
          // Wraps with MP activeAlerts property
          map((alerts: Alert[]) => <MeasurementPoint>Object.assign(measurementPoint, { activeAlerts: alerts.filter((alert: Alert) => alert.state=='active') }))
        )
    } else {
      return of(measurementPoint)
    }
  }

  getMeasurementPoint(assetId: string, measurementPointId: string): Observable<MeasurementPoint> {
    return this.http.get<MeasurementPoint>(this.globalVariables.getApiUrl() + '/asset/' + assetId + '/measurement-point/' + measurementPointId, { headers: this.makeHttpOptionsHeader() })
  }

  addMeasurementPoint(assetId: string, measurementPoint: Partial<MeasurementPoint>): Observable<MeasurementPoint> {
    return this.http.post<MeasurementPoint[]>(this.globalVariables.getApiUrl() + '/asset/' + assetId + '/measurement-point/', measurementPoint, { headers: this.makeHttpOptionsHeader('POST')})
      .pipe(
        map(measurementPoints => measurementPoints[0])
      )
  }

  updateMeasurementPoint(assetId: string, measurementPoint: Partial<MeasurementPoint>): Observable<MeasurementPoint> {
    return this.http.post<MeasurementPoint>(this.globalVariables.getApiUrl() + '/asset/' + assetId + '/measurement-point/' + measurementPoint.identifier, measurementPoint, { headers: this.makeHttpOptionsHeader('POST')})
  }

  deleteMeasurementPoint(assetId: string, measurementPoint: MeasurementPoint): Observable<MeasurementPoint> {
    return this.http.delete<MeasurementPoint>(this.globalVariables.getApiUrl() + '/asset/' + assetId + '/measurement-point/' + measurementPoint.identifier, { headers: this.makeHttpOptionsHeader('POST')})
  }

  attachMeasurementPoint(assetId: string, measurementPointId: string, measurementPointToAttach: Partial<MeasurementPoint>): Observable<MeasurementPoint> {
    return this.http.post<MeasurementPoint>(this.globalVariables.getApiUrl() + '/asset/' + assetId + '/measurement-point/' + measurementPointId + '/attach', measurementPointToAttach, { headers: this.makeHttpOptionsHeader('POST') })
  }

  detachMeasurementPoint(assetId: string, measurementPointId: string, measurementPointToDetach: Partial<MeasurementPoint>): Observable<MeasurementPoint> {
    return this.http.post<MeasurementPoint>(this.globalVariables.getApiUrl() + '/asset/' + assetId + '/measurement-point/' + measurementPointId + '/detach', measurementPointToDetach, { headers: this.makeHttpOptionsHeader('POST') })
  }

  // MEASUREMENTS
  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  getLastMeasurement(measurementPoint: MeasurementPoint, assetId: string): Observable<MeasurementPoint> {
    let params = this.lastMeasurementFieldCodes(measurementPoint.type);
    // get last measurement from current or last association
    return this.http.get<LastMeasurement>(this.globalVariables.getApiUrl() + '/asset/' + assetId + '/measurement-point/' + measurementPoint.identifier + '/measurements/last', { headers: this.makeHttpOptionsHeader(), params: params})
        .pipe(
          // Wraps with MP lastMeasurements property
          filter((lastMeasurement: LastMeasurement) => lastMeasurement != null),
          map((lastMeasurement: LastMeasurement) => 
            <MeasurementPoint>Object.assign(measurementPoint, { lastMeasurement: this.convertLastMeasurement(lastMeasurement) })
          )
      )
  }
  
  private convertLastMeasurement(lastMeasurement: LastMeasurement): LastMeasurement {
    // convert utc to local time
    if(environment.production && lastMeasurement._last_update_date) {
      var d = new Date(lastMeasurement._last_update_date.replace(' ', 'T') + 'Z');
      const date = d.toISOString().split('T')[0];
      const time = d.toTimeString().split(' ')[0];
      lastMeasurement._last_update_date = date + ' ' + time; 
    }
    // convert to user prefered unit
    if(lastMeasurement.pressure && this.globalVariables.getPressureUnit() !== PRESSURE_UNITS[0]) {
      lastMeasurement.pressure = Utils.barToPsi(Number(lastMeasurement.pressure)) + '';
    } 
    if(this.globalVariables.getTemperatureUnit() !== TEMPERATURE_UNITS[0]) {
      if(lastMeasurement.pressure_sensor_temperature) {
        lastMeasurement.pressure_sensor_temperature = Utils.celsiusToFahrenheit(Number(lastMeasurement.pressure_sensor_temperature)) + '';
      }
      if(lastMeasurement.temperature) {
        lastMeasurement.temperature = Utils.celsiusToFahrenheit(Number(lastMeasurement.temperature)) + '';
      }
    }
    return lastMeasurement;
  }

  getMeasurements(assetId: string, measurementPoint: MeasurementPoint, 
    from: string = moment().subtract(1, 'week').format('YYYY[-]MM[-]DD HH[:]mm[:]ss'), 
    to: string = moment().format('YYYY[-]MM[-]DD HH[:]mm[:]ss')): Observable<Measurement[]> {
      
    let params = new HttpParams()
      .append("from", from)
      .append("to", to)
      .append("fieldCodes", "device_type")
      .append("fieldCodes", "pressure")
      .append("fieldCodes", "temperature")
      .append("fieldCodes", "pressure_sensor_temperature")
    
    return this.http.get<Measurement[]>(this.globalVariables.getApiUrl() + '/asset/' + assetId + '/measurement-point/' + measurementPoint.identifier + '/measurements', { headers: this.makeHttpOptionsHeader() , params: params})
      .pipe(
        map((measurements: Measurement[]) => {
          let convertedmeasurements: Measurement[] = []
          measurements
          .sort((a, b) => { return moment(a.timestamp).unix() - moment(b.timestamp).unix() })
          .forEach(measurement => {
            if(convertedmeasurements.length == 0) convertedmeasurements.push(measurement)
            if(moment(measurement.timestamp).unix() - moment(convertedmeasurements[convertedmeasurements.length-1].timestamp).unix() > 24*60*60) {
              convertedmeasurements.push({ timestamp: convertedmeasurements[convertedmeasurements.length-1].timestamp.slice(0, 14) + '59:59', values: { pressure: null, temperature: null, pressure_sensor_temperature: null } })
            }
            convertedmeasurements.push(this.convertMeasurement(measurement))
          })
          return convertedmeasurements
        })
      ) 
  }

  getMeasurementsAsCsv(assetId: string, measurementPoint: MeasurementPoint, from?: string, to?: string): Observable<string> {
    from = from==null? moment().subtract(1, 'year').format('YYYY[-]MM[-]DD HH[:]mm[:]ss') : from
    to = to==null? moment().format('YYYY[-]MM[-]DD HH[:]mm[:]ss') : to

    let params = new HttpParams()
      .append("from", from)
      .append("to", to)
      .append("fieldCodes","pressure")
      .append("fieldCodes","temperature")
      .append("fieldCodes", "rssi")
      .append("fieldCodes", "pressure_sensor_temperature")
    
    return this.http.get<string>(this.globalVariables.getApiUrl() + '/asset/' + assetId + '/measurement-point/' + measurementPoint.identifier + '/measurements/csv', { headers: this.makeHttpOptionsHeader() , params: params, responseType: 'text' as 'json'})
  }

  private convertMeasurement(measurement: Measurement): Measurement {
    if(!measurement || !measurement.values) return measurement;
    if(measurement.values.pressure && this.globalVariables.getPressureUnit() !== PRESSURE_UNITS[0]) {
      measurement.values.pressure = Utils.barToPsi(Number(measurement.values.pressure)) + '';
    }
    if(this.globalVariables.getTemperatureUnit() !== TEMPERATURE_UNITS[0]) {
      if(measurement.values.pressure_sensor_temperature) {
        measurement.values.pressure_sensor_temperature = Utils.celsiusToFahrenheit(Number(measurement.values.pressure_sensor_temperature)) + '';
      }
      if(measurement.values.temperature) {
        measurement.values.temperature = Utils.celsiusToFahrenheit(Number(measurement.values.temperature)) + '';
      }
    }
    return measurement;
  }

  // ASSOCIATIONS
  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  getAssociationsFromDevices(devices: Device[]) {
    let associationsArray: Association[] = []
    for (let device of devices) {
      if (device.hasOwnProperty('associations')) {
        for (let association of device.associations) {
          associationsArray.push(association)
        }
      }
    }
    return of(associationsArray)
  }

  addAssociation(assetId: string, measurementPointId: string, association): Observable<Association> {
    if (association.start == null) {
      association.start = moment().format('YYYY[-]MM[-]DD')
    }
    association.asset = { identifier: assetId }
    association.measurementPoint = {identifier: measurementPointId}
    let params = new HttpParams()
      .set("forceStartMonitored", "true")
    return this.http.post<Association>(this.globalVariables.getApiUrl() + '/asset/' + assetId + '/measurement-point/' + measurementPointId + '/association/', association, { headers: this.makeHttpOptionsHeader('POST'), params: params })
  }

  updateAssociation(assetId: string, measurementPointId: string, association: Association): Observable<Association> {
    let params = new HttpParams()
      .set("forceEndMonitored", "true")
    return this.http.post<Association>(this.globalVariables.getApiUrl() + '/asset/' + assetId + '/measurement-point/' + measurementPointId + '/association/' + association.identifier, association, { headers: this.makeHttpOptionsHeader('POST'), params: params })
  }

  // Not used
  deleteAssociation(assetId: string, measurementPointId: string, association: Association): Observable<Association> {
    return this.http.delete<Association>(this.globalVariables.getApiUrl() + '/asset/' + assetId + '/measurement-point/' + measurementPointId + '/association/' + association.identifier, { headers: this.makeHttpOptionsHeader('POST')})
  }

  // DEVICE TYPE
  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  getDeviceTypes(size = '200'): Observable<DeviceType[]> {
    let params = new HttpParams()
      .set("size", size);
    return this.http.get<{ links: any, content: DeviceType[]}>(this.globalVariables.getApiUrl() + '/device-type/search/', { headers: this.makeHttpOptionsHeader()})
            .pipe(
              map(res => res.content)
            )
  }

  // DEVICES
  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  getDevices(assets: Dictionary<Asset>): Observable<Device[]> {
    return this.http.get<Device[]>(this.globalVariables.getApiUrl() + '/device/searchAll?sort=serial,desc', { headers: this.makeHttpOptionsHeader() })
            .pipe(
              map(devices => devices.map(device => DeviceAssociationHelpers.updateDeviceWithCurrentAssetAndMp(device, assets)))
            )
  }

  createDevice(device: Device): Observable<Device> {
    return this.http.post<Device>(this.globalVariables.getApiUrl() + '/device', device, { headers: this.makeHttpOptionsHeader('POST') });
  }

  updateDevice(device: Device): Observable<Device> {
    return this.http.post<Device>(this.globalVariables.getApiUrl() + '/device/' + device.identifier, device, { headers: this.makeHttpOptionsHeader('POST') });
  }

  getDevicePhoto(identifier: string): Observable<Blob> {
    return this.http.get<Blob>(this.globalVariables.getApiUrl() + '/device/' + identifier + '/photo', { headers: this.makeHttpOptionsHeader(), responseType: 'blob' as 'json'})
  }

  // TODO
  // uploadDevicePhoto(identifier: string, file: Blob) {
  //   this.http.post<Blob>(this.globalVariables.getApiUrl() + '/device/' + identifier + '/photo', file, { headers: this.makeHttpOptionsHeader('POST'), responseType: 'blob' as 'json' })
  // }

  valveSwitch(devEui: string, switchCode: string): Observable<string> {
    let params = new HttpParams().set("switchCode", switchCode)
    return this.http.post<string>(this.globalVariables.getApiUrl() + '/device/kerlink/data-down-valve-switch/' + devEui, null, { headers: this.makeHttpOptionsHeader('POST'), responseType: 'string' as 'json', params: params })
  }

  // ALERT TYPE
  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  getAlertTypes(): Observable<any> {
    let params = new HttpParams()
    return this.http.get<{ links: any, content: any}>(this.globalVariables.getApiUrl() + '/alert-type/search', { headers: this.makeHttpOptionsHeader(), params: params})
            .pipe(
              map(res => res.content)
            )
  }

  // ALERT TEMPLATES
  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  getAlertTemplatesFromMeasurementPoint(measurementPointId: string): Observable<AlertTemplate[]> {
    let params = new HttpParams()
    return this.http.get<AlertTemplate[]>(this.globalVariables.getApiUrl() + '/measurement-point/' + measurementPointId + '/alert-templates/', { headers: this.makeHttpOptionsHeader(), params: params})
  }

  addAlertTemplateToMeasurementPoint(alertTemplate: AlertTemplate, measurementPointId: string): Observable<AlertTemplate> {
    return this.http.post<AlertTemplate[]>(this.globalVariables.getApiUrl() + '/measurement-point/' + measurementPointId + "/alert-template/", alertTemplate, { headers: this.makeHttpOptionsHeader('POST')})
      .pipe(
        map(res => res[0])
      )
  }

  updateAlertTemplateToMeasurementPoint(alertTemplate: AlertTemplate, measurementPointId: string): Observable<AlertTemplate> {
    return this.http.post<AlertTemplate>(this.globalVariables.getApiUrl() + '/measurement-point/' + measurementPointId + "/alert-template/" + alertTemplate.identifier, alertTemplate, { headers: this.makeHttpOptionsHeader('POST')})
  }

  deleteAlertTemplateToMeasurementPoint(alertTemplateId: string, measurementPointId: string): Observable<AlertTemplate> {
    return this.http.delete<AlertTemplate>(this.globalVariables.getApiUrl() + '/measurement-point/' + measurementPointId + "/alert-template/" + alertTemplateId, { headers: this.makeHttpOptionsHeader('POST')})
  }

  addOrUpdateAlertTemplateParameter(parameter: {"comparator": string, "threshold": string}, alertTemplateId: string, measurementPointId: string): Observable<{"comparator": string, "threshold": string}> {
    return this.http.post<{"comparator": string, "threshold": string}>(this.globalVariables.getApiUrl() + '/measurement-point/' + measurementPointId + "/alert-template/" + alertTemplateId + "/alert-parameter", parameter, { headers: this.makeHttpOptionsHeader('POST')})
  }

  getAlertTemplateParametersFromMeasurementPointAlertTemplate(measurementPointId: string, alertTemplateId: string): Observable<AlertParameter[]> {
    let params = new HttpParams()
    return this.http.get<AlertParameter[]>(this.globalVariables.getApiUrl() + '/measurement-point/' + measurementPointId + '/alert-templates/' + alertTemplateId + '/alert-parameter', { headers: this.makeHttpOptionsHeader(), params: params})
  }

  // ACTIVE ALERTS
  /** ------------------------------------------------------------------------------------------------------------------------------------- */
  acknowledgeAlert(alertId: string, measurementPointId: string, parameters: {suspendCheck: boolean, timeToSuspendInDays: string}): Observable<Alert> {
    let paramDelayDays: string = parameters.timeToSuspendInDays?"delayDays="+parameters.timeToSuspendInDays:""
    return this.http.post<Alert>(this.globalVariables.getApiUrl() + '/measurement-point/' + measurementPointId + "/alert/" + alertId + "/acknowledge?isSuspended="+parameters.suspendCheck + "&" + paramDelayDays, null, { headers: this.makeHttpOptionsHeader('POST') })
  }

}
