import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Device, SortFilterDevice } from '../../models/device.model';
import { Asset } from '../../models/asset.model';
import { MatDialog, PageEvent } from '@angular/material';
import { DeviceDialogComponent } from 'src/app/components/device-dialog/device-dialog.component';
import { filter, map, switchMap } from 'rxjs/operators';
import { UserType } from 'src/app/models/bvuser.model';
import { Organization } from 'src/app/models/organization.model';
import { FormControl } from '@angular/forms';
import { BUMBLEBEE_TYPE, PAGE_SIZE_OPTIONS, SERIAL_PREFIX, SORT, Utils } from 'src/app/shared/common';
import { SortAndFilterDevice } from 'src/app/store/sort-filter/sort-filter.actions';
import * as devicesActions from '../../store/device/device.actions';
import * as mainReducers from '../../reducers/index';
import { DeviceType } from 'src/app/models/device-type.model';

@Component({
  selector: 'app-device-maintenance',
  templateUrl: './device-maintenance.component.html',
  styleUrls: ['./device-maintenance.component.scss']
})
export class DeviceMaintenanceComponent implements OnInit {
  
  sortOrder: string 
  pageLength: number 
  deviceType: string = 'assets_monitoring@device_type-bumblebee'
  typeIconColor: string
  dateIconColor: string
  assetIconColor: string 
  isAdmin: boolean = false
  isSuperAdmin: boolean = false
  assets$: Observable<Asset[]>
  devices$: Observable<Device[]>
  deviceTypeFormControl: FormControl 
  orgFormControl: FormControl
  searchFormControl: FormControl
  currentSortFilter: SortFilterDevice
  deviceTypes$: Observable<DeviceType[]>
  organizations: Organization[]
  organizations$: Observable<Organization[]>
  filteredAssetsByOrganization: Asset[]
  pageSizeOptions = PAGE_SIZE_OPTIONS

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private store: Store<mainReducers.State>,
  ) {}

  ngOnInit() {
    this.sortOrder = SORT.DESC
    this.store.select(mainReducers.selectFeatureBvuserType).subscribe(userType => {
      this.isAdmin = (userType === UserType.SUPER_ADMIN || userType === UserType.ADMIN)
      this.isSuperAdmin = userType === UserType.SUPER_ADMIN
    })

    this.assets$ = this.store.select(mainReducers.selectFeatureAssetsEntitiesAsArray)
    this.assets$.subscribe(assets => this.filteredAssetsByOrganization = assets)
    this.deviceTypes$ = this.store.select(mainReducers.selectFeatureDeviceTypeEntities)
    this.organizations$ = this.store.select(mainReducers.selectFeatureBvuserManagingOrganizations)
    this.organizations$.subscribe(orgs => this.organizations = orgs)
    this.devices$ = this.selectDevices()
    // filter by device type
    this.deviceTypeFormControl = new FormControl('assets_monitoring@device_type-bumblebee')
    this.deviceTypeFormControl.valueChanges.subscribe(value =>{
      this.deviceType = value
      this.store.dispatch(new SortAndFilterDevice(Object.assign({}, this.currentSortFilter, { deviceType: value })))
    })
    // filter by search (serial)
    this.searchFormControl = new FormControl('')
    this.searchFormControl.valueChanges.subscribe(value => {
      this.store.dispatch(new SortAndFilterDevice(Object.assign({}, this.currentSortFilter, { search: value })))
    })
    // filter by organization
    this.orgFormControl = new FormControl('')
    this.orgFormControl.valueChanges.subscribe(value => {
      this.store.dispatch(new SortAndFilterDevice(Object.assign({}, this.currentSortFilter, { organization: value })))
    })

  }

  ngAfterContentChecked() {
    this.cdRef.detectChanges();
  }

  private selectDevices(noPaginated?: boolean): Observable<Device[]> {
    return this.store.select(mainReducers.selectFeatureDevicesEntitiesAsArray).pipe(
      filter(devices => (devices.length > 0)),
      switchMap(devices => this.store.select(mainReducers.selectFeatureSortFilterDevice).pipe(
          map(sortFilter => {
            this.currentSortFilter = sortFilter
            return this.sortFilterDevices(devices, sortFilter, noPaginated)
          }),
        )
      )
    )
  }

  createDevice() {
    const dialogRef = this.dialog.open(DeviceDialogComponent, {
      width: '450px',
      data: { action: 'new' }
    })

    dialogRef.afterClosed()
    .pipe(
      filter(result => result != null)
    )
    .subscribe((result: Device) => {
      this.store.dispatch(new devicesActions.AddDevice(result))
    })
  }

  exportDevices() {
    const subs = this.selectDevices(true).subscribe(devices => {
      const HEADER: string = 'serial;type;appKey;asset;measurementPoint;\n'
      const DEVICES: string[] = devices.map(d => {
        let content: string = d.serial.replace(SERIAL_PREFIX, '') + ';'
        if(d.deviceSensorType && d.deviceSensorType==1) {
          content = content + BUMBLEBEE_TYPE.T.name + ';'
        } else if(d.deviceSensorType && d.deviceSensorType==2) {
          content = content + BUMBLEBEE_TYPE.P.name + ';'
        } else {
          content = content + ';'
        }
        if(d.appKey) {
          content = content + d.appKey + ';'
        } else {
          content = content + ';'
        }
        if(d.currentAsset && d.currentAsset.reference) {
          content = content + d.currentAsset.reference + ';'
        } else {
          content = content + ';'
        }
        if(d.currentMeasurementPoint && d.currentMeasurementPoint.name) {
          content = content + d.currentMeasurementPoint.name + ';'
        } else {
          content = content + ';'
        }
        return content + '\n'
      })
      let BODY: string = ''
      DEVICES.forEach(d => BODY += d)
      Utils.downloadFile(HEADER+BODY, 'devices.csv', 'text/csv')
    })
    subs.unsubscribe()

  }

  editDevice(device: Device) {
    const dialogRef = this.dialog.open(DeviceDialogComponent, {
      width: '450px', 
      data: { action: 'edit', entity: device }
    })
    dialogRef.afterClosed()
    .pipe(
      filter(result => result != null)
    )
    .subscribe((result: Device) => {
      this.store.dispatch(new devicesActions.UpdateDevice(result))
    })
  }
  
  goToAssetDetails(asset: Asset) {
    this.router.navigate(['dashboard-assets/asset/' + asset.identifier]);
  }

  // Sort and filters
  /** -------------------------------------------------------------------------------- */
  sortFilterDevices(devices: Device[], sortFilter: any, noPaginated?: boolean): Device[] {
    let filteredDevices: Device[] = devices
    
    // device type filter: Bumblebee or Valve 
    if(!sortFilter.deviceType.includes('valve')) {
      filteredDevices = filteredDevices
        .filter(device => device.deviceType.identifier != 'assets_monitoring@device_type-valve')
    } else {
      filteredDevices = filteredDevices
        .filter(device => device.deviceType.identifier == 'assets_monitoring@device_type-valve')
    }

    // search filter
    if(sortFilter.search != '' ) {
      if(!sortFilter.deviceType.includes('valve')) {
        filteredDevices = filteredDevices.filter(device => device.serial.replace(SERIAL_PREFIX, '').startsWith(sortFilter.search.toLowerCase()))
      } else {
        filteredDevices = filteredDevices.filter(device => device.serial.startsWith(sortFilter.search.toLowerCase()))
      }
    }

    // type filter: 'all', 'pressure' or 'temerature'
    if((sortFilter.type != '') && (sortFilter.type != 'all')) {
      this.typeIconColor = 'goldenrod'
      if(sortFilter.type == BUMBLEBEE_TYPE.P.name) {
        filteredDevices = filteredDevices.filter(device => device.deviceSensorType===BUMBLEBEE_TYPE.P.id)
      } else if(sortFilter.type == BUMBLEBEE_TYPE.T.name) {
        filteredDevices = filteredDevices.filter(device => device.deviceSensorType===BUMBLEBEE_TYPE.T.id)
      } 
    } else {
      this.typeIconColor = '#262626'
    }

    // last transmission filter: 'all', 'activated' or 'Non activated'
    if((sortFilter.status != '') && (sortFilter.status != 'all')) {
      this.dateIconColor = 'goldenrod'
      if(sortFilter.status == 'activated') {
        filteredDevices = filteredDevices.filter(device => device.lastUpdateDate)
      } else if(sortFilter.status == 'deactivated') {
        filteredDevices = filteredDevices.filter(device => !device.lastUpdateDate)
      }
    } else {
      this.dateIconColor = '#262626'
    }

    // organization filter
    if((sortFilter.organization != '') && (sortFilter.organization != 'all')) {
      let targetOrg = this.organizations
        .filter(org => org.identifier==sortFilter.organization || (org.partOf && org.partOf.identifier==sortFilter.organization))
        .map(org => org.identifier)
        .toString()
      filteredDevices = filteredDevices
        .filter(device => targetOrg.includes(device.ownerOrganization.identifier))
      this.assets$.subscribe(assets => 
        this.filteredAssetsByOrganization = assets.filter(asset => targetOrg.includes(asset.organization.identifier))
      )
    } else {
      if(sortFilter.organization == 'all') {
        this.assets$.subscribe(assets => 
          this.filteredAssetsByOrganization = assets
        )
      }
    }

    // asset filter
    if((sortFilter.asset != '') && (sortFilter.asset != 'all')) {
      this.assetIconColor = 'goldenrod'
      if(sortFilter.asset == 'none') {
        filteredDevices = filteredDevices.filter(device => !device.currentAsset)
      } else {
        filteredDevices = filteredDevices.filter(device => device.currentAsset && device.currentAsset.identifier == sortFilter.asset)
      }
    } else {
      this.assetIconColor = '#262626'
    }

    // serial sort
    if(sortFilter.serial === SORT.ASC) {
      filteredDevices = filteredDevices.sort((s1, s2) => s1.serial.localeCompare(s2.serial))
    } else {
      filteredDevices = filteredDevices.sort((s1, s2) => s2.serial.localeCompare(s1.serial))
    }
    if(noPaginated) {
      return filteredDevices
    }
    // pagination
    this.pageLength = filteredDevices.length
    const pageIndex = sortFilter.pageIndex
    const pageSize = sortFilter.pageSize
    return filteredDevices.slice(pageIndex*pageSize, pageIndex*pageSize + pageSize)
  }

  refresh() {
    // this.store.dispatch(searchDevice())
    // this.store.dispatch(new SortAndFilterDevice(Object.assign({}, this.currentSortFilter, { search: '' })))
  }
  
  paginate(event: PageEvent) {
    this.store.dispatch(new SortAndFilterDevice(Object.assign({}, this.currentSortFilter, { pageIndex: event.pageIndex, pageSize: event.pageSize })))
  }

  sort(sortOrder: string) {
    this.sortOrder = sortOrder
    this.store.dispatch(new SortAndFilterDevice(Object.assign({}, this.currentSortFilter, { serial: sortOrder })))
  }
  
  filter(filterBy: string) {
    const FILTERS = filterBy.split('.')
    const FILTER_BY = FILTERS[0]==='type'?{ type: FILTERS[1] }:FILTERS[0]==='status'?{ status: FILTERS[1] }:FILTERS[0]==='asset'?{ asset: FILTERS[1] }:{ organization: FILTERS[1]} 
    this.store.dispatch(new SortAndFilterDevice(Object.assign({}, this.currentSortFilter, FILTER_BY)))
  }


}
