import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import { Administrator } from "src/app/models/bvuser.model";
import { ApiService } from "src/app/services/api-service.service";
import { ActivateAdministratorFailure, ActivateAdministratorSuccess, AddAdministratorFailure, AddAdministratorSuccess, AdministratorActions, AdministratorActionTypes, ChangeRoleAsTechnicianFailure, ChangeRoleAsTechnicianSuccess, LoadAdministratorsFailure, LoadAdministratorsSuccess, SuspendAdministratorFailure, SuspendAdministratorSuccess, UpdateAdministratorFailure, UpdateAdministratorSuccess } from "./administrator.actions";

@Injectable()
export class AdministratorsEffects {

    @Effect()
    loadAdministrators$ = this.actions$.pipe(
        ofType(AdministratorActionTypes.LoadAdministrators),
        concatMap(() =>
            this.apiService.getAdministrators()
            .pipe(
                map((administrators: Administrator[]) => new LoadAdministratorsSuccess(administrators)),
                catchError(error => of(new LoadAdministratorsFailure({ error })))
            )
        )
    )

    @Effect()
    addAdministrator$ = this.actions$.pipe(
        ofType(AdministratorActionTypes.AddAdministrator),
        map(action => action.payload),
        concatMap((admin: Administrator) => 
            this.apiService.addAdministrator(admin).pipe(
                map((newAdmin: Administrator) => new AddAdministratorSuccess(newAdmin)),
                catchError(error => of(new AddAdministratorFailure({ error })))
            )
        )
    )

    @Effect() 
    updateAdministrator$ = this.actions$.pipe(
        ofType(AdministratorActionTypes.UpdateAdministrator),
        map(action => action.payload),
        concatMap((admin: Administrator) => 
            this.apiService.updateAdministrator(admin).pipe(
                map((newAdmin: Administrator) => new UpdateAdministratorSuccess({ id: newAdmin.identifier, changes: newAdmin })),
                catchError(error => of(new UpdateAdministratorFailure({ error })))
            )
        )
    )

    @Effect() 
    suspendAdministrator$ = this.actions$.pipe(
        ofType(AdministratorActionTypes.SuspendAdministrator),
        map(action => action.payload),
        concatMap((adminId: string) => 
            this.apiService.suspendAdministrator(adminId).pipe(
                map((newAdmin: Administrator) => new SuspendAdministratorSuccess({ id: newAdmin.identifier, changes: newAdmin })),
                catchError(error => of(new SuspendAdministratorFailure({ error })))
            )
        )
    )

    @Effect() 
    activateAdministrator$ = this.actions$.pipe(
        ofType(AdministratorActionTypes.ActivateAdministrator),
        map(action => action.payload),
        concatMap((adminId: string) => 
            this.apiService.activateAdministrator(adminId).pipe(
                map((newAdmin: Administrator) => new ActivateAdministratorSuccess({ id: newAdmin.identifier, changes: newAdmin })),
                catchError(error => of(new ActivateAdministratorFailure({ error })))
            )
        )
    )

    @Effect() 
    changeRoleAsTechnician$ = this.actions$.pipe(
        ofType(AdministratorActionTypes.ChangeRoleAsTechnician),
        map(action => action.payload),
        concatMap((admin: Administrator) => 
            this.apiService.updateAdministrator(admin).pipe(
                map((res: Administrator) => new ChangeRoleAsTechnicianSuccess(res)),
                catchError(error => of(new ChangeRoleAsTechnicianFailure({ error })))
            )
        )
    )

    constructor(private actions$: Actions<AdministratorActions>, private apiService: ApiService) {}

}


