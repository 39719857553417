import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as L from 'leaflet';
import { MeasurementPoint } from 'src/app/models/measurement-point.model';
import { Asset } from '../../models/asset.model';
import * as mainReducers from '../../reducers/index';

@Component({
  selector: 'asset-map',
  templateUrl: './asset-map.component.html',
  styleUrls: ['./asset-map.component.scss']
})
export class AssetMapComponent implements OnInit, OnChanges {
  @Input() assets: Asset[];
  @Input() measurementPoints: MeasurementPoint[];
  @Output() clickOnMeasurementPoint = new EventEmitter<MeasurementPoint>()

  mapHeight: string;
  markerIcon = L.icon({
    iconUrl: '../../../assets/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12.5, 41]
  });
  componentMap: L.Map = null;

  constructor(
    private router: Router,
    private store: Store<mainReducers.State>
  ) {}

  ngOnChanges() {
    const markers = location.hash.includes('/dashboard-assets/asset')?
        this.convertMeasurementPointsToMarkers(this.getMeasurementPointsForMarkers()):
        this.convertAssetsToMarkers(this.getAssetsForMarkers())
    this.drawMap(markers);
  }

  ngOnInit() {}

  // For assets map
  /** --------------------------------------------------------------------------------------------------- */
  getAssetsForMarkers(): Asset[] {
    return this.assets.filter(
      asset => (asset != null)
    )
    .filter(
      asset => (asset.latitude && asset.longitude)
    );
  }

  convertAssetsToMarkers(assets: Asset[]): L.Marker[] {
    return assets
      .map(
        asset => L.marker(
          [this.sanitizeLatOrLng(asset.latitude), this.sanitizeLatOrLng(asset.longitude)],
          { icon: this.markerIcon, title: asset.reference }
        )
      );
  }

  // For measurement points map
  /** --------------------------------------------------------------------------------------------------- */
  getMeasurementPointsForMarkers(): MeasurementPoint[] {
    return this.measurementPoints.filter(
      (mp: MeasurementPoint) => mp != null
    )
    .filter(
      (mp: MeasurementPoint) => mp.latitude && mp.longitude
    )
  }

  convertMeasurementPointsToMarkers(measurementPoints: MeasurementPoint[]): L.Marker[] {
    return measurementPoints
      .map(
        mp => L.marker(
          [this.sanitizeLatOrLng(mp.latitude), this.sanitizeLatOrLng(mp.longitude)],
          { icon: this.markerIcon, title: mp.name }
        )
      );
  }

  // Helper
  /** --------------------------------------------------------------------------------------------------- */
  onClick(e) {
    if(!location.hash.includes('/dashboard-assets/asset')) {
      const assetId = this.assets.filter((asset: Asset) => asset.reference === e.target.options.title)[0].identifier;
      this.router.navigate(['dashboard-assets/asset/' + assetId]);
    } else {
      const measurementPoint = this.measurementPoints.filter((mp: MeasurementPoint) => mp.name === e.target.options.title)[0]
      this.clickOnMeasurementPoint.emit(measurementPoint);
    }
  }
  
  drawMap(markers: L.Marker[]) {
    if (markers.length) {
      const markerGroup = L.featureGroup(markers);

      if (this.componentMap) {
        this.componentMap.off();
        this.componentMap.remove();
      }

      if (markers.length > 1) {
        this.componentMap = L.map('componentmapid', {
          maxBounds: [
            [-90, -180],
            [90, 180]
            ]
          }
        );
      } else {
        this.componentMap = L.map('componentmapid'); // maxBounds fait bugger la carte quand il n'y a qu'un seul marker ...
      }

      this.componentMap
      .setMaxZoom(15)
      .setView([markers[0].getLatLng().lat, markers[0].getLatLng().lng], 13)
      .fitBounds(markerGroup.getBounds());

      L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: 'Srett',
        noWrap: true
      }).addTo(this.componentMap);

      for (const key of Object.keys(markers)) {
        markers[key].on('click', this.onClick.bind(this));
        markers[key].addTo(this.componentMap);
      }
    }
  }

  sanitizeLatOrLng(value: number | string): number {
    switch (typeof(value)) {
      case 'string':
        return parseFloat(value as string);
      case 'number':
        return value as number;

      default:
        console.log('could not convert coordinate value ' + value);
        return null;
    }
  }

}

export interface Coordinates {
  latitude: number;
  longitude: number;
}
