
import { DeviceTypeActions, DeviceTypeActionTypes } from './device-type.actions';
import { DeviceType } from 'src/app/models/device-type.model';

export interface State {
  entities: DeviceType[],
  isLoading: boolean
}

export const initialState: State = {
  entities: null,
  isLoading: false
};

export function reducer(state = initialState, action: DeviceTypeActions): State {
  switch (action.type) {

    case DeviceTypeActionTypes.LoadDeviceTypes:
      return Object.assign({}, state, {
        isLoading: true
      });

    case DeviceTypeActionTypes.LoadDeviceTypesSuccess:
      const deviceTypes: DeviceType[] = action.payload;
      return Object.assign({}, state, {
        entities: deviceTypes,
        isLoading: false
      });

    case DeviceTypeActionTypes.LoadDeviceTypesFailure:
      return Object.assign({}, state, {
        isLoading: false
      });

    default:
      return state;
  }
}
