import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AssetDialogComponent } from '../../components/asset-dialog/asset-dialog.component';
import * as mainReducers from '../../reducers/index';
import * as assetActions from '../../store/asset/asset.actions';
import { Asset } from '../../models/asset.model';
import { AssetFilterBarComponent } from '../../components/asset-filter-bar/asset-filter-bar.component';
import { UserType } from 'src/app/models/bvuser.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild(AssetFilterBarComponent) assetFilterBarComponent: AssetFilterBarComponent

  public assets$: Observable<any>;
  public assetsIds$: Observable<any>;
  public filteredAssets: Asset[] = [];
  public filteredOptions: Observable<Asset[]> = of([]);
  public filterText: any = null;
  public showMap = true;
  public isSuperAdmin: boolean = false;

  constructor(
    private store: Store<mainReducers.State>,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.store.select(mainReducers.selectFeatureBvuserType) 
      .subscribe(userType => this.isSuperAdmin = userType === UserType.SUPER_ADMIN)
    this.assets$ = this.store.select(mainReducers.selectFeatureAssetsEntitiesAsArray)
    //this.assetsIds$ = this.store.select(mainReducers.selectFeatureAssetsIds)
  }

  goToAssetDetails(asset: Asset) {
    this.router.navigate(['dashboard-assets/asset/' + asset.identifier])
  }

  newAsset(): void {
    const dialogRef = this.dialog.open(AssetDialogComponent, {
      width: '450px',
      data: {}
    });

    dialogRef.afterClosed()
      .pipe(
        filter(result => result != null)
      )
      .subscribe((result: Asset) => {
        this.store.dispatch(new assetActions.AddAsset(result))
      });
  }

  toggleMap() {
    this.showMap = !this.showMap;
  }

}
