
import { saveAs } from 'file-saver';

export const PRESSURE_UNITS = ['Bar', 'Psi']  // Bar by default
export const TEMPERATURE_UNITS = ['Celsius(°C)', 'Fahrenheit(°F)'] // °C by default

export const SERIAL_PREFIX = '8c192d7000' // Bumblebee
export const BUMBLEBEE_TYPE = {
     P: { id: 2, name: 'Pressure' }, 
     T: { id: 1, name: 'Temperature'}
}
export const DEVICE_TYPE_BUMBLEBEE = "Bumblebee"
export const DEVICE_TYPE_VALVE = "Valve" 

export const VALVE_STATUS = {
     _1: 'HP Line pressurized',
     _2: 'HP Line = 0 bar',
     _3: 'HP Line ready to be pressurized'
}

export const VALVE_CONTROL_CMD = {
     _1: 'Bleed HP Line',
     _2: 'Reset BumbleBee Valve',
     _3: ''
}

export const VALVE_CONFIRM_CMD = {
     _1: 'Confirm HP Line Pressure = 0 bar',
     _2: 'Confirm BumbleBee Valve is reset',
     _3: 'Confirm HP Line pressurized',
}

export const SORT = {
     ASC: 'asc',
     DESC: 'desc'
}
// If device has last transmision or not
export const STATUS = {
     ACT: 'Activated', 
     DEACT: 'Non activated'
}
export const PAGE_SIZE_OPTIONS = [
     20, 50, 100, 200
]

export class Utils {

     public static barToPsi(value: number): number {
          return value*14.5038
     }

     public static psiToBar(value: number): number {
          return value / 14.5038
     }

     public static celsiusToFahrenheit(value: number): number {
          return value * 1.800 + 32 
     }

     public static fahrenheitToCelsius(value: number): number {
          return (value - 32) / 1.8
     }

     public static round(value: number, n: number): string {
          return (Math.round(value * 100)/100).toFixed(n)
     }

     public static downloadFile(data: any, filename: string, filetype: string) {
          const blob = new Blob([data], { type: filetype });
          saveAs(blob, filename)
      }

     public static downloadCsvFile(data: any, filename: string, convertPressure: boolean, convertTemperature: boolean) {
          let array = data.split('\n')
          let pressureIndex = array[0].split(';').indexOf('pressure')
          let temperatureIndex = array[0].split(';').indexOf('temperature')
          let pressureSensorTemperatureIndex = array[0].split(';').indexOf('pressure_sensor_temperature')
          // Fill units for titles
          let titles = array[0].split(';')
          titles[0] = "date(UTC)"
          if(pressureIndex != -1) {
               if(convertPressure) {
                    titles[pressureIndex] = "pressure(Psi)"
               } else {
                    titles[pressureIndex] = "pressure(Bar)"
               }
               if(convertTemperature && pressureSensorTemperatureIndex != -1) {
                    titles[pressureSensorTemperatureIndex] = "temperature(F)"
               } else {
                    titles[pressureSensorTemperatureIndex] = "temperature(C)"
               }
          } else if(temperatureIndex != -1) {
               if(convertTemperature) {
                    titles[temperatureIndex] = "temperature(F)"
               } else {
                    titles[temperatureIndex] = "temperature(C)"
               }
          }
          array[0] = ''
          titles.forEach(title => array[0] = array[0] + title + ";")

          // "pressureIndex != -1" means this is a pressure sensor; "temperatureIndex != -1" means this is a temperature sensor
          let convertData = array[0] + '\n'
          if((pressureIndex != -1 && !convertPressure && !convertTemperature) || (temperatureIndex != -1 && !convertTemperature)) {
               array
               .slice(1)
               .forEach(s=> convertData = convertData + s + '\n')
               const blob = new Blob([convertData], { type: 'text/csv' });
               saveAs(blob, filename)
          } else {
               array
               .slice(1)
               .forEach(s => {
                    let items = s.split(';')
                    if(pressureIndex != -1) {
                         if(convertPressure) {
                              items[pressureIndex] = this.round(this.barToPsi(Number(items[pressureIndex])), 3)
                         }
                         if(convertTemperature && pressureSensorTemperatureIndex != -1) {
                              items[pressureSensorTemperatureIndex] = this.round(this.celsiusToFahrenheit(items[pressureSensorTemperatureIndex]), 3)
                         }
                    } else if(temperatureIndex != -1) {
                         if(convertTemperature) {
                              items[temperatureIndex] = this.round(this.celsiusToFahrenheit(items[temperatureIndex]), 3)
                         }
                    }

                    items
                    .slice(0, items.length - 1)
                    .forEach(item => {
                         convertData = convertData + item + ';'
                    })
                    convertData = convertData + '\n'
               })
               const blob = new Blob([convertData], { type: 'text/csv' });
               saveAs(blob, filename)
          }
     }
    
 
}