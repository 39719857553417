import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, of } from 'rxjs'
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { filter, switchMap, tap, catchError } from 'rxjs/operators';
import { Asset } from 'src/app/models/asset.model';
import * as mainReducers from '../../reducers/index';
import * as assetActions from '../../store/asset/asset.actions';
import * as measurementPointActions from '../../store/measurement-point/measurement-point.actions';
import { ApiService } from 'src/app/services/api-service.service';
import { MeasurementPoint } from 'src/app/models/measurement-point.model';
import { AssetDialogComponent } from '../../components/asset-dialog/asset-dialog.component';
import { MeasurementPointDialogComponent } from '../../components/measurement-point-dialog/measurement-point-dialog.component';
import { UploadDialogComponent } from '../../components/upload-dialog/upload-dialog.component'
import { MeasurementPointFilterBarComponent } from '../../components/measurement-point-filter-bar/measurement-point-filter-bar.component';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { Association } from 'src/app/models/association.model';
import { AssetState } from 'src/app/store/asset/asset.reducer';

@Component({
  selector: 'app-asset-detail',
  templateUrl: './asset-detail.component.html',
  styleUrls: ['./asset-detail.component.scss']
})
export class AssetDetailComponent implements OnInit, OnDestroy {
  @ViewChild(MeasurementPointFilterBarComponent) measurementPointFilterBarComponent: MeasurementPointFilterBarComponent

  timer: any
  asset$: Observable<Asset>
  assetSubscription1: Subscription
  assetSubscription2: Subscription
  assetPhoto: any
  assetSchema: any
  measurementPoints$: Observable<MeasurementPoint[]>
  isPhotoLoading: boolean = false
  isSchemaLoading: boolean = false

  constructor(
    private store: Store<mainReducers.State>,
    private router: Router, 
    private route: ActivatedRoute, 
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public dialog2: MatDialog,
    public confirmDialog: MatDialog,
  ) {}
  
  ngOnInit() {
    this.asset$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.store.select(mainReducers.selectFeatureSingleAssetById, { id: params.get('identifier') })) 
    )

    // Sets asset as selected
    this.assetSubscription1 = this.asset$.pipe(
      filter(asset => (asset != null))
    ).subscribe(asset => 
      this.store.dispatch(new assetActions.SetSelectedAsset(asset))
    )

    // Gets assets MeasurementPoints => pourrait être déclenché au moment où l'on clique
    this.assetSubscription2 = this.asset$.pipe(
      filter(asset => asset && asset.measurementPoints != null)
    ).subscribe(asset => {
        this.store.dispatch(new measurementPointActions.LoadMeasurementPoints({ assetId: asset.identifier, measurementPoints: asset.measurementPoints }))
        this.startAssetUpdateInterval(asset)
    })

    this.measurementPoints$ = this.store.select(mainReducers.selectFeatureMeasurementPointsEntitiesAsArray)

    // Gets asset Photo
    this.asset$.pipe(
      filter(asset => asset != null),
      filter(asset => asset.photo != null),
      tap(() => this.isPhotoLoading = true),
      switchMap((asset) =>
        this.apiService.getAssetPhoto(asset.identifier)
      )
    ).subscribe(
      res => this.assetPhoto = this.sanitizePhotoSrc(res),
      error => this.isPhotoLoading = false
    )
    
    // Gets asset Schematics
    this.asset$.pipe(
      filter(asset => asset != null),
      filter(asset => asset.schema != null),
      tap(() => this.isSchemaLoading = true),
      switchMap((asset) =>
        this.apiService.getAssetSchema(asset.identifier)
      )
    ).subscribe(
      res => this.assetSchema = this.sanitizeSchemaSrc(res),
      error => this.isSchemaLoading = false
    )

  }

  private startAssetUpdateInterval(asset: Asset) {
    if(!this.timer) {
      this.timer = setInterval(
        () => this.store.dispatch(new measurementPointActions.LoadMeasurementPoints({ assetId: asset.identifier, measurementPoints: asset.measurementPoints })),
        60*1000
      )
    } else {
      clearInterval(this.timer)
      this.timer = null 
      this.startAssetUpdateInterval(asset)
    }
    
  }

  editAsset(): void {
    let assetDialog: Asset
    this.asset$.subscribe(
      asset => assetDialog = asset
    )
    
    const dialogRef = this.dialog.open(AssetDialogComponent, {
      width: '450px',
      data: { action: 'edit', asset: assetDialog }
    });

    dialogRef.afterClosed()
    .pipe(
        filter(result => result != null)
    )
    .subscribe((result: Asset) => {
      this.store.dispatch(new assetActions.UpdateAsset(result))
    });
  }

  deleteAsset(): void {
    let currentAsset: Asset = null;
    let removable: boolean = true;
    let message: string = "Are you sure to remove this asset ?";
    this.asset$.pipe(
      filter(asset => asset != null),
    ).subscribe(
      asset => {
        currentAsset = asset;
        if(asset.measurementPoints != null && asset.measurementPoints.length) {
          asset.measurementPoints
            .filter((mp: MeasurementPoint) => mp.associations != null && mp.associations.length > 0)
            .forEach((mp: MeasurementPoint) => {
              mp.associations
              .filter((association: Association) => association != null && association.end == null)
              .filter((association: Association) => association != null)
              .map(() => {
                removable = false;
                message = "Please disociate sensor and measurement point at first.";
              })
            })
        }
      }
      
    )
    const confirmDialog = this.confirmDialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: {
        title: currentAsset == null? "Delete Asset": "Delete Asset - " + currentAsset.reference, 
        message: message
      }
    });

    confirmDialog.afterClosed()
      .pipe(
        filter(result => result),
      )
      .subscribe(() => {
        if(removable) {
          this.store.dispatch(new assetActions.DeleteAsset({ id: currentAsset.identifier }));
          this.store.select(mainReducers.selectFeatureAssetsLoadState).subscribe(
            (state: AssetState) => {
              if(state == AssetState.DELETE_OK) {
                this.router.navigate(['dashboard-assets'])
              }
            }
          )
        }
      })
  }

  newMeasurementPoint(): void {
    const dialogRef2 = this.dialog2.open(MeasurementPointDialogComponent, {
      width: '450px',
      data: {}
    });

    dialogRef2.afterClosed()
    .pipe(
      filter(result => result != null)
    )
    .subscribe((result: any) => {
      this.store.dispatch(new measurementPointActions.AddMeasurementPoint(result.measurementPoint))
      if(result.device) {
        console.log('need to create association ' + result.device)
      }
      if(result.startDate) {
        console.log('need to create start date')
      }
    });
  }

  uploadFile(fileType = 'asset-photo') {
    let assetDialog: Asset
    
    this.asset$.subscribe(
      asset => assetDialog = asset
    )

    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: '500px',
      data: { identifier: assetDialog.identifier, fileType: fileType }
    });

    dialogRef.afterClosed()
      .pipe(
        catchError(error => of(error)),
        filter(result => result === true)
      )
      .subscribe(
        result => {
          this.store.dispatch(new assetActions.LoadAssets)
        },
        err => console.log(err)
      );
  }

  sanitizePhotoSrc(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.assetPhoto = this.sanitizer.bypassSecurityTrustUrl(reader.result.toString());
      this.isPhotoLoading = false
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  sanitizeSchemaSrc(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.assetSchema = this.sanitizer.bypassSecurityTrustUrl(reader.result.toString());
      this.isSchemaLoading = false
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  goToAssetList() {
    this.router.navigate(['dashboard-assets']);
  }

  expandMeasurementPoint(measurementPoint: MeasurementPoint) {
    this.measurementPoints$.subscribe((measurementPoints) => {
      measurementPoints.forEach(mp => {
        if(mp.identifier === measurementPoint.identifier) {
          mp.expandPanel = true
        } else {
          mp.expandPanel = false
        }
      })
    })
  }

  ngOnDestroy() {
    this.assetSubscription1.unsubscribe();
    this.assetSubscription2.unsubscribe();
    if(this.timer) {
      console.info('clearInterval')
      clearInterval(this.timer)
    }
    
    this.store.dispatch(new measurementPointActions.ClearMeasurementPoints())
    this.store.dispatch(new assetActions.UnsetSelectedAsset())
  }
}