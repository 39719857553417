
import { AssetTypeActions, AssetTypeActionTypes } from './asset-type.actions';
import { AssetType } from 'src/app/models/asset-type.model';

export interface State {
  entities: AssetType[],
  isLoading: boolean
}

export const initialState: State = {
  entities: null,
  isLoading: false
};

export function reducer(state = initialState, action: AssetTypeActions): State {
  switch (action.type) {

    case AssetTypeActionTypes.LoadAssetTypes:
      return Object.assign({}, state, {
        isLoading: true
      });

    case AssetTypeActionTypes.LoadAssetTypesSuccess:
      const assetTypes: AssetType[] = action.payload;
      return Object.assign({}, state, {
        entities: assetTypes,
        isLoading: false
      });

    case AssetTypeActionTypes.LoadAssetTypesFailure:
      return Object.assign({}, state, {
        isLoading: false
      });

    default:
      return state;
  }
}
