import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { ApiService } from 'src/app/services/api-service.service';

@Component({
  selector: 'upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit {

  fileForm: FormGroup

  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  fileName: string;
  fileSize: number;
  progressPercentage: number = 0;
  isLoading: boolean = false


  constructor(
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private apiService: ApiService,
    private errorSnackBar: MatSnackBar,
    
  ) {
    this.options = { concurrency: 1, maxUploads: 1, allowedContentTypes: ['image/png', 'image/jpeg'] };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {
    this.fileForm = this.fb.group({
      file: ['', Validators.required],
    });
    
  }

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
          // uncomment this if you want to auto upload files when added
          // const event: UploadInput = {
          //   type: 'uploadAll',
          //   url: '/upload',
          //   method: 'POST',
          //   data: { foo: 'bar' }
          // };
          // this.uploadInput.emit(event);
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          this.files.push(output.file);
          this.fileName = output.file.name
          this.fileSize = Math.round(output.file.size / 1024)
        }
        break;
      case 'start':
        if (typeof output.file !== 'undefined') {
          this.isLoading = true
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          this.progressPercentage = output.file.progress.data.percentage
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'cancelled':
        if (typeof output.file !== 'undefined') {
          this.dialogRef.close();
        }
        break;
      case 'removed':
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'done':
        if (output.file.responseStatus === 200) {
          this.dialogRef.close(true);
        } else {
          this.dialogRef.close(false);
          this.errorSnackBar.open('Error, upload failed !', null, {
            duration: 3000
          })
        }
        break;
    }
  }

  startUpload(): void {
    let uploadUrlData: {
      url: string,
      headers: any
    }
    
    uploadUrlData = this.apiService.getUploadFileUrl(this.data.identifier, this.data.fileType)
    const event: UploadInput = {
      type: 'uploadAll',
      url: uploadUrlData.url,
      method: 'POST',
      headers: uploadUrlData.headers
    };

    this.uploadInput.emit(event);
  }

  cancelUpload(): void {
    const event: UploadInput = {
      type: 'cancelAll'
    }
    this.uploadInput.emit(event);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    let result: string =  (this.fileForm.value.file)
    this.dialogRef.close(result)
  }

}
