import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { AssetsOwner } from "src/app/models/bvuser.model";


export enum AssetsOwnerActionTypes {
    // Search asset owners
    LoadAssetsOwners = "[AssetsOwner] Load AssetsOwners",
    LoadAssetsOwnersSuccess = "[AssetsOwner] Load AssetsOwners Success",
    LoadAssetsOwnersFailure = "[AssetsOwner] Load AssetsOwners Failure",
    // Create a new asset owner
    AddAssetsOwner = "[AssetsOwner] Add AssetsOwner",
    AddAssetsOwnerSuccess = "[AssetsOwner] Add AssetsOwner Success",
    AddAssetsOwnerFailure = "[AssetsOwner] Add AssetsOwner Failure",
    // Update an asset owner
    UpdateAssetsOwner = "[AssetsOwner] Update AssetsOwner",
    UpdateAssetsOwnerSuccess = "[AssetsOwner] Update AssetsOwner Success", 
    UpdateAssetsOwnerFailure = "[AssetsOwner] Update AssetsOwner Failure",
    // Suspend an asset owner
    SuspendAssetsOwner = "[AssetsOwner] Suspend AssetsOwner",
    SuspendAssetsOwnerSuccess = "[AssetsOwner] Suspend AssetsOwner Success",
    SuspendAssetsOwnerFailure = "[AssetsOwner] Suspend AssetsOwner Failure",
    // Activate an asset owner
    ActivateAssetsOwner = "[AssetsOwner] Activate AssetsOwner",
    ActivateAssetsOwnerSuccess = "[AssetsOwner] Activate AssetsOwner Success",
    ActivateAssetsOwnerFailure = "[AssetsOwner] Activate AssetsOwner Failure",
    // Change technician as administrator
    ChangeRoleAsAdmin = "[AssetsOwner] Change Role As Administrator",
    ChangeRoleAsAdminSuccess = "[AssetsOwner] Change Role As Administrator Success",
    ChangeRoleAsAdminFailure = "[AssetsOwner] Change Role As Administrator Failure",
}

export class LoadAssetsOwners implements Action {
    readonly type = AssetsOwnerActionTypes.LoadAssetsOwners;
}

export class LoadAssetsOwnersSuccess implements Action {
    readonly type = AssetsOwnerActionTypes.LoadAssetsOwnersSuccess;
    constructor(public payload: AssetsOwner[]) {}
}

export class LoadAssetsOwnersFailure implements Action {
    readonly type = AssetsOwnerActionTypes.LoadAssetsOwnersFailure;
    constructor(public payload: { error : any }) {}
}

export class AddAssetsOwner implements Action {
    readonly type = AssetsOwnerActionTypes.AddAssetsOwner;
    constructor(public payload: AssetsOwner) {}
}

export class AddAssetsOwnerSuccess implements Action {
    readonly type = AssetsOwnerActionTypes.AddAssetsOwnerSuccess;
    constructor(public payload: AssetsOwner) {}
}

export class AddAssetsOwnerFailure implements Action {
    readonly type = AssetsOwnerActionTypes.AddAssetsOwnerFailure;
    constructor(public payload: { error: any }) {}
}

export class UpdateAssetsOwner implements Action {
    readonly type = AssetsOwnerActionTypes.UpdateAssetsOwner;
    constructor(public payload: AssetsOwner) {}
}

export class UpdateAssetsOwnerSuccess implements Action {
    readonly type = AssetsOwnerActionTypes.UpdateAssetsOwnerSuccess;
    constructor(public payload: Update<AssetsOwner>) {}
}

export class UpdateAssetsOwnerFailure implements Action {
    readonly type = AssetsOwnerActionTypes.UpdateAssetsOwnerFailure;
    constructor(public payload: { error: any }) {}
}


export class SuspendAssetsOwner implements Action {
    readonly type = AssetsOwnerActionTypes.SuspendAssetsOwner;
    constructor(public payload: string) {}
}

export class SuspendAssetsOwnerSuccess implements Action {
    readonly type = AssetsOwnerActionTypes.SuspendAssetsOwnerSuccess;
    constructor(public payload: Update<AssetsOwner>) {}
}

export class SuspendAssetsOwnerFailure implements Action {
    readonly type = AssetsOwnerActionTypes.SuspendAssetsOwnerFailure;
    constructor(public payload: { error: any }) {}
}

export class ActivateAssetsOwner implements Action {
    readonly type = AssetsOwnerActionTypes.ActivateAssetsOwner;
    constructor(public payload: string) {}
}

export class ActivateAssetsOwnerSuccess implements Action {
    readonly type = AssetsOwnerActionTypes.ActivateAssetsOwnerSuccess;
    constructor(public payload: Update<AssetsOwner>) {}
}

export class ActivateAssetsOwnerFailure implements Action {
    readonly type = AssetsOwnerActionTypes.ActivateAssetsOwnerFailure;
    constructor(public payload: { error: any }) {}
}

export class ChangeRoleAsAdmin implements Action {
    readonly type = AssetsOwnerActionTypes.ChangeRoleAsAdmin;
    constructor(public payload: AssetsOwner) {}
}

export class ChangeRoleAsAdminSuccess implements Action {
    readonly type = AssetsOwnerActionTypes.ChangeRoleAsAdminSuccess;
    constructor(public payload: AssetsOwner) {}
}

export class ChangeRoleAsAdminFailure implements Action {
    readonly type = AssetsOwnerActionTypes.ChangeRoleAsAdminFailure;
    constructor(public payload: { error: any }) {}
}

export type AssetsOwnerActions = 
    LoadAssetsOwners
    | LoadAssetsOwnersSuccess
    | LoadAssetsOwnersFailure
    | AddAssetsOwner
    | AddAssetsOwnerSuccess
    | AddAssetsOwnerFailure
    | UpdateAssetsOwner
    | UpdateAssetsOwnerSuccess
    | UpdateAssetsOwnerFailure
    | SuspendAssetsOwner
    | SuspendAssetsOwnerSuccess
    | SuspendAssetsOwnerFailure
    | ActivateAssetsOwner
    | ActivateAssetsOwnerSuccess
    | ActivateAssetsOwnerFailure
    | ChangeRoleAsAdmin
    | ChangeRoleAsAdminSuccess
    | ChangeRoleAsAdminFailure
    ;
