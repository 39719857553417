import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Alert } from '../../models/alert.model';
import { AlertActions, AlertActionTypes } from './alert.actions';

export interface State extends EntityState<Alert> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Alert> = createEntityAdapter<Alert>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export function reducer(
  state = initialState,
  action: AlertActions
): State {
  switch (action.type) {
    case AlertActionTypes.AddAlert: {
      return adapter.addOne(action.payload.alert, state);
    }

    case AlertActionTypes.UpsertAlert: {
      return adapter.upsertOne(action.payload.alert, state);
    }

    case AlertActionTypes.AddAlerts: {
      return adapter.addMany(action.payload.alerts, state);
    }

    case AlertActionTypes.UpsertAlerts: {
      return adapter.upsertMany(action.payload.alerts, state);
    }

    case AlertActionTypes.UpdateAlert: {
      return adapter.updateOne(action.payload.alert, state);
    }

    case AlertActionTypes.UpdateAlerts: {
      return adapter.updateMany(action.payload.alerts, state);
    }

    case AlertActionTypes.DeleteAlert: {
      return adapter.removeOne(action.payload.id, state);
    }

    case AlertActionTypes.DeleteAlerts: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case AlertActionTypes.LoadAlerts: {
      return adapter.addAll(action.payload.alerts, state);
    }

    case AlertActionTypes.ClearAlerts: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
