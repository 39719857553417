import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as mainReducers from '../../reducers/index';
import { Administrator, AdminOrganization, AssetsOwner } from 'src/app/models/bvuser.model';
import { Organization } from 'src/app/models/organization.model';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

  userForm: FormGroup;
  userType: string = 'admin'; // 'admin' or 'technician' // 'supervisor'
  actionType: string = 'new'; // 'new' or 'edit';
  errorMsg: string = '';
  organizations$: Observable<Organization[]>

  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private store: Store<mainReducers.State>
  ) {}

  ngOnInit() {
    if(this.data.action) {
      this.actionType = this.data.action;
    }
    if(this.data.userType) {
      this.userType = this.data.userType;
    }
    if(this.data.userEntity) {
      this.userForm = this.fb.group({
        firstName: [this.data.userEntity.firstName, Validators.required],
        lastName: [this.data.userEntity.lastName, Validators.required],
        login: [this.data.userEntity.login, Validators.required],
        organizations: [this.data.userEntity.managingOrganizations.map((org: Organization) => org.identifier), Validators.required],
      }) 
    } else {
      this.userForm = this.fb.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        login: ['', Validators.required],
        password: ['', Validators.required],
        organizations: ['', Validators.required],
      })
    }

    this.organizations$ = this.store.select(mainReducers.selectFeatureBvuserManagingOrganizations)
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    let newUser: Administrator|AssetsOwner = {
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      login: this.userForm.value.login,
      password: this.userForm.value.password,
      asAdmin: this.userType==='admin'? true:false,
      managingOrganizations: this.userForm.value.organizations==null||this.userForm.value.organizations.length==0?[]:this.userForm.value.organizations.map((id: string) => {
        let managingOrg: Organization = { identifier: id }
        return managingOrg;
      }),
      adminOrganizations: this.userForm.value.organizations==null||this.userForm.value.organizations.length==0?[]:this.userForm.value.organizations.map((id: string) => {
        let adminOrg: AdminOrganization = { identifier: id }
        return adminOrg;
      }),
    }

    if(newUser.login && (!newUser.login.includes('@') || !newUser.login.includes('.') || newUser.login.indexOf('@') == newUser.login.length-1)) {
      this.errorMsg = 'Please verify your mail adresse.'
      if(newUser.password && newUser.password.length < 8) {
        this.errorMsg = this.errorMsg + ' The password must be at least 8 characters.'
      }
    } else if(newUser.password && newUser.password.length < 8) {
      this.errorMsg = 'The password must be at least 8 characters.'
    } else {
      this.errorMsg = ''
    }

    if(!this.errorMsg) {
      if(this.actionType =='edit') {
        newUser.identifier = this.data.userEntity.identifier;
        this.dialogRef.close(Object.assign(this.data.userEntity, newUser));
      } else {
        this.dialogRef.close(newUser);
      }
    }
  }

}
