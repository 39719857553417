import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Association } from '../../models/association.model';
import { Device } from 'src/app/models/device.model';

export enum AssociationActionTypes {
  LoadAssociations = '[Association] Load Associations',
  LoadAssociationsSuccess = '[Association] Load Associations Success',
  LoadAssociationsFailure = '[Association] Load Associations Failure',
  AddAssociation = '[Association] Add Association',
  AddAssociationSuccess = '[Association] Add Association Success',
  AddAssociationFailure = '[Association] Add Association Failure',
  UpdateAssociation = '[Association] Update Association',
  UpdateAssociationSuccess = '[Association] Update Association Success',
  UpdateAssociationFailure = '[Association] Update Association Failure',
  DeleteAssociation = '[Association] Delete Association',
  DeleteAssociationSuccess = '[Association] Delete Association Success',
  DeleteAssociationFailure = '[Association] Delete Association Failure',


  UpsertAssociation = '[Association] Upsert Association',
  AddAssociations = '[Association] Add Associations',
  UpsertAssociations = '[Association] Upsert Associations',
  UpdateAssociations = '[Association] Update Associations',
  DeleteAssociations = '[Association] Delete Associations',
  ClearAssociations = '[Association] Clear Associations'
}

export class LoadAssociations implements Action {
  readonly type = AssociationActionTypes.LoadAssociations;
  constructor(public payload: Device[]) {}
}

export class LoadAssociationsSuccess implements Action {
  readonly type = AssociationActionTypes.LoadAssociationsSuccess;
  constructor(public payload: Association[]) {}
}

export class LoadAssociationsFailure implements Action {
  readonly type = AssociationActionTypes.LoadAssociationsFailure;
  constructor(public payload: { error: any }) {}
}

// Add association
export class AddAssociation implements Action {
  readonly type = AssociationActionTypes.AddAssociation;
  constructor(public payload: {association: Association, measurementPointId: string}) {}
}

export class AddAssociationSuccess implements Action {
  readonly type = AssociationActionTypes.AddAssociationSuccess;
  constructor(public payload: Association) {}
}

export class AddAssociationFailure implements Action {
  readonly type = AssociationActionTypes.AddAssociationFailure;
  constructor(public payload: { error: any }) {}
}

// Update Association
export class UpdateAssociation implements Action {
  readonly type = AssociationActionTypes.UpdateAssociation;
  constructor(public payload: {association: Association, measurementPointId: string}) {}
}    

export class UpdateAssociationSuccess implements Action {
  readonly type = AssociationActionTypes.UpdateAssociationSuccess; 
  constructor(public payload: Update<Association>) {}
}    

export class UpdateAssociationFailure implements Action {
  readonly type = AssociationActionTypes.UpdateAssociationFailure;
  constructor(public payload: { error: any }) {}
}

// Delete Association
export class DeleteAssociation implements Action {
  readonly type = AssociationActionTypes.DeleteAssociation;
  constructor(public payload: {association: Association, measurementPointId: string}) {}
}    

export class DeleteAssociationSuccess implements Action {
  readonly type = AssociationActionTypes.DeleteAssociationSuccess; 
  constructor(public payload: { id: string }) {}
}    

export class DeleteAssociationFailure implements Action {
  readonly type = AssociationActionTypes.DeleteAssociationFailure;
  constructor(public payload: { error: any }) {}
}

// default
export class UpsertAssociation implements Action {
  readonly type = AssociationActionTypes.UpsertAssociation;
  constructor(public payload: { association: Association }) {}
}

export class AddAssociations implements Action {
  readonly type = AssociationActionTypes.AddAssociations;
  constructor(public payload: { associations: Association[] }) {}
}

export class UpsertAssociations implements Action {
  readonly type = AssociationActionTypes.UpsertAssociations;
  constructor(public payload: { associations: Association[] }) {}
}

export class UpdateAssociations implements Action {
  readonly type = AssociationActionTypes.UpdateAssociations;
  constructor(public payload: { associations: Update<Association>[] }) {}
}

export class DeleteAssociations implements Action {
  readonly type = AssociationActionTypes.DeleteAssociations;
  constructor(public payload: { ids: string[] }) {}
}

export class ClearAssociations implements Action {
  readonly type = AssociationActionTypes.ClearAssociations;
}

export type AssociationActions =
 LoadAssociations
 | LoadAssociationsSuccess
 | LoadAssociationsFailure
 | AddAssociation
 | AddAssociationSuccess
 | AddAssociationFailure
 | UpdateAssociation
 | UpdateAssociationSuccess
 | UpdateAssociationFailure
 | DeleteAssociation
 | DeleteAssociationSuccess
 | DeleteAssociationFailure
 //
 | UpsertAssociation
 | AddAssociations
 | UpsertAssociations
 | UpdateAssociations
 | DeleteAssociations
 | ClearAssociations;
