import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Association } from '../../models/association.model';
import { AssociationActions, AssociationActionTypes } from './association.actions';

export interface State extends EntityState<Association> {
  // additional entities state properties
  isLoading: boolean
}

export const adapter: EntityAdapter<Association> = createEntityAdapter<Association>({
    selectId: selectAssociationId,
  });

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  isLoading: false
});

export function selectAssociationId(association: Association): string {
  return association.identifier;
}

export function reducer(
  state = initialState,
  action: AssociationActions
): State {
  switch (action.type) {
    
    case AssociationActionTypes.LoadAssociations: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }

    case AssociationActionTypes.LoadAssociationsSuccess: {
      return Object.assign({}, adapter.addAll(action.payload, state), {
        isLoading: false,
      });
    }

    case AssociationActionTypes.LoadAssociationsFailure: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }

    // Add
    case AssociationActionTypes.AddAssociation: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }

    case AssociationActionTypes.AddAssociationSuccess: {
      return Object.assign({}, adapter.addOne(action.payload, state), {
        isLoading: false,
      });
    }

    case AssociationActionTypes.AddAssociationFailure: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }

    // Update
    case AssociationActionTypes.UpdateAssociation: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }

    case AssociationActionTypes.UpdateAssociationSuccess: {
      return Object.assign({}, adapter.updateOne(action.payload, state), {
        isLoading: false,
      });
    }

    case AssociationActionTypes.UpdateAssociationFailure: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }

    // Not used
    case AssociationActionTypes.DeleteAssociation: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }

    case AssociationActionTypes.DeleteAssociationSuccess: {
      return Object.assign({}, adapter.removeOne(action.payload.id, state), {
        isLoading: false,
      });
    }

    case AssociationActionTypes.DeleteAssociationFailure: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }

    case AssociationActionTypes.UpsertAssociation: {
      return adapter.upsertOne(action.payload.association, state);
    }

    case AssociationActionTypes.AddAssociations: {
      return adapter.addMany(action.payload.associations, state);
    }

    case AssociationActionTypes.UpsertAssociations: {
      return adapter.upsertMany(action.payload.associations, state);
    }

    case AssociationActionTypes.UpdateAssociations: {
      return adapter.updateMany(action.payload.associations, state);
    }

    case AssociationActionTypes.DeleteAssociations: {
      return adapter.removeMany(action.payload.ids, state);
    }

    case AssociationActionTypes.ClearAssociations: {
      return adapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
