import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatMenuTrigger } from '@angular/material';
import { Store } from '@ngrx/store';
import * as mainReducers from '../../reducers/index';
import * as assetsOwnerActions from '../../store/assets-owner/assets-owner.actions';
import { AssetsOwner, Bvuser } from 'src/app/models/bvuser.model';
import { BvuserMapHelper } from 'src/app/shared/bvuser-map-helper';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: '[asset-owner-line]',
  templateUrl: './asset-owner-line.component.html',
  styleUrls: ['./asset-owner-line.component.scss']
})
export class AssetOwnerLineComponent implements OnInit {
  @ViewChild(MatMenuTrigger) moreActions: MatMenuTrigger;
  @Input() assetsOwner: AssetsOwner; 

  public isAdmin: boolean;
  public isSuperAdmin: boolean;
  public isUserself: boolean;
  public $bvuser: Observable<Bvuser>;
  public organizationNames: string = "";

  constructor(
    private store: Store<mainReducers.State>,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.$bvuser = this.store.select(mainReducers.selectFeatureBvuserEntity);
    this.$bvuser.subscribe((user: Bvuser) => {
      this.isUserself = user.identifier === this.assetsOwner.identifier;
      this.isAdmin = user.asAdmin;
      this.isSuperAdmin = user.identifier.startsWith('assets_monitoring@administrator');
    })
    this.organizationNames = BvuserMapHelper.getAllOrganizationNames(this.assetsOwner.managingOrganizations);
  }

  editAssetsOwner(): void {
    let technician: AssetsOwner = this.assetsOwner;
    const dialog = this.dialog.open(UserDialogComponent, {
      width: '450px',
      data: { action: 'edit', userType: 'technician', userEntity: technician }
    })

    dialog.afterClosed()
    .pipe(
      filter(result => result != null)
    )
    .subscribe((result: AssetsOwner) => 
      this.store.dispatch(new assetsOwnerActions.UpdateAssetsOwner(result))
    );
  }

  suspendAssetsOwner(): void {
    this.store.dispatch(new assetsOwnerActions.SuspendAssetsOwner(this.assetsOwner.identifier))
  }

  activateAssetsOwner(): void {
    this.store.dispatch(new assetsOwnerActions.ActivateAssetsOwner(this.assetsOwner.identifier))
  }

  asAdministrator(): void {
    const tech: AssetsOwner = Object.assign({}, this.assetsOwner, { asAdmin: true })
    this.store.dispatch(new assetsOwnerActions.ChangeRoleAsAdmin(tech))
  }

}
