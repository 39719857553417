export const ALERT_COLOR_LABELS: string[] = [
        "red",
        "darkOrange",
        "gold",
        "dodgerBlue",
        "oliveDrab",
        "rebeccaPurple",
        "yellow",
        "midnightBlue",
        "green"
    ]