import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatMenuTrigger } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as mainReducers from '../../reducers/index';
import * as adminActions from '../../store/administrator/administrator.actions';
import { Administrator, Bvuser } from 'src/app/models/bvuser.model';
import { BvuserMapHelper } from 'src/app/shared/bvuser-map-helper';
import { UserDialogComponent } from '../user-dialog/user-dialog.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: '[admin-line]',
  templateUrl: './admin-line.component.html',
  styleUrls: ['./admin-line.component.scss']
})
export class AdminLineComponent implements OnInit {
  @ViewChild(MatMenuTrigger) moreActions: MatMenuTrigger;
  @Input() administrator: Administrator;

  public $bvuser: Observable<Bvuser>;
  public isUserself: boolean;
  public isSuperAdmin: boolean;
  public organizationNames: string = "";

  constructor(
    private store: Store<mainReducers.State>,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.$bvuser = this.store.select(mainReducers.selectFeatureBvuserEntity);
    this.$bvuser.subscribe((user: Bvuser) => {
      this.isUserself = user.identifier === this.administrator.identifier;
      this.isSuperAdmin = user.identifier.startsWith('assets_monitoring@administrator')
    })
    this.organizationNames = BvuserMapHelper.getAllOrganizationNames(this.administrator.managingOrganizations);
  }

  editAdmin(): void {
    let admin: Administrator = this.administrator;
    const dialog = this.dialog.open(UserDialogComponent, {
      width: '450px',
      data: { action: 'edit', userType: 'admin', userEntity: admin }
    })
    dialog.afterClosed()
    .pipe(
      filter(result => result != null)
    )
    .subscribe((result: Administrator) =>
      this.store.dispatch(new adminActions.UpdateAdministrator(result))
    );
  }

  suspendAdmin(): void {
    this.store.dispatch(new adminActions.SuspendAdministrator(this.administrator.identifier))
  }

  activateAdmin(): void {
    this.store.dispatch(new adminActions.ActivateAdministrator(this.administrator.identifier))
  }
  
  asTechnician(): void {
    const admin: Administrator = Object.assign({}, this.administrator, { asAdmin: false })
    this.store.dispatch(new adminActions.ChangeRoleAsTechnician(admin))
  }

}
