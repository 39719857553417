import { Organization } from './organization.model';

export interface UserPreferences {
  emailOptions?: string;
  pressureUnit?: string;
  temperatureUnit?: string;
}

export interface Bvuser {
  identifier?: string;
  login: string;
  password?: string;
  vestalisIdentifier?: string;
  firstName: string;
  lastName: string;
  asAdmin: boolean;
  active?: boolean;
  asSupervisor?: boolean; // TODO: create in the futur
  managingOrganizations: Organization[];
  adminOrganizations: AdminOrganization[];
  adminOrganizationEntities?: Organization[];
}

export interface AdminOrganization {
  identifier: string;
}

export interface BvuserCredentials {
  username: string;
  password: string;
}

export interface Administrator extends Bvuser {}

export interface AssetsOwner extends Bvuser {}

export const USER_STATUS_VARIABLES = [
  { id: 1, status: 'Active', description: 'account active' },
  { id: 2, status: 'Suspend', description: 'account suspend' }
]

export enum UserType {
  SUPER_ADMIN, 
  ADMIN,
  ASSETS_OWNER, 
  SUPERVISOR
}

export enum PrefState {
  CREATED,
  UPDATED, 
  ERROR // while load failure
}