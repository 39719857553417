import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs'

import { Bvuser } from '../../models/bvuser.model'

import * as mainReducers from '../../reducers/index';
import * as bvuserActions from '../../store/bvuser/bvuser.actions';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  public $user: Observable<Bvuser>;

  constructor(
    private store: Store<mainReducers.State>,
  ) { }

  ngOnInit() {
    this.$user = this.store.select(mainReducers.selectFeatureBvuserEntity)
  }

  logout() {
    this.store.dispatch(new bvuserActions.LogoutBvuser())
  }

}
