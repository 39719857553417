import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AccountFilterBarComponent } from 'src/app/components/account-filter-bar/account-filter-bar.component';
import { UserDialogComponent } from 'src/app/components/user-dialog/user-dialog.component';
import { AssetsOwner, UserType } from 'src/app/models/bvuser.model';
import * as assetsOwnerActions from '../../store/assets-owner/assets-owner.actions';
import * as mainReducers from '../../reducers/index';
import { AssetsOwnerState } from 'src/app/store/assets-owner/assets-owner.reducer';

@Component({
  selector: 'app-technicians',
  templateUrl: './technicians.component.html',
  styleUrls: ['./technicians.component.scss']
})
export class TechniciansComponent implements OnInit {
  @ViewChild(AccountFilterBarComponent) accountFilterBarComponent: AccountFilterBarComponent

  public isAdmin: boolean;
  public $bvuserType: Observable<UserType>;
  public assetsOwners$: Observable<AssetsOwner[]>;

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private store: Store<mainReducers.State>
  ) {}

  ngOnInit() {
    this.$bvuserType = this.store.select(mainReducers.selectFeatureBvuserType) 
    this.$bvuserType.subscribe(userType => this.isAdmin = (userType === UserType.SUPER_ADMIN || userType === UserType.ADMIN))
    // reload technicians
    this.store.dispatch(new assetsOwnerActions.LoadAssetsOwners());
    this.store.select(mainReducers.selectFeatureAssetsOwnerStateAndError)
      .subscribe((res: {state: AssetsOwnerState, error: any }) => {
        if(res.state == AssetsOwnerState.ADD_ASSETS_OWNER_OK) {
          this.snackBar.open('A new technician created successfully.')
        } else if(res.state == AssetsOwnerState.ADD_ASSETS_OWNER_KO) {
          this.snackBar.open('The new technician created fallure. ' + res.error)
        }
      })
  }

  newTechnician(): void {
    const dialog = this.dialog.open(UserDialogComponent, {
      width: '450px',
      data: { action: 'new', userType: 'technician' }
    })

    dialog.afterClosed()
    .pipe(
      filter(result => result != null)
    )
    .subscribe((result: AssetsOwner) =>
      this.store.dispatch(new assetsOwnerActions.AddAssetsOwner(result))
    );
  }

}
