import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators'
import { Store } from '@ngrx/store';
import * as mainReducers from '../../reducers/index';
import { Asset } from '../../models/asset.model';
import { Organization } from '../../models/organization.model';
import { AssetType } from '../../models/asset-type.model';
import { ALERT_COLOR_LABELS } from '../../shared/alert-color-labels';
import { MeasurementPoint } from '../../models/measurement-point.model';
import { Alert } from '../../models/alert.model';
import { AlertTemplate } from '../../models/alert-template.model';

@Component({
  selector: 'asset-filter-bar',
  templateUrl: './asset-filter-bar.component.html',
  styleUrls: ['./asset-filter-bar.component.scss']
})
export class AssetFilterBarComponent implements OnInit {

  assetFilterForm: FormGroup;
  filteredOptions$: Observable<Asset[]> = null;

  organizations: Organization[]
  organizations$: Observable<Organization[]>
  assetTypes$: Observable<AssetType[]>
  assetTypes: AssetType[]
  alertColorLabels: string[] = ALERT_COLOR_LABELS

  @Output() onSelectedOption = new EventEmitter();

  constructor(
    private store: Store<mainReducers.State>,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    //gets organizations and asset types
    this.organizations$ = this.store.select(mainReducers.selectFeatureBvuserManagingOrganizations)
    this.organizations$.subscribe(organizations => this.organizations = organizations)
    this.assetTypes$ = this.store.select(mainReducers.selectFeatureAssetTypeEntities)
    this.assetTypes$.subscribe(res => this.assetTypes = res)

    this.assetFilterForm = this.fb.group({
      organization: '',
      type: '',
      alert: '',
      name: ''
    })

    this.filteredOptions$ = 
      this.store.select(mainReducers.selectFeatureAssetsEntitiesAsArray)
        .pipe(
          filter(assets => (assets.length > 0)),
          switchMap(assets =>
            this.assetFilterForm.valueChanges.pipe(
              startWith({name: '', organization: '', alert: '', type: ''}),
              map(values => this._filter(assets, values)),
            )
          )  
      );
  }

  // this is where filtering the data happens according to you typed value
  private _filter(allAssets: Asset[], values: any): Asset[] {
    const filterValue = values.name? values.name.toLowerCase() : '';

    let filteredAssets: Asset[] = allAssets

    if ((values.organization != '') && (values.organization != 'all')) {
      let targetOrg = this.organizations
        .filter(org => org.identifier==values.organization || (org.partOf && org.partOf.identifier==values.organization))
        .map(org => org.identifier)
        .toString()
      filteredAssets = filteredAssets
        .filter(asset => targetOrg.includes(asset.organization.identifier))
    }

    if ((values.type != '') && (values.type != 'all')) {
      filteredAssets = filteredAssets
        .filter(asset => asset.type.identifier == values.type)
    }

    if ((values.alert != '') && (values.alert != 'all')) {
      filteredAssets = filteredAssets
        .filter(asset => asset.measurementPoints)
        .filter(asset => asset.measurementPoints
          .filter(measurementPoint => measurementPoint.activeAlerts)
          .find(
            (measurementPoint: MeasurementPoint) => {
              return measurementPoint.activeAlerts.find(
              (activeAlert: Alert) => measurementPoint.alertTemplates.find((alertTemplate: AlertTemplate) => alertTemplate.identifier == activeAlert.alertTemplate.identifier).label == values.alert
              )
            }
        )
      )
    }

    if (values.name != '') {
      if (values.name.length > 1) {
        return filteredAssets.filter(asset => asset.reference.toLowerCase().indexOf(filterValue) != -1) // === 0 pour exact match par le début
      } else {
        return filteredAssets
      }
    } else {
      return filteredAssets
    }

  }
}
