import { Update } from "@ngrx/entity";
import { Action } from "@ngrx/store";
import { Administrator } from "src/app/models/bvuser.model";

export enum AdministratorActionTypes {
    // Search administrators
    LoadAdministrators = "[Administrator] Load Administrators",
    LoadAdministratorsSuccess = "[Administrator] Load Administrators Success",
    LoadAdministratorsFailure = "[Administrator] Load Administrators Failure",
    // Create a new administrator
    AddAdministrator = "[Administrator] Add Administrator",
    AddAdministratorSuccess = "[Administrator] Add Administrator Success",
    AddAdministratorFailure = "[Administrator] Add Administrator Failure",
    // Update an administrator
    UpdateAdministrator = "[Administrator] Update Administrator",
    UpdateAdministratorSuccess = "[Administrator] Update Administrator Success", 
    UpdateAdministratorFailure = "[Administrator] Update Administrator Failure",
    // Suspend an administrator
    SuspendAdministrator = "[Administrator] Suspend Administrator",
    SuspendAdministratorSuccess = "[Administrator] Suspend Administrator Success",
    SuspendAdministratorFailure = "[Administrator] Suspend Administrator Failure",
    // Activate an administrator
    ActivateAdministrator = "[Administrator] Activate Administrator",
    ActivateAdministratorSuccess = "[Administrator] Activate Administrator Success",
    ActivateAdministratorFailure = "[Administrator] Activate Administrator Failure",
    // Change role as technician
    ChangeRoleAsTechnician = "[Administrator] Change Role As Technician",
    ChangeRoleAsTechnicianSuccess = "[Administrator] Change Role As Technician Success",
    ChangeRoleAsTechnicianFailure = "[Administrator] Change Role As Technician Failure",
}

export class LoadAdministrators implements Action {
    readonly type = AdministratorActionTypes.LoadAdministrators;
}

export class LoadAdministratorsSuccess implements Action {
    readonly type = AdministratorActionTypes.LoadAdministratorsSuccess;
    constructor(public payload: Administrator[]) {}
}

export class LoadAdministratorsFailure implements Action {
    readonly type = AdministratorActionTypes.LoadAdministratorsFailure;
    constructor(public payload: { error : any }) {}
}

export class AddAdministrator implements Action {
    readonly type = AdministratorActionTypes.AddAdministrator;
    constructor(public payload: Administrator) {}
}

export class AddAdministratorSuccess implements Action {
    readonly type = AdministratorActionTypes.AddAdministratorSuccess;
    constructor(public payload: Administrator) {}
}

export class AddAdministratorFailure implements Action {
    readonly type = AdministratorActionTypes.AddAdministratorFailure;
    constructor(public payload: { error : any }) {}
}

export class UpdateAdministrator implements Action {
    readonly type = AdministratorActionTypes.UpdateAdministrator;
    constructor(public payload: Administrator) {}
}

export class UpdateAdministratorSuccess implements Action {
    readonly type = AdministratorActionTypes.UpdateAdministratorSuccess;
    constructor(public payload: Update<Administrator>) {}
}

export class UpdateAdministratorFailure implements Action {
    readonly type = AdministratorActionTypes.UpdateAdministratorFailure;
    constructor(public payload: { error: any }) {}
}

export class SuspendAdministrator implements Action {
    readonly type = AdministratorActionTypes.SuspendAdministrator;
    constructor(public payload: string) {}
}

export class SuspendAdministratorSuccess implements Action {
    readonly type = AdministratorActionTypes.SuspendAdministratorSuccess;
    constructor(public payload: Update<Administrator>) {}
}

export class SuspendAdministratorFailure implements Action {
    readonly type = AdministratorActionTypes.SuspendAdministratorFailure;
    constructor(public payload: { error: any }) {}
}

export class ActivateAdministrator implements Action {
    readonly type = AdministratorActionTypes.ActivateAdministrator;
    constructor(public payload: string) {}
}

export class ActivateAdministratorSuccess implements Action {
    readonly type = AdministratorActionTypes.ActivateAdministratorSuccess;
    constructor(public payload: Update<Administrator>) {}
}

export class ActivateAdministratorFailure implements Action {
    readonly type = AdministratorActionTypes.ActivateAdministratorFailure;
    constructor(public payload: { error: any }) {}
}

export class ChangeRoleAsTechnician implements Action {
    readonly type = AdministratorActionTypes.ChangeRoleAsTechnician;
    constructor(public payload: Administrator) {}
}

export class ChangeRoleAsTechnicianSuccess implements Action {
    readonly type = AdministratorActionTypes.ChangeRoleAsTechnicianSuccess;
    constructor(public payload: Administrator) {}
}

export class ChangeRoleAsTechnicianFailure implements Action {
    readonly type = AdministratorActionTypes.ChangeRoleAsTechnicianFailure;
    constructor(public payload: { error: any }) {}
}

export type AdministratorActions = 
    LoadAdministrators
    | LoadAdministratorsSuccess
    | LoadAdministratorsFailure
    | AddAdministrator
    | AddAdministratorSuccess
    | AddAdministratorFailure
    | UpdateAdministrator
    | UpdateAdministratorSuccess
    | UpdateAdministratorFailure
    | SuspendAdministrator
    | SuspendAdministratorSuccess
    | SuspendAdministratorFailure
    | ActivateAdministrator
    | ActivateAdministratorSuccess
    | ActivateAdministratorFailure
    | ChangeRoleAsTechnician
    | ChangeRoleAsTechnicianSuccess
    | ChangeRoleAsTechnicianFailure
    ;

