import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { PRESSURE_UNITS, TEMPERATURE_UNITS } from '../../shared/common'
import { PrefState, UserPreferences } from 'src/app/models/bvuser.model';
import * as mainReducers from '../../reducers/index';
import * as bvuserActions from '../../store/bvuser/bvuser.actions';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  pressureUnits = PRESSURE_UNITS
  temperatureUnits = TEMPERATURE_UNITS
  settingsFormGroup: FormGroup
  savingStatus: string  = null
  savingStatusMessage: string = null

  constructor(
    private fb: FormBuilder,
    private store: Store<mainReducers.State>,
  ) {}

  ngOnInit() {
    this.store.select(mainReducers.selectFeatureBvuserPreferences) 
    .subscribe(preferences => {
      this.settingsFormGroup = this.fb.group({
        measurementAlert: [preferences && preferences.emailOptions && preferences.emailOptions.includes('alerts')],
        lowBatteryAlert: [preferences && preferences.emailOptions && preferences.emailOptions.includes('lowBattery')],
        pressureUnit: [(preferences && preferences.pressureUnit)?preferences.pressureUnit:this.pressureUnits[0]],
        temperatureUnit: [(preferences && preferences.temperatureUnit)?preferences.temperatureUnit:this.temperatureUnits[0]]
      })
    })
  }

  onSaveSettings() {
    let mailOptions = '';
    if(this.settingsFormGroup.controls.measurementAlert.value) {
      mailOptions = mailOptions + 'alerts';
    }
    if(this.settingsFormGroup.controls.lowBatteryAlert.value) {
      if(mailOptions.length != 0) {
        mailOptions = mailOptions + ','
      }
      mailOptions = mailOptions + 'lowBattery';
    }
    let preferences: UserPreferences = {
      emailOptions: mailOptions,
      pressureUnit: this.settingsFormGroup.controls.pressureUnit.value,
      temperatureUnit: this.settingsFormGroup.controls.temperatureUnit.value
    }
    this.store.dispatch(new bvuserActions.UpdateUserPreferences(preferences));
    this.store.select(mainReducers.selectFeatureBvuserPreferencesState)
      .subscribe(res => {
        if(res.state == PrefState.UPDATED) {
          this.savingStatus = "save-ok"
          this.savingStatusMessage = "Saved"
        } else if(res.state == PrefState.ERROR) {
          this.savingStatus = "save-error"
          this.savingStatusMessage = "Error: " + res.errorMessage
        } 
      })
  }

  onCancel() {
    this.savingStatus = null
    this.savingStatusMessage = null
  }

}
