import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalVariablesService } from '../shared/global-variables.service';
import { Observable, of, throwError } from 'rxjs'

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded', 
    'Authorization': 'Basic YnVtYmxlYmVlLXZpc2lvbjprbzlrMEpsTjVkY1c='
  })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient, 
    private globalVariables: GlobalVariablesService
  ) {}


  // Only for test
  checkAuthConnection() {
    let requestData = new HttpParams()
      .set('grant_type', 'password',)
      .set('scope', 'READ WRITE')
      .set('username', 'olivier.cathelineau@gmail.com')
      .set('password', 'GxjpamgrNenH')

    return this.http.post(this.globalVariables.getAuthUrl() + '/oauth/token', requestData, httpOptions)
  }

  // Deprecated: replace by openLogin()
  login(username: string, password: string) {
    let requestData = new HttpParams()
    .set('grant_type', 'password',)
    .set('scope', 'READ WRITE')
    .set('username', username)
    .set('password', password)

    return this.http.post<AccessToken>(this.globalVariables.getAuthUrl() + '/oauth/token', requestData, httpOptions)
  }

  //open sirius-authorization-server login page
  openLogin() {
    window.open(this.globalVariables.getAuthUrl(), "_self");
  }

  logout() {
    this.clearAccessToken()
  }

  storeAccessToken(accessToken: AccessToken) {
    localStorage.setItem('access_token', accessToken.access_token)
    localStorage.setItem('token_type', accessToken.token_type)
    // store expired timestamp
    let now = new Date();
    let expiration = now.getTime() + accessToken.expires_in * 1000 + '';
    localStorage.setItem('expiration', expiration);
  }

  clearAccessToken() {
    //localStorage.clear()
    localStorage.removeItem('access_token')
    localStorage.removeItem('token_type')
    localStorage.removeItem('expiration')
  }

  // observable
  checkStoredToken(): Observable<AccessToken> {
    let now: Date = new Date();
    let expiration: string = localStorage.getItem('expiration');
    if (!localStorage.getItem('access_token') || (expiration && now.getTime() >= parseInt(expiration))) {
      return throwError('no access token stored or token is expired')
    } else {
      let accessToken: AccessToken = {
        access_token: localStorage.getItem('access_token'),
        token_type: localStorage.getItem('token_type')
      }
      return of(accessToken)
    }
  }

  // non observable
  getStoredAccessToken(): AccessToken {
    return {
      access_token: localStorage.getItem('access_token'),
      token_type: localStorage.getItem('token_type')
    }
  }
}

export interface AccessToken {
  access_token: string; // 'access_token'
  token_type: string; // 'token_type'
  expires_in?: number; // 'expires_in'
  scope?: string; // 'scope'
  recieved?: number;
}

export default class TokenUtil {
  // non observable
  static parseToken(hash: string): AccessToken {
    let accessToken: AccessToken = {
      access_token: '',
      token_type: '',
      expires_in: 0,
      scope: '',
      recieved: 0
    };
    let params: string[] = hash.substring(1).split('&')
    params.forEach(param => {
      let map = param.split('=')
      if(map[0] === 'access_token')
        accessToken.access_token = map[1] 
      if(map[0] === 'expires_in')
        accessToken.expires_in = parseInt(map[1])
      if(map[0] === 'token_type')
        accessToken.token_type = map[1]
      if(map[0] === 'scope')
        accessToken.scope = map[1]
    })
    return accessToken;
  }
}
