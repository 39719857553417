import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators'
import { Store } from '@ngrx/store';
import * as mainReducers from '../../reducers/index';
import { MeasurementPoint } from '../../models/measurement-point.model';
import { DEVICE_TYPES_VARIABLES } from '../../models/device-type.model';
import { ALERT_COLOR_LABELS } from '../../shared/alert-color-labels';
import { DEVICE_TYPE_VALVE } from 'src/app/shared/common';

@Component({
  selector: 'measurement-point-filter-bar',
  templateUrl: './measurement-point-filter-bar.component.html',
  styleUrls: ['./measurement-point-filter-bar.component.scss']
})
export class MeasurementPointFilterBarComponent implements OnInit {

  measurementPointFilterForm: FormGroup;
  filteredOptions$: Observable<MeasurementPoint[]> = null;
  deviceTypes: any = DEVICE_TYPES_VARIABLES
  alertColorLabels: string[] = ALERT_COLOR_LABELS

  @Output() onSelectedOption = new EventEmitter();

  constructor(
    private store: Store<mainReducers.State>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.measurementPointFilterForm = this.fb.group({
      type: '',
      alert: ''
    })

    this.filteredOptions$ = 
      this.store.select(mainReducers.selectFeatureMeasurementPointsEntitiesAsArray)
        .pipe(
          filter(measurementPoints => (measurementPoints.length > 0)),
          switchMap(measurementPoints =>
            this.measurementPointFilterForm.valueChanges.pipe(
            startWith({type: '', alert: ''}),
            map(values => this._filter(measurementPoints, values)),
          )
        )
    );
  }

  // this is where filtering the data happens according to you typed value
  private _filter(allMeasurementPoints: MeasurementPoint[], values: any): MeasurementPoint[] {

    let filteredMeasurementPoints: MeasurementPoint[] = allMeasurementPoints

    if ((values.type != '') && (values.type != 'all') && (values.type != 'valve')) {
      filteredMeasurementPoints = filteredMeasurementPoints
        .filter(measurementPoint => measurementPoint.lastMeasurement)
        .filter(measurementPoint => measurementPoint.lastMeasurement.hasOwnProperty(values.type))
    } else if(values.type == 'valve') {
      filteredMeasurementPoints = filteredMeasurementPoints
        .filter(measurementPoint => measurementPoint.type == DEVICE_TYPE_VALVE)
    }

    if ((values.alert != '') && (values.alert != 'all')) {
      filteredMeasurementPoints = filteredMeasurementPoints
        .filter(measurementPoint => measurementPoint.activeAlerts)
        .filter(measurementPoint => measurementPoint.activeAlerts.find(
          activeAlert => activeAlert.alertTemplate.label == values.alert
        )
      )
    }

    return filteredMeasurementPoints
  }
}
