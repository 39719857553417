import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment'

@Component({
  selector: 'app-end-association-dialog',
  templateUrl: './end-association-dialog.component.html',
  styleUrls: ['./end-association-dialog.component.scss']
})
export class EndAssociationDialogComponent implements OnInit {

  errorMsg: string = null;
  endAssociationForm: FormGroup
  public maxDate: string = moment(new Date()).format('YYYY-MM-DD')
  public minDate: string

  constructor(
    public dialogRef: MatDialogRef<EndAssociationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.minDate = moment(data.start, 'YYYY[-]MM[-]DD HH[:]mm[:]ss').format('YYYY-MM-DD')
  }

  ngOnInit() {
    this.endAssociationForm = this.fb.group({
      endDate: ['', Validators.required],
      endTime: [moment(new Date()).format('HH[:]mm[:]ss'), Validators.required]
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    let endDate: string = moment(this.endAssociationForm.value.endDate).format('YYYY[-]MM[-]DD') 
    let result: string = moment(endDate + ' ' + this.endAssociationForm.value.endTime).format('YYYY[-]MM[-]DD HH[:]mm[:]ss')
    if(!moment(this.endAssociationForm.value.endTime, "HH:mm:ss", true).isValid()) {
      this.errorMsg = "Please enter the correct end time with format 'HH:mm:ss'"
    }
    if(!this.errorMsg) {
      this.dialogRef.close(result)
    }
  }

}
