import { Component, OnInit, Input } from '@angular/core';
import { Asset } from '../../models/asset.model';
import { Alert } from '../../models/alert.model';
import { MeasurementPoint } from '../../models/measurement-point.model';
import { AlertTemplate } from '../../models/alert-template.model';
import * as mainReducers from '../../reducers/index';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApiService } from 'src/app/services/api-service.service';
import { UserType } from 'src/app/models/bvuser.model';

@Component({
  selector: '[asset-line]',
  templateUrl: './asset-line.component.html',
  styleUrls: ['./asset-line.component.scss']
})
export class AssetLineComponent implements OnInit {
  @Input() asset: Asset;

  alerts: Alert[] = []
  alertLabels: string[] = []
  lowBatteryAlert: boolean = false;
  isSuperAdmin: boolean = false;
  organizationName: string = ''

  constructor(
    private apiService: ApiService,
    private store: Store<mainReducers.State>,
  ) {}

  ngOnInit() {
    this.store.select(mainReducers.selectFeatureBvuserType) 
      .subscribe(userType => this.isSuperAdmin = userType === UserType.SUPER_ADMIN)
    this.store.select(mainReducers.selectFeatureBvuserManagingOrganizations)
    .pipe(
      filter(organizations => organizations != null && organizations.length >0)
    )
    .subscribe(organizations => 
      organizations
      .filter(org => org.identifier == this.asset.organization.identifier)
      .map(org => this.organizationName = org.name)
    )
      
    if(this.asset.measurementPoints) {
      this.alerts = this.asset.measurementPoints
        .filter((measurementPoint: MeasurementPoint) => measurementPoint.activeAlerts)
        .map((measurementPoint: MeasurementPoint) => 
          measurementPoint.activeAlerts
          .filter((alert:Alert) => alert.state=='active')
          .map(
            alert => Object.assign({}, alert, {alertTemplate: measurementPoint.alertTemplates.filter(alertTemplate => (alertTemplate.identifier == alert.alertTemplate.identifier)).shift()})
          )
        )
        .flat();
      this.asset.measurementPoints.forEach((measurementPoint: MeasurementPoint) => 
        this.apiService.getLastMeasurement(measurementPoint, this.asset.identifier)
        .subscribe((measurementPoint: MeasurementPoint) => {
            if(measurementPoint.lastMeasurement && (measurementPoint.lastMeasurement.battery_status == '1' || measurementPoint.lastMeasurement.battery_error == '1')) {
              this.lowBatteryAlert = true;
            }
        })
      )
    }

    this.alerts.forEach((alert: any, index: number) => {
        if(this.alertLabels.includes(alert.alertTemplate.label)) {
          this.alerts.splice(index,1)
        } else {
          this.alertLabels.push(alert.alertTemplate.label)
        }
      }
    )   
  }

  findColorfromAlertTemplateId(alertTemplateId: string, alertTemplates: AlertTemplate[]) {
    let found: boolean = false
    let index: number = 0
    let color: string = null
    
    while (!found) {
      if(!alertTemplates[index]) {
        break
      } else if(alertTemplates[index].identifier == alertTemplateId) {
        color = alertTemplates[index].label
      }
      index++
    }
    return color
  }

}
