import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators'
import { Store } from '@ngrx/store';
import * as mainReducers from '../../reducers/index';
import { Asset } from '../../models/asset.model';
import { Router } from '@angular/router';


@Component({
  selector: 'search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  searchControl = new FormControl();
  filteredOptions: Observable<Asset[]>;
  allAssets: Asset[] = [];
  autoCompleteList: any[];
  searchOption: any[] = [];

  @ViewChild('autocompleteInput') autocompleteInput: ElementRef;
  @Output() onSelectedOption = new EventEmitter();

  constructor(
    private store: Store<mainReducers.State>,
    private router: Router
  ) { }

  ngOnInit() {

    // get all the assets
    this.store.select(mainReducers.selectFeatureAssetsEntitiesAsArray)
    .pipe(
      filter(assets => (assets.length > 0)),
    )
    .subscribe((assets: Asset[]) => {
      this.allAssets = assets
    });

    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

  }

  // this is where filtering the data happens according to you typed value
  private _filter(value: string): Asset[] {
    const filterValue = value? value.toLowerCase() : '';

    if (value.length < 2) {
      return []
    } else {
      return this.allAssets
        .filter(option => option.reference.toLowerCase().indexOf(filterValue) != -1) // === 0 pour exact match par le début
    }

  }

  // after you clicked an autosuggest option, this function will show the field you want to show in input
  displayFn(asset: Asset) {
    return asset ? asset.reference : asset;
  }

  navigateToAssetDetail(event) {
    var asset = event.source.value;
        if (asset) {
          this.router.navigate(['dashboard-assets/asset/' + asset])
        }
        //this.autocompleteInput.nativeElement.value = '';
        this.autocompleteInput.nativeElement.blur();
  }


}
