import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { 
  LoadAssetsSuccess,
  LoadAssetsFailure,
  AddAssetSuccess,
  AddAssetFailure,
  UpdateAssetSuccess,
  UpdateAssetFailure,
  AssetActionTypes,
  AssetActions,
  DeleteAssetFailure,
  DeleteAssetSuccess,
  UpdateAsset,
  LoadAssetFailure
} from './asset.actions';
import { ApiService } from '../../services/api-service.service'
import { Asset } from '../../models/asset.model';

@Injectable()
export class AssetEffects {

  @Effect()
  loadAssets$ = this.actions$.pipe(
    ofType(AssetActionTypes.LoadAssets),
    concatMap(() =>
      this.apiService.getAssets()
      .pipe(
        map((assets: Asset[]) => new LoadAssetsSuccess(assets)),
        catchError(error => of(new LoadAssetsFailure({ error }))))
    )
  );
  
  /*@Effect()
  loadAssetPhoto$ = this.actions$.pipe(
    ofType(AssetActionTypes.LoadAssetPhoto),
    concatMap((identifier) =>
      this.apiService.getAssetPhoto(identifier)
      .pipe(
        map((assets: any) => new LoadAssetPhotoSuccess(assets)),
        catchError(error => of(new LoadAssetPhotoFailure({ error }))))
    )
  );*/

  @Effect()
  loadAsset$ = this.actions$.pipe(
    ofType(AssetActionTypes.LoadAsset),
    map(action => action.payload),
    concatMap(((assetId: string) =>
      this.apiService.getAssetWithMeasurementPointAndData(assetId)
      .pipe(
        map((asset: Asset) => new UpdateAssetSuccess({id: asset.identifier , changes: asset})),
        catchError(error => of(new LoadAssetFailure({ error }))))
    ))
  );

  @Effect()
  addAsset$ = this.actions$.pipe(
    ofType(AssetActionTypes.AddAsset),
    map(action => action.payload),
    concatMap((asset: Asset) =>
      this.apiService.addAsset(asset)
      .pipe(
        map((newAsset: Asset) => new AddAssetSuccess(newAsset)),
        catchError(error => of(new AddAssetFailure({ error }))))
    )
  );

  @Effect()
  updateAsset$ = this.actions$.pipe(
    ofType(AssetActionTypes.UpdateAsset),
    map(action => action.payload),
    concatMap((asset: Asset) =>
      this.apiService.updateAsset(asset)
      .pipe(
        map((asset: Asset) => new UpdateAssetSuccess({id: asset.identifier , changes: asset})),
        catchError(error => of(new UpdateAssetFailure({ error }))))
    )
  );

  @Effect()
  deleteAsset$ = this.actions$.pipe(
    ofType(AssetActionTypes.DeleteAsset),
    map(action => action.payload),
    concatMap((payload: { id: string }) =>
      this.apiService.deleteAsset(payload.id)
      .pipe(
        map((asset: Asset) => new DeleteAssetSuccess({ id: asset.identifier })),
        catchError(error => of(new DeleteAssetFailure({ error }))))
    )
  );
  
  constructor(
    private actions$: Actions<AssetActions>, 
    private apiService: ApiService,
  ) {}

}
