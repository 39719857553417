import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { LoadAlertTypesFailure, LoadAlertTypesSuccess, AlertTypeActionTypes, AlertTypeActions } from './alert-type.actions';
import { ApiService } from '../../services/api-service.service';
import { AlertType } from '../../models/alert-type.model';


@Injectable()
export class AlertTypeEffects {

  @Effect()
  loadAlertTypes$ = this.actions$.pipe(
    ofType(AlertTypeActionTypes.LoadAlertTypes),
    concatMap(() =>
      this.apiService.getAlertTypes()
      .pipe(
        map((entities: AlertType[]) => new LoadAlertTypesSuccess({ entities: entities })),
        catchError(error => of(new LoadAlertTypesFailure({ error }))))
    )
  );


  constructor(private actions$: Actions<AlertTypeActions>, private apiService: ApiService) {}

}
