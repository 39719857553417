import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import * as mainReducers from '../../reducers/index';
import { Device } from 'src/app/models/device.model';
import { Association } from 'src/app/models/association.model';
import * as moment from 'moment';
import { filter, map, tap } from 'rxjs/operators';
import { Asset } from 'src/app/models/asset.model';
import { DeviceType } from 'src/app/models/device-type.model';

@Component({
  selector: 'app-add-device-dialog',
  templateUrl: './add-device-dialog.component.html',
  styleUrls: ['./add-device-dialog.component.scss']
})
export class AddDeviceDialogComponent implements OnInit {

  asset: Asset = null
  errorMsg: string = null
  organizationName: string = ''
  addDeviceForm: FormGroup
  devices$: Observable<Device[]>
  maxDate = moment(new Date()).format('YYYY-MM-DD')

  constructor(
    public dialogRef: MatDialogRef<AddDeviceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private store: Store<mainReducers.State>,
  ) {}

  ngOnInit() {
    if(this.data.entity) {
      if(this.data.entity.asset && this.data.entity.asset.identifier) {
        this.store.select(mainReducers.selectFeatureSingleAssetById, { id: this.data.entity.asset.identifier })
        .subscribe(
          asset => this.asset = asset
        )
      }
    }

    this.addDeviceForm = this.fb.group({
      identifier: ['', Validators.required],
      startDate: ['', Validators.required],
      startTime: [moment(new Date()).format('HH[:]mm[:]ss'), Validators.required]
    });

    // récupère les devices et les filtre pour ne proposer que ceux qui ne sont pas déjà associés
    this.devices$ = this.store.select(mainReducers.selectFeatureDevicesEntitiesAsArray).pipe(
        map((devices: Device[]) => devices
          .filter(device => (device.monitored === false))
          .filter(device => (device.ownerOrganization.identifier === this.asset.organization.identifier))
        ), 
        tap((devices: Device[]) => {
          if(!devices.length) {
            this.errorMsg = 'There is no devices managed by ' + this.organizationName 
          }
        })
    )

    this.store.select(mainReducers.selectFeatureBvuserManagingOrganizations).pipe(
      filter(organizations => organizations != null && organizations.length > 0)
    ).subscribe(
      organizations => organizations
        .filter(org => org.identifier == this.asset.organization.identifier)
        .map(org => this.organizationName = org.name)
    )

  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    let startDate: string = moment(this.addDeviceForm.value.startDate).format('YYYY[-]MM[-]DD')
    let result: Partial<Association> = {
      device: <Device>{ identifier: this.addDeviceForm.value.identifier },
      start: moment(startDate + ' ' + this.addDeviceForm.value.startTime).format('YYYY[-]MM[-]DD HH[:]mm[:]ss')
    }
    if(!moment(this.addDeviceForm.value.startTime, "HH:mm:ss", true).isValid()) {
      this.errorMsg = "Please enter the correct start time with format 'HH:mm:ss'"
    }
    if(!this.errorMsg) {
      this.dialogRef.close(result)
    }
  }

}
