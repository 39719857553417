export interface DeviceType {
  identifier: string;
  description?: string;
  model?: string;
  manufacturer?: string;
}

// @Deprecated
export const DEVICE_TYPES_VARIABLES = [
    {id: 1, type: "Temperature", propertyName: "temperature"},
    {id: 2, type: "Pressure", propertyName: "pressure"},
  ]