
import { AlertTypeActions, AlertTypeActionTypes } from './alert-type.actions';
import { AlertType } from '../../models/alert-type.model';

export interface State {
  entities: AlertType[],
  isLoading: boolean
}

export const initialState: State = {
  entities: [],
  isLoading: false
};

export function reducer(state = initialState, action: AlertTypeActions): State {
  switch (action.type) {

    case AlertTypeActionTypes.LoadAlertTypes:
      return Object.assign({}, state, {
        isLoading: true
      });

    case AlertTypeActionTypes.LoadAlertTypesSuccess:
      const entities: AlertType[] = action.payload.entities;
      return Object.assign({}, state, {
        entities: entities,
        isLoading: false
      });

    case AlertTypeActionTypes.LoadAlertTypesFailure:
      return Object.assign({}, state, {
        isLoading: false
      });

    default:
      return state;
  }
}
