import * as Highcharts from "highcharts";
import * as moment from "moment";
import { Measurement } from "../models/measurement.model";

export const COLOR_P = '#191970'
export const COLOR_T = '#FFBF00'

export class ChartHelper {
    // Commun chart options 
    /**------------------------------------------------------------------------------*/
    static tooltip = {
        // snap: 10,
        shared: true,
        enabled: true,
        xDateFormat: '%Y-%m-%d %H:%M:%S',
    }
    static plotOptions = {
      series: {
        animation: {
            duration: 150
        },
        marker: {
            enabled: false
        },
        // cursor: 'pointer',
        // stickyTracking: true,
        state: {
          hover: {
            enabled: true
          },
        }
      },
    }
    // TODO: Debugger (http://jsfiddle.net/highcharts/F4e2Y/)
    static interpolate(data) {
      var resolution = 0.1,
          interpolatedData = [];
      data.forEach(function(point, i) {
          var x;
          if (i > 0) {
              for (x = data[i - 1].x + resolution; x < point.x; x += resolution) {
                  interpolatedData.push({
                      x: Highcharts.correctFloat(x),
                      y: Highcharts.correctFloat(data[i - 1].y + (point.y - data[i - 1].y) *
                          ((x - data[i - 1].x) / (point.x - data[i - 1].x)))
                  });
              }
          }
          interpolatedData.push(point)
      });
      return interpolatedData;
    }

    /**------------------------------------------------------------------------------*/
    private static convertAndFilterMeasurementsToSeries(measurements: Measurement[], type: string, from?: number): { type: string, series: any } {
      let properties: string[] = ['pressure', 'temperature', 'pressure_sensor_temperature', 'device_type']
      let series1: number[][] = [], series2: number[][] = []
      if(!measurements || !measurements.length) return { type: type, series: [] }
      if(!type && measurements[0].values[properties[3]]) {
        type = measurements[0].values[properties[3]]
      }
      let filteredMeasurements;
      if(from) {
        filteredMeasurements = measurements.filter(measurement => (moment(measurement.timestamp).utc(true).unix()*1000 + moment().utcOffset()*60*1000) > from)
      } else {
        filteredMeasurements = measurements
      }
      filteredMeasurements.forEach(measurement => {
        let datetime = (moment(measurement.timestamp).utc(true).unix()*1000 + moment().utcOffset()*60*1000)
        if(type == '2') {
          let pressure = measurement.values[properties[0]]
          let temperature = measurement.values[properties[2]]
          series1.push([datetime, pressure?parseFloat(pressure):null])
          series2.push([datetime, temperature?parseFloat(temperature):null])
        } else if(type == '1') {
          let temperature = measurement.values[properties[1]]
          series1.push([datetime, temperature?parseFloat(temperature):null])
        }
      })
      return {
        type: type,
        series: [series1, series2]
      }
    }
    
    static temperatureChartOptions(measurements: Measurement[], defaultUnit: boolean, chartHeight: number): any {
        let series = this.convertAndFilterMeasurementsToSeries(measurements, '1').series[0]
        return {
            chart: {
              type: 'spline',
              height: chartHeight,
              zoomType: 'x',
            },
            title: { 
              text: '' 
            },
            credits: { 
              enabled: false 
            },
            tooltip: this.tooltip,
            plotOptions: this.plotOptions,
            xAxis: {
                type: 'datetime',
                crosshair: true,
            },
            yAxis: {
                title: {
                  text: 'Temperature ' + (defaultUnit?'(°C)':'(°F)'),
                },
            },
            series: [
              {
                type: 'spline',
                color: COLOR_T,
                name: 'Temperature',
                data: series,
              }
            ]
        }
    }

    static pressureChartOptions(measurements: Measurement[], defaultPressureUnit: boolean, showTemperature: boolean, defaultTemperatureUnit: boolean, chartHeight: number): any {
      let series1 = this.convertAndFilterMeasurementsToSeries(measurements, '2').series[0]
      let series2 = this.convertAndFilterMeasurementsToSeries(measurements, '2').series[1]
      let yAxisTitle = [{
          title: {
            text: 'Pressure ' + (defaultPressureUnit?'(Bar)':'(Psi)'),
          }
        }, {
          title: {
            text: 'Temperature ' + (defaultTemperatureUnit?'(°C)':'(°F)'),
          },
          opposite: true
      }]
      let series = [{
          name: 'Pressure',
          type: 'spline',
          color: COLOR_P,
          data: series1,
          // enableMouseTracking: false
        }, {
          name: 'Temperature',
          type: 'spline',
          color: COLOR_T,
          zIndex: -1,
          yAxis: 1,
          data: series2
      }]
      if(!showTemperature) {
        yAxisTitle = [{
          title: {
            text: 'Pressure ' + (defaultPressureUnit?'(Bar)':'(Psi)'),
          }
        }]
        series = [{
          name: 'Pressure',
          type: 'spline',
          color: COLOR_P,
          data: series1
        }]
      }
      return {
          chart: {
            height: chartHeight,
            zoomType: 'x',
          },
          title: { 
            text: '' 
          },
          credits: { 
            enabled: false 
          },
          tooltip: this.tooltip,
          plotOptions: this.plotOptions,
          xAxis: {
              type: 'datetime',
              crosshair: true,
          },
          yAxis: yAxisTitle,
          series: series 
      }
    }
    
    static refreshChart(chart: Highcharts.Chart, type: string, measurements: Measurement[], from?: number) {
      for(let i=0; i<chart.series.length; i++)
        chart.series[i].setData([])
      let res: { type: string, series: any } = this.convertAndFilterMeasurementsToSeries(measurements, type, from)
      if(!type && res.type) {
        type = res.type
      }
      if (type == '2') {
        chart.series[0].setData(res.series[0])
        chart.series[1].setData(res.series[1])
      } else if(type == '1') {
        chart.series[0].setData(res.series[0])
      }
    }

}
