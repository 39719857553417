import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Alert } from '../../models/alert.model';

export enum AlertActionTypes {
  LoadAlerts = '[Alert] Load Alerts',
  AddAlert = '[Alert] Add Alert',
  UpsertAlert = '[Alert] Upsert Alert',
  AddAlerts = '[Alert] Add Alerts',
  UpsertAlerts = '[Alert] Upsert Alerts',
  UpdateAlert = '[Alert] Update Alert',
  UpdateAlerts = '[Alert] Update Alerts',
  DeleteAlert = '[Alert] Delete Alert',
  DeleteAlerts = '[Alert] Delete Alerts',
  ClearAlerts = '[Alert] Clear Alerts'
}

export class LoadAlerts implements Action {
  readonly type = AlertActionTypes.LoadAlerts;

  constructor(public payload: { alerts: Alert[] }) {}
}

export class AddAlert implements Action {
  readonly type = AlertActionTypes.AddAlert;

  constructor(public payload: { alert: Alert }) {}
}

export class UpsertAlert implements Action {
  readonly type = AlertActionTypes.UpsertAlert;

  constructor(public payload: { alert: Alert }) {}
}

export class AddAlerts implements Action {
  readonly type = AlertActionTypes.AddAlerts;

  constructor(public payload: { alerts: Alert[] }) {}
}

export class UpsertAlerts implements Action {
  readonly type = AlertActionTypes.UpsertAlerts;

  constructor(public payload: { alerts: Alert[] }) {}
}

export class UpdateAlert implements Action {
  readonly type = AlertActionTypes.UpdateAlert;

  constructor(public payload: { alert: Update<Alert> }) {}
}

export class UpdateAlerts implements Action {
  readonly type = AlertActionTypes.UpdateAlerts;

  constructor(public payload: { alerts: Update<Alert>[] }) {}
}

export class DeleteAlert implements Action {
  readonly type = AlertActionTypes.DeleteAlert;

  constructor(public payload: { id: string }) {}
}

export class DeleteAlerts implements Action {
  readonly type = AlertActionTypes.DeleteAlerts;

  constructor(public payload: { ids: string[] }) {}
}

export class ClearAlerts implements Action {
  readonly type = AlertActionTypes.ClearAlerts;
}

export type AlertActions =
 LoadAlerts
 | AddAlert
 | UpsertAlert
 | AddAlerts
 | UpsertAlerts
 | UpdateAlert
 | UpdateAlerts
 | DeleteAlert
 | DeleteAlerts
 | ClearAlerts;
