import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap, tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { 
  LoginBvuserSuccess,
  LoadTokenFromStorageSuccess,
  LoadTokenFromStorageFailure,
  LoadBvuserSuccess,
  LogoutBvuser,
  UpdateBvuser,
  BvuserActionTypes,
  BvuserActions,
  UpdateUserPreferencesSuccess,
  UpdateUserPreferencesFailure,
  LoadUserPreferencesSuccess,
  LoadUserPreferencesFailure,
  CreateOrganizationFailure,
  UpdateOrganizationFailure,
  LoadBvuser, 
} from './bvuser.actions';
import { ApiService } from '../../services/api-service.service'
import { AuthService } from '../../services/auth-service.service'
import { AccessToken } from '../../services/auth-service.service'
import { Bvuser, UserPreferences } from '../../models/bvuser.model'
import { PRESSURE_UNITS, TEMPERATURE_UNITS } from 'src/app/shared/common';
import { GlobalVariablesService } from 'src/app/shared/global-variables.service';
import { Organization } from 'src/app/models/organization.model';

@Injectable()
export class BvuserEffects {

  @Effect()
  loginBvusers$ = this.actions$.pipe(
    ofType(BvuserActionTypes.LoginBvuser),
    switchMap(() => {
      this.authService.openLogin()
      return null
    })
  );

  @Effect({ dispatch: false })
  loginBvuserSuccess$ = this.actions$.pipe(
    ofType(BvuserActionTypes.LoginBvuserSuccess),
    map((action: LoginBvuserSuccess) => action.payload),
    tap((accessToken: AccessToken) => 
      this.authService.storeAccessToken(accessToken)
    ),
    tap((res) => this.router.navigate(['dashboard-assets']))
  );

  @Effect({ dispatch: false })
  logoutBvuser$ = this.actions$.pipe(
    ofType(BvuserActionTypes.LogoutBvuser),
    tap(() => 
      this.authService.logout()
    )
  );

  @Effect()
  loadTokenFromStorage$ = this.actions$.pipe(
    ofType(BvuserActionTypes.LoadTokenFromStorage),
    concatMap(() =>
      this.authService.checkStoredToken()
      .pipe(
        map((accessToken: AccessToken) => new LoadTokenFromStorageSuccess(accessToken)),
        catchError(error => of(new LoadTokenFromStorageFailure({ error }))))
    )
  );

  @Effect()
  loadBvuser$ = this.actions$.pipe(
    ofType(BvuserActionTypes.LoadBvuser),
    concatMap(() =>
      this.apiService.getUser()
      .pipe(
        map((res) => new LoadBvuserSuccess(res)),
        catchError(error => of(new LogoutBvuser())))
    )
  );

  @Effect()
  loadAdminOrganizations$ = this.actions$.pipe(
    ofType(BvuserActionTypes.LoadBvuserSuccess),
    map(action => action.payload),
    concatMap((bvuser: Bvuser) =>
      this.apiService.getAdminOrganizations(bvuser)
      .pipe(
        map((res) => new UpdateBvuser(res)),
        //catchError(error => of(new LogoutBvuser()))
        )
    )
  );

  @Effect() 
  loadUserPreferences$ = this.actions$.pipe(
    ofType(BvuserActionTypes.LoadUserPreferences),
    concatMap(() => 
      this.apiService.getUserPreferences()
      .pipe(
        map((preferences: UserPreferences) => new LoadUserPreferencesSuccess(preferences)),
        catchError(error => of(new LoadUserPreferencesFailure({ error })))
      )
    )
  )

  @Effect()
  createUserPreferences$ = this.actions$.pipe(
    ofType(BvuserActionTypes.LoadUserPreferencesSuccess),
    map(action => action.payload),
    /*filter(preference => preference.emailOptions == null ||
      preference.pressureUnit == null || preference.temperatureUnit == null),*/
    concatMap((preferences: UserPreferences) => {
      if(preferences.emailOptions == null) {
        preferences = Object.assign({}, preferences, { emailOptions:'alerts,lowBattery' })
      }
      // configure global varialbes: unit
      if(preferences.pressureUnit == null || preferences.temperatureUnit == null) {
        preferences = Object.assign({}, preferences, { pressureUnit: PRESSURE_UNITS[0], temperatureUnit: TEMPERATURE_UNITS[0] })
        this.globalVariables.setPressureUnit(PRESSURE_UNITS[0]);
        this.globalVariables.setTemperatureUnit(TEMPERATURE_UNITS[0]);
      } else {
        this.globalVariables.setPressureUnit(preferences.pressureUnit);
        this.globalVariables.setTemperatureUnit(preferences.temperatureUnit);
      }
      return this.apiService.saveUserPreferences(preferences)
      .pipe(
        map((res) => new UpdateUserPreferencesSuccess(res)),
        catchError(error => of(new UpdateUserPreferencesFailure({ error })))
      )
    })
  )

  @Effect()
  updateUserPreferences$ = this.actions$.pipe(
    ofType(BvuserActionTypes.UpdateUserPreferences),
    map(action => action.payload),
    concatMap((preferences: UserPreferences) => {
      this.globalVariables.setPressureUnit(preferences.pressureUnit);
      this.globalVariables.setTemperatureUnit(preferences.temperatureUnit);
      return this.apiService.saveUserPreferences(preferences)
      .pipe(
        map((res) => new UpdateUserPreferencesSuccess(res)),
        catchError(error => of(new UpdateUserPreferencesFailure({ error })))
      )
    })
  )

  @Effect()
  createOrganization$ = this.actions$.pipe(
    ofType(BvuserActionTypes.CreateOrganization),
    map(action => action.payload),
    concatMap((organization: Organization) => 
      this.apiService.createOrganization(organization)
      .pipe(
        map(() => new LoadBvuser()), // Reload user
        catchError(error => of(new CreateOrganizationFailure({ error })))
      ) 
    )
  )

  @Effect()
  updateOrganization$ = this.actions$.pipe(
    ofType(BvuserActionTypes.UpdateOrganization),
    map(action => action.payload),
    concatMap((organization: Organization) => 
      this.apiService.updateOrganization(organization)
      .pipe(
        map(() => new LoadBvuser()), // Reload user
        catchError(error => of(new UpdateOrganizationFailure({ error })))
      ) 
    )
  )
  
  constructor(
    private actions$: Actions<BvuserActions>, 
    private router: Router,
    private authService: AuthService, 
    private apiService: ApiService,
    private globalVariables: GlobalVariablesService
  ) {}

}
