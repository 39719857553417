import { Action } from '@ngrx/store';
import { AccessToken } from 'src/app/services/auth-service.service';
import { Bvuser, UserPreferences } from 'src/app/models/bvuser.model';
import { Organization } from 'src/app/models/organization.model';

export enum BvuserActionTypes {
  LoadBvuser = '[Bvuser] Load Bvusers',
  LoadBvuserSuccess = '[Bvuser] Load Bvusers Success',
  LoadBvuserFailure = '[Bvuser] Load Bvusers Failure',
  // Login
  LoginBvuser = '[Bvuser] Login Bvuser with Auth Service',
  LoginBvuserSuccess = '[Bvuser] Login Bvuser with Auth Service Success',
  LoginBvuserFailure = '[Bvuser] Login Bvuser with Auth Service Failure',
  // Logout
  LogoutBvuser = '[Bvuser] Logout Bvuser',
  // Load Token From Storage
  LoadTokenFromStorage = '[Bvuser] Loads Token from storage',
  LoadTokenFromStorageSuccess = '[Bvuser] Loads Token from storage Success',
  LoadTokenFromStorageFailure = '[Bvuser] Loads Token from storage Failure',
  // Update Bvuser
  UpdateBvuser = '[Bvuser] Update Bvuser',
  // User preferences
  LoadUserPreferences = '[Bvuser] Load User Preferences',
  LoadUserPreferencesSuccess = '[Bvuser] Load User Prefrerences Success',
  LoadUserPreferencesFailure = '[Bvuser] Load User Prefrerences Failure',
  UpdateUserPreferences = '[Bvuser] Update User Prefrerences',
  UpdateUserPreferencesSuccess = '[Bvuser] Update User Prefrerences Success',
  UpdateUserPreferencesFailure = '[Bvuser] Update User Prefrerences Failure',
  // Organization for SUPER ADMIN
  CreateOrganization = '[Bvuser] SUPER ADMIN Create Organization',
  // CreateOrganizationSuccess = '[Bvuser] SUPER ADMIN Create Organization Success',
  CreateOrganizationFailure = '[Bvuser] SUPER ADMIN Create Organization Failure',
  UpdateOrganization = '[Bvuser] SUPER ADMIN Update Organization', 
  // UpdateOrganizationSuccess = '[Bvuser] SUPER ADMIN Update Organization Success', 
  UpdateOrganizationFailure = '[Bvuser] SUPER ADMIN Update Organization Failure', 
}

export class LoadBvuser implements Action {
  readonly type = BvuserActionTypes.LoadBvuser;
}

export class LoadBvuserSuccess implements Action {
  readonly type = BvuserActionTypes.LoadBvuserSuccess;
  constructor(public payload: Bvuser) { }
}

export class LoadBvuserFailure implements Action {
  readonly type = BvuserActionTypes.LoadBvuserFailure;
  constructor(public payload: { error: any }) { }
}

// Login
export class LoginBvuser implements Action {
  readonly type = BvuserActionTypes.LoginBvuser;
  // constructor(public payload: { username: string, password: string }) { }
}

export class LoginBvuserSuccess implements Action {
  readonly type = BvuserActionTypes.LoginBvuserSuccess;
  constructor(public payload: AccessToken) {}
}

export class LoginBvuserFailure implements Action {
  readonly type = BvuserActionTypes.LoginBvuserFailure;
  constructor(public payload: { error: any }) { }
}

// Logout
export class LogoutBvuser implements Action {
  readonly type = BvuserActionTypes.LogoutBvuser;
}

// Load Token From Storage
export class LoadTokenFromStorage implements Action {
  readonly type = BvuserActionTypes.LoadTokenFromStorage;
}

export class LoadTokenFromStorageSuccess implements Action {
  readonly type = BvuserActionTypes.LoadTokenFromStorageSuccess;
  constructor(public payload: AccessToken) { }
}

export class LoadTokenFromStorageFailure implements Action {
  readonly type = BvuserActionTypes.LoadTokenFromStorageFailure;
  constructor(public payload: { error: any }) {}
}

// Update Bvuser
export class UpdateBvuser implements Action {
  readonly type = BvuserActionTypes.UpdateBvuser;
  constructor(public payload: Bvuser) { }
}

// User Preferences 
export class LoadUserPreferences implements Action {
  readonly type = BvuserActionTypes.LoadUserPreferences;
}

export class LoadUserPreferencesSuccess implements Action {
  readonly type = BvuserActionTypes.LoadUserPreferencesSuccess;
  constructor(public payload: UserPreferences) {}
}

export class LoadUserPreferencesFailure implements Action {
  readonly type = BvuserActionTypes.LoadUserPreferencesFailure;
  constructor(public payload: { error: any }) {}
}

export class UpdateUserPreferences implements Action {
  readonly type = BvuserActionTypes.UpdateUserPreferences;
  constructor(public payload: UserPreferences) {}
}

export class UpdateUserPreferencesSuccess implements Action {
  readonly type = BvuserActionTypes.UpdateUserPreferencesSuccess;
  constructor(public payload: UserPreferences) {}
}

export class UpdateUserPreferencesFailure implements Action {
  readonly type = BvuserActionTypes.UpdateUserPreferencesFailure;
  constructor(public payload: { error: any }) {}
}

export class CreateOrganization implements Action {
  readonly type = BvuserActionTypes.CreateOrganization;
  constructor(public payload: Organization) {}
}

export class CreateOrganizationFailure implements Action {
  readonly type = BvuserActionTypes.CreateOrganizationFailure;
  constructor(public payload: { error: any }) {}
}

export class UpdateOrganization implements Action {
  readonly type = BvuserActionTypes.UpdateOrganization;
  constructor(public payload: Organization) {}
}

export class UpdateOrganizationFailure implements Action {
  readonly type = BvuserActionTypes.UpdateOrganizationFailure;
  constructor(public payload: { error: any }) {}
}

export type BvuserActions = 
  LoadBvuser
  | LoadBvuserSuccess 
  | LoadBvuserFailure
  | LoginBvuser
  | LoginBvuserSuccess
  | LoginBvuserFailure
  | LogoutBvuser
  | LoadTokenFromStorage
  | LoadTokenFromStorageSuccess
  | LoadTokenFromStorageFailure
  | UpdateBvuser
  | LoadUserPreferences
  | LoadUserPreferencesSuccess
  | LoadUserPreferencesFailure
  | UpdateUserPreferences
  | UpdateUserPreferencesSuccess
  | UpdateUserPreferencesFailure
  | CreateOrganization
  | CreateOrganizationFailure
  | UpdateOrganization
  | UpdateOrganizationFailure
  ;

