import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as mainReducers from '../../reducers/index';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { ChartHelper } from 'src/app/shared/chart-helper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Measurement } from 'src/app/models/measurement.model';
import { Store } from '@ngrx/store';
import { ApiService } from 'src/app/services/api-service.service';
import { filter, first, switchMap } from 'rxjs/operators';
import { MeasurementPoint } from 'src/app/models/measurement-point.model';
import { GlobalVariablesService } from 'src/app/shared/global-variables.service';
import { PRESSURE_UNITS, TEMPERATURE_UNITS, Utils } from 'src/app/shared/common';

@Component({
  selector: 'app-measurements-chart-window',
  templateUrl: './measurements-chart-window.component.html',
  styleUrls: ['./measurements-chart-window.component.scss']
})
export class MeasurementsChartWindowComponent implements OnInit {

  title: string
  chart: any
  Highcharts: typeof Highcharts = Highcharts
  chartOptions: any
  chartCallback: any
  dateRangForm: FormGroup
  minDate: string
  maxDate: string

  constructor(
    private store: Store<mainReducers.State>,
    private apiService: ApiService,
    private globalVariables: GlobalVariablesService,
    public dialogRef: MatDialogRef<MeasurementsChartWindowComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { measurementPoint: MeasurementPoint, deviceType: string, measurements: Measurement[], assetId: string }, 
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    // header
    if(this.data.measurementPoint && this.data.measurementPoint.name) {
      this.title = "Measurement point " + this.data.measurementPoint.name;
    } else {
      this.title = "Measurement point chart";
    }
    let from: string = this.data.measurements&&this.data.measurements.length&&this.data.measurements[0].timestamp?
                    moment(this.data.measurements[0].timestamp).format('YYYY[-]MM[-]DD'):''
    let to: string = this.data.measurements&&this.data.measurements.length&&this.data.measurements[this.data.measurements.length-1].timestamp?
                    moment(this.data.measurements[this.data.measurements.length-1].timestamp).format('YYYY[-]MM[-]DD'):''
    this.dateRangForm = this.fb.group({
      start: [from, Validators.required],
      end:[to, Validators.required]
    })
    // chart
    this.chartCallback = chart => { this.chart = chart }
     // set pressure chart option by default
    if (!this.data.deviceType || this.data.deviceType == '2') {
      this.chartOptions = ChartHelper.pressureChartOptions(this.data.measurements, 
        this.globalVariables.getPressureUnit()==PRESSURE_UNITS[0], this.data.measurementPoint.type && !this.data.measurementPoint.type.includes('DS'), this.globalVariables.getTemperatureUnit()==TEMPERATURE_UNITS[0], window.innerHeight*2/3);
    } else if (this.data.deviceType == '1') {
      this.chartOptions = ChartHelper.temperatureChartOptions(this.data.measurements, this.globalVariables.getTemperatureUnit()==TEMPERATURE_UNITS[0], window.innerHeight*2/3);
    }
  }

  resetInterval(start: Measurement, end: Measurement) {
    let from: string = start && start.timestamp?moment(start.timestamp).format('YYYY[-]MM[-]DD'):''
    let to: string = end && end.timestamp?moment(end.timestamp).format('YYYY[-]MM[-]DD'):''
    this.dateRangForm.get('start').setValue(from)
    this.dateRangForm.get('end').setValue(to)
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onReloadChart(): void {
    this.chart.showLoading() 
    let from = moment(this.dateRangForm.value.start).format('YYYY[-]MM[-]DD HH[:]mm[:]ss')
    let to = moment(this.dateRangForm.value.end).format('YYYY[-]MM[-]DD HH[:]mm[:]ss')
    this.apiService.getMeasurements(this.data.assetId, this.data.measurementPoint, from, to)
      .subscribe((measurements: Measurement[]) => {
        this.chart.hideLoading()
        ChartHelper.refreshChart(this.chart, this.data.deviceType, measurements)
      })
  }

  changeTimeInterval(timeInterval: number) {
    if(timeInterval > 7) {
      this.chart.showLoading() 
      let from: string = timeInterval == 365 ? moment().subtract(1, 'year').format('YYYY[-]MM[-]DD HH[:]mm[:]ss') : 
                                               moment().subtract(1, 'month').format('YYYY[-]MM[-]DD HH[:]mm[:]ss')
      if(this.data.measurementPoint.lastMeasurement && this.data.measurementPoint.lastMeasurement._last_update_date) { 
        const LAST = moment(this.data.measurementPoint.lastMeasurement._last_update_date, 'YYYY-MM-DD HH:mm:ss') 
        from = timeInterval == 365 ? LAST.subtract(1, 'year').format('YYYY[-]MM[-]DD HH[:]mm[:]ss') : 
                                     LAST.subtract(1, 'month').format('YYYY[-]MM[-]DD HH[:]mm[:]ss')
      }
      this.apiService.getMeasurements(this.data.assetId, this.data.measurementPoint, from)
      .subscribe((measurements: Measurement[]) => {
        this.chart.hideLoading()
        ChartHelper.refreshChart(this.chart, this.data.deviceType, measurements)
        if(measurements && measurements.length > 0) {
          this.resetInterval(measurements[0], measurements[measurements.length-1])
        }
      })
    } else { 
      let from = moment().utc(true).subtract(timeInterval, 'days').unix()*1000 + moment().utcOffset()*60*1000
      if(this.data.measurementPoint.lastMeasurement && this.data.measurementPoint.lastMeasurement._last_update_date) { 
        const LAST = moment(this.data.measurementPoint.lastMeasurement._last_update_date, 'YYYY-MM-DD HH:mm:ss')
        from = LAST.utc(true).subtract(timeInterval, 'days').unix()*1000 + moment().utcOffset()*60*1000
      }
      ChartHelper.refreshChart(this.chart, this.data.deviceType, this.data.measurements, from)
      if(this.data.measurements && this.data.measurements.length > 0) {
        if(timeInterval == 1) 
          this.resetInterval(null, null)
        else 
          this.resetInterval(this.data.measurements[0], this.data.measurements[this.data.measurements.length-1])
      }
    }
  }

  exportMeasurements() {
    let from = moment(this.dateRangForm.value.start).format('YYYY[-]MM[-]DD HH[:]mm[:]ss')
    let to = moment(this.dateRangForm.value.end).format('YYYY[-]MM[-]DD') + ' 23:59:59'
    this.store.select(mainReducers.selectFeatureAssetsSelectedAssetId)
      .pipe(
        first(),
        filter(assetId => assetId != null),
        switchMap(
          assetId => this.apiService.getMeasurementsAsCsv(assetId, this.data.measurementPoint, from, to)
        ),
        filter(measurements => measurements.length > 0),
        first()
      ).subscribe(
        data => Utils.downloadCsvFile(data, 'data_mp_' + this.data.measurementPoint.name + "_" + from.slice(0, 10) + "_" + to.slice(0, 10) + ".csv",
            this.globalVariables.getPressureUnit() != PRESSURE_UNITS[0], this.globalVariables.getTemperatureUnit() != TEMPERATURE_UNITS[0]),
        error => console.log('Error downloading the file.')
      )
  }

}
