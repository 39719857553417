import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import * as mainReducers from '../../reducers/index';
import * as adminActions from '../../store/administrator/administrator.actions';
import { AccountFilterBarComponent } from 'src/app/components/account-filter-bar/account-filter-bar.component';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { Administrator } from 'src/app/models/bvuser.model';
import { MatDialog, MatSnackBar } from '@angular/material';
import { UserDialogComponent } from 'src/app/components/user-dialog/user-dialog.component';
import { AdminState } from 'src/app/store/administrator/administrator.reducer';

@Component({
  selector: 'app-administrators',
  templateUrl: './administrators.component.html',
  styleUrls: ['./administrators.component.scss']
})
export class AdministratorsComponent implements OnInit {
  @ViewChild(AccountFilterBarComponent) accountFilterBarComponent: AccountFilterBarComponent
  public administrators$: Observable<Administrator[]>;

  constructor(
    private store: Store<mainReducers.State>,
    private snackBar: MatSnackBar,
    private dialog: MatDialog, 
  ) {}

  ngOnInit() {
    this.store.dispatch(new adminActions.LoadAdministrators());
    this.store.select(mainReducers.selectFeatureAdminStateAndError)
      .subscribe((res: {state: AdminState, error: any }) => {
        if(res.state == AdminState.ADD_ADMIN_OK) {
          this.snackBar.open('A new administrator created successfully.')
        } else if(res.state == AdminState.ADD_ADMIN_KO) {
          this.snackBar.open('The new administrator created fallure. ' + res.error)
        }
      })
  }

  newAdministrator() {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      width: '450px',
      data: { action: 'new', userType: 'admin' }
    });

    dialogRef.afterClosed()
    .pipe(
      filter(result => result != null)
    )
    .subscribe((result: Administrator) => {
      this.store.dispatch(new adminActions.AddAdministrator(result));
    });
  }

}


