import { Component, OnInit, Input } from '@angular/core';
import { Measurement } from 'src/app/models/measurement.model';
import * as moment from 'moment';
import * as Highcharts from 'highcharts';
import { MatDialog } from '@angular/material';
import { MeasurementsChartWindowComponent } from '../measurements-chart-window/measurements-chart-window.component';
import { ChartHelper } from 'src/app/shared/chart-helper';
import { MeasurementPoint } from 'src/app/models/measurement-point.model';
import { GlobalVariablesService } from 'src/app/shared/global-variables.service';
import { PRESSURE_UNITS, TEMPERATURE_UNITS } from 'src/app/shared/common';
import { ApiService } from 'src/app/services/api-service.service';

@Component({
  selector: 'measurements-chart',
  templateUrl: './measurements-chart.component.html',
  styleUrls: ['./measurements-chart.component.scss']
})
export class MeasurementsChartComponent implements OnInit {
  @Input() measurementPoint: MeasurementPoint
  @Input() measurements: Measurement[]
  @Input() deviceType: string
  @Input() assetId: string

  Highcharts: typeof Highcharts = Highcharts
  chart: any
  chartOptions: any
  chartCallback: any
  timeInterval: string = 'week'
  
  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private globalVariables: GlobalVariablesService,
  ) {}

  ngOnInit() {
    if(this.measurements != undefined) {
      this.chartCallback = chart => this.chart = chart
      // set pressure chart option by default
      if (!this.deviceType || this.deviceType == '2') {
        this.chartOptions = ChartHelper.pressureChartOptions(this.measurements, 
          this.globalVariables.getPressureUnit()==PRESSURE_UNITS[0], this.measurementPoint.type && !this.measurementPoint.type.includes('DS'), this.globalVariables.getTemperatureUnit()==TEMPERATURE_UNITS[0], 310);
      } else if (this.deviceType == '1') {
        this.chartOptions = ChartHelper.temperatureChartOptions(this.measurements, this.globalVariables.getTemperatureUnit()==TEMPERATURE_UNITS[0], 310);
      }
    }
  }

  changeTimeInterval(timeInterval: number) {
    if(timeInterval > 7) {
      this.chart.showLoading() 
      let from: string = timeInterval == 365 ? moment().subtract(1, 'year').format('YYYY[-]MM[-]DD HH[:]mm[:]ss') : 
                                               moment().subtract(1, 'month').format('YYYY[-]MM[-]DD HH[:]mm[:]ss')
      if(this.measurementPoint.lastMeasurement && this.measurementPoint.lastMeasurement._last_update_date) { 
        const LAST = moment(this.measurementPoint.lastMeasurement._last_update_date, 'YYYY-MM-DD HH:mm:ss') 
        from = timeInterval == 365 ? LAST.subtract(1, 'year').format('YYYY[-]MM[-]DD HH[:]mm[:]ss') : 
                                     LAST.subtract(1, 'month').format('YYYY[-]MM[-]DD HH[:]mm[:]ss')
      }
      this.apiService.getMeasurements(this.assetId, this.measurementPoint, from)
      .subscribe((measurements: Measurement[]) => {
        this.chart.hideLoading()
        ChartHelper.refreshChart(this.chart, this.deviceType, measurements)
      })
    } else { 
      let from = moment().utc(true).subtract(timeInterval, 'days').unix()*1000 + moment().utcOffset()*60*1000
      if(this.measurementPoint.lastMeasurement && this.measurementPoint.lastMeasurement._last_update_date) { 
        const LAST = moment(this.measurementPoint.lastMeasurement._last_update_date, 'YYYY-MM-DD HH:mm:ss')
        from = LAST.utc(true).subtract(timeInterval, 'days').unix()*1000 + moment().utcOffset()*60*1000
      }
      console.info('Refresh chart from [' + timeInterval + ' days ago] to [' + this.measurementPoint.lastMeasurement._last_update_date + ']')
      ChartHelper.refreshChart(this.chart, this.deviceType, this.measurements, from)
    }
  }

  openChart(): void {
    this.dialog.open(MeasurementsChartWindowComponent, {
      width: window.innerWidth + 'px', // '1300px',
      height: window.innerHeight + 'px', // '720px',
      data: { 
        measurementPoint: this.measurementPoint,
        deviceType: this.deviceType, 
        measurements: this.measurements,
        assetId: this.assetId
      }
    })
  }

}
