import { Action } from '@ngrx/store';
import { AlertType } from '../../models/alert-type.model';

export enum AlertTypeActionTypes {
  LoadAlertTypes = '[AlertType] Load AlertTypes',
  LoadAlertTypesSuccess = '[AlertType] Load AlertTypes Success',
  LoadAlertTypesFailure = '[AlertType] Load AlertTypes Failure',
}

export class LoadAlertTypes implements Action {
  readonly type = AlertTypeActionTypes.LoadAlertTypes;
}

export class LoadAlertTypesSuccess implements Action {
  readonly type = AlertTypeActionTypes.LoadAlertTypesSuccess;
  constructor(public payload: { entities: AlertType[] }) { }
}

export class LoadAlertTypesFailure implements Action {
  readonly type = AlertTypeActionTypes.LoadAlertTypesFailure;
  constructor(public payload: { error: any }) { }
}

export type AlertTypeActions = LoadAlertTypes | LoadAlertTypesSuccess | LoadAlertTypesFailure;

