import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { PRESSURE_UNITS, TEMPERATURE_UNITS } from './common';


export const CLIENT_ID = 'bumblebee-vision';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {
  private production: boolean;
  private apiUrl: string;
  private authUrl: string;
  private redirectUri: string;
  private pressureUnit: string;
  private temperatureUnit: string;

  constructor() {
    this.production = environment.production;
    this.apiUrl = environment.apiUrl;
    this.authUrl = environment.authUrl;
    this.redirectUri = environment.redirectUri;
    this.pressureUnit = PRESSURE_UNITS[0];
    this.temperatureUnit = TEMPERATURE_UNITS[0];
  }

  isProduction() {
    return this.production
  }

  getApiUrl() {
    return this.apiUrl;
  }

  // sirius-authorization-server login page uri
  getAuthUrl() {
    return this.authUrl + '/login?' + 
           'response_type=token&lang=en' +
           '&client_id=' + CLIENT_ID + 
           '&state=bumblebee-vision' + 
           '&redirect_uri=' + this.redirectUri;
  }

  getRedirectUri() {
    return this.redirectUri;
  }

  setPressureUnit(unit: string) {
    this.pressureUnit = unit;
  }

  getPressureUnit() {
    return this.pressureUnit;
  }

  setTemperatureUnit(unit: string) {
    this.temperatureUnit = unit;
  }

  getTemperatureUnit() {
    return this.temperatureUnit;
  }
  
}
