import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MeasurementPoint } from 'src/app/models/measurement-point.model';
import * as mainReducers from '../../reducers/index';

@Component({
  selector: 'app-attach-device-dialog',
  templateUrl: './attach-device-dialog.component.html',
  styleUrls: ['./attach-device-dialog.component.scss']
})
export class AttachDeviceDialogComponent implements OnInit {

  attachForm: FormGroup
  errorMsg: string 
  attachedMeasurementPointIds: string[] = []
  measurementPoints$: Observable<MeasurementPoint[]>
  
  constructor(
    private dialogRef: MatDialogRef<AttachDeviceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public store: Store<mainReducers.State>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    if(this.data && this.data.entity) {
      this.attachedMeasurementPointIds = this.data.entity.attachs?this.data.entity.attachs:[]
    }
    this.measurementPoints$ = this.store.select(mainReducers.selectFeatureMeasurementPointsEntitiesAsArray).pipe(
      map(measurementPoints => measurementPoints.filter(mp => mp.monitored && mp.type != 'Valve' && (!mp.attachs || mp.attachs.length==0) && !this.attachedMeasurementPointIds.toString().includes(mp.identifier)))
    )
    this.attachForm = this.fb.group({
      measurementPoint: ['', Validators.required]
    })
  }

  onCancel() {
    this.dialogRef.close();
  }

  onConfirm() {
    this.errorMsg = ''
    let targetMp: MeasurementPoint = null 
    if(!this.attachForm.value.measurementPoint) {
      this.errorMsg = 'Please select a measurement point to attach.'
    } else {
      this.store.select(mainReducers.selectFeatureSingleMeasurementPointsById, { id: this.attachForm.value.measurementPoint }).subscribe(mp => {
        targetMp = mp 
      })
    }
    if(!this.errorMsg) {
      // todo semothing 
      let res = {
        origin: this.data.entity,
        target: targetMp
      }
      console.info('To attch: ', res)
      this.dialogRef.close(res)
    }
  }

}
