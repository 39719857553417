import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { Administrator, AssetsOwner, USER_STATUS_VARIABLES } from 'src/app/models/bvuser.model';
import { Organization } from 'src/app/models/organization.model';
import { BvuserMapHelper } from 'src/app/shared/bvuser-map-helper';
import * as mainReducers from '../../reducers/index';

@Component({
  selector: 'account-filter-bar',
  templateUrl: './account-filter-bar.component.html',
  styleUrls: ['./account-filter-bar.component.scss']
})
export class AccountFilterBarComponent implements OnInit {
  @Input() position: string;

  accountFilterForm: FormGroup;
  accountStatus: any = USER_STATUS_VARIABLES
  organizations$: Observable<Organization[]>
  filteredOptions$: Observable<Administrator[] | AssetsOwner[]> = null;
  
  constructor(
    private store: Store<mainReducers.State>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.accountFilterForm = this.fb.group({
      name: '',
      organization: '', 
      status: 1
    })

    this.accountFilterForm.get('status').setValue('1')

    this.organizations$ = this.store.select(mainReducers.selectFeatureBvuserManagingOrganizations);
    this.filteredOptions$ = this.position === 'technicians' ? 
      combineLatest(
        this.organizations$,
        this.store.select(mainReducers.selectFeatureAssetOwnerEntitiesAsArray)
      ).pipe(
        map(([organizations, assetOwners]) => {
          return assetOwners
              .filter(assetOwner => BvuserMapHelper.checkIfManagingOrganizationsMatched(organizations, assetOwner.managingOrganizations))
              .map(assetOwner => BvuserMapHelper.updateAssetOwners(organizations, assetOwner))
        }),
        filter(assetsOwners => assetsOwners.length > 0),
        switchMap(assetsOwners => 
            this.accountFilterForm.valueChanges.pipe(
              startWith({name: '', organization: '', status: 1}), 
              map(values => this._filter(assetsOwners, values))
            )
        )
      )
      : this.position === 'administrators' ? 
      combineLatest(
        this.organizations$,
        this.store.select(mainReducers.selectFeatureAdminEntitiesAsArray)
      ).pipe(
        map(([organizations, administrators]) => {
          return administrators
              .filter(admin => BvuserMapHelper.checkIfManagingOrganizationsMatched(organizations, admin.managingOrganizations))
              .map(admin => BvuserMapHelper.updateAdministrators(organizations, admin))
        }),
        filter(admins => admins.length > 0),
        switchMap(admins => 
            this.accountFilterForm.valueChanges.pipe(
              startWith({name: '', organization: '', status: 1}), 
              map(values => this._filter(admins, values))
            )
        )
      ) : null;
  }

  private _filter(accounts: AssetsOwner[] | Administrator[], values: any): AssetsOwner[] | Administrator[] {
    const searchname = values.name? values.name.toLowerCase() : '';
    let filteredAccounts: AssetsOwner[] | Administrator[] = accounts

    if ((values.organization != '') && (values.organization != 'all')) {
      filteredAccounts = filteredAccounts
        .filter(account => {
          const IDs: string[] = account.managingOrganizations.map(org => org.identifier)
          return IDs.includes(values.organization)
        })
    }

    if(values.status != '' && values.status !='all') {
      if(values.status == 1) {
        filteredAccounts = filteredAccounts.filter(account => account.active)
      } else {
        filteredAccounts = filteredAccounts.filter(account => !account.active)
      }
    }

    if (values.name !== '') {
      if (values.name.length > 1) {
        return filteredAccounts.filter(account => 
          (account.lastName && account.lastName.toLowerCase().indexOf(searchname) != -1) || 
          (account.firstName && account.firstName.toLowerCase().indexOf(searchname) != -1)) 
      } else {
        return filteredAccounts
      }
    } else {
      return filteredAccounts
    }

  }


}
