import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, concatMap } from 'rxjs/operators';
import { EMPTY, of } from 'rxjs';
import { LoadAssetTypesFailure, LoadAssetTypesSuccess, AssetTypeActionTypes, AssetTypeActions } from './asset-type.actions';
import { ApiService } from '../../services/api-service.service';


@Injectable()
export class AssetTypeEffects {

  @Effect()
  loadAssetTypes$ = this.actions$.pipe(
    ofType(AssetTypeActionTypes.LoadAssetTypes),
    concatMap(() =>
      this.apiService.getAssetTypes()
      .pipe(
        map(assetTypes => new LoadAssetTypesSuccess( assetTypes )),
        catchError(error => of(new LoadAssetTypesFailure({ error }))))
    )
  );


  constructor(private actions$: Actions<AssetTypeActions>, private apiService: ApiService) {}

}
