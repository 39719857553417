import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { filter, tap, switchMap } from 'rxjs/operators';

import { MatDialog } from '@angular/material';
import { AlertAcknowledgeDialogComponent } from '../alert-acknowledge-dialog/alert-acknowledge-dialog.component';
import { ApiService } from '../../services/api-service.service';

import { Alert } from '../../models/alert.model';
import * as moment from 'moment';
import { AlertTemplate } from 'src/app/models/alert-template.model';

@Component({
  selector: 'alert-indicator',
  templateUrl: './alert-indicator.component.html',
  styleUrls: ['./alert-indicator.component.scss']
})
export class AlertIndicatorComponent implements OnInit {

  @Input() type: string
  @Input() alerts: Alert[];
  @Input() alertTemplates: AlertTemplate[];
  @Input() alertManagementAllowed: boolean;

  @Output() alertUpdated = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.alerts.map((alert: Alert) => {
      if(alert.suspendedUntilDate && 
        (moment(alert.suspendedUntilDate).utc(true).unix() - moment().utc(true).unix() > 0)) {
        alert.isSuspended = true
      } else {
        alert.isSuspended = false
      }
    })
  }

  manageAlert(alert: Alert) {
    if (this.alertManagementAllowed) {
      const dialog = this.dialog.open(AlertAcknowledgeDialogComponent, {
        width: '500px',
        data: { type: this.type, alert: alert, alertTemplate: this.alertTemplates.filter((alertTemplate: AlertTemplate) => alert.alertTemplate.identifier == alertTemplate.identifier)[0] }
      });
      
      dialog.afterClosed()
      .pipe(
        filter((result: {suspendCheck: boolean, timeToSuspendInDays: string })=> result != null),
        switchMap(parameters => this.apiService.acknowledgeAlert(alert.identifier, alert.measurementPoint.identifier, parameters))
        )
        .subscribe(
          alert => {
            this.alertUpdated.emit();
          },
          err => console.log(err)
          
        );
      }

  }

}
