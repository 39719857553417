import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Administrator } from "src/app/models/bvuser.model";
import { AdministratorActions, AdministratorActionTypes } from "./administrator.actions";

export enum AdminState {
    NOT_STARTED, 
    LOADING_ADMINS,
    LOAD_ADMINS_OK,
    LOAD_ADMINS_KO, 
    ADDING_ADMIN,
    ADD_ADMIN_OK,
    ADD_ADMIN_KO,
    UPDATING_ADMIN, 
    UPDATE_ADMIN_OK, 
    UPDATE_ADMIN_KO, 
    SUSPENDING_ADMIN, 
    SUSPEND_ADMIN_OK,
    SUSPEND_ADMIN_KO,
    ACTIVATING_ADMIN, 
    ACTIVATE_ADMIN_OK,
    ACTIVATE_ADMIN_KO, 
    SWITCH_TO_TECH,
    SWITCH_TO_TECH_OK,
    SWITCH_TO_TECH_KO
}

export interface State extends EntityState<Administrator> {
    isLoading: AdminState;
    errorMsg: any
}

export const adapter: EntityAdapter<Administrator> = createEntityAdapter<Administrator>({
    selectId: (admin: Administrator) => admin.identifier,
    sortComparer: (a: Administrator, b: Administrator) => a.firstName.localeCompare(b.firstName)
})

export const initialState: State = adapter.getInitialState({
    isLoading: AdminState.NOT_STARTED,
    errorMsg: null
})

export function reducer(
    state = initialState, 
    action: AdministratorActions
): State {
    switch(action.type) {
        // Search administrators
        case AdministratorActionTypes.LoadAdministrators:
            return Object.assign({}, state, { 
                isLoading: AdminState.LOADING_ADMINS, 
            });
        case AdministratorActionTypes.LoadAdministratorsSuccess:
            return Object.assign({}, adapter.addAll(action.payload, state), { 
                isLoading: AdminState.LOAD_ADMINS_OK, 
            });
        case AdministratorActionTypes.LoadAdministratorsFailure:
            return Object.assign({}, state, { 
                isLoading: AdminState.LOAD_ADMINS_KO,
                errorMsg: action.payload.error
            });
        // Add a new administrator
        case AdministratorActionTypes.AddAdministrator:
            return Object.assign({}, state, {
                isLoading: AdminState.ADDING_ADMIN,
            });
        case AdministratorActionTypes.AddAdministratorSuccess:
            return Object.assign({}, adapter.addOne(action.payload, state), {
                isLoading: AdminState.ADD_ADMIN_OK,
            });
        case AdministratorActionTypes.AddAdministratorFailure:
            return Object.assign({}, state, {
                isLoading: AdminState.ADD_ADMIN_KO,
                errorMsg: action.payload.error
            });
        // Update an administrator
        case AdministratorActionTypes.UpdateAdministrator: 
            return Object.assign({}, state, {
                isLoading: AdminState.UPDATING_ADMIN,
            });
        case AdministratorActionTypes.UpdateAdministratorSuccess:
            return Object.assign({}, adapter.updateOne(action.payload, state), {
                isLoading: AdminState.UPDATE_ADMIN_OK,
            });
        case AdministratorActionTypes.UpdateAdministratorFailure:
            return Object.assign({}, state, {
                isLoading: AdminState.UPDATE_ADMIN_KO,
                errorMsg: action.payload.error
            });
        // Suspend an administrator
        case AdministratorActionTypes.SuspendAdministrator: 
            return Object.assign({}, state, {
                isLoading: AdminState.SUSPENDING_ADMIN,
            });
        case AdministratorActionTypes.SuspendAdministratorSuccess:
            return Object.assign({}, adapter.updateOne(action.payload, state), {
                isLoading: AdminState.SUSPEND_ADMIN_OK,
            });
        case AdministratorActionTypes.SuspendAdministratorFailure:
            return Object.assign({}, state, {
                isLoading: AdminState.SUSPEND_ADMIN_KO,
                errorMsg: action.payload.error
            });
        // Activate an administrator    
        case AdministratorActionTypes.ActivateAdministrator: 
            return Object.assign({}, state, {
                isLoading: AdminState.ACTIVATING_ADMIN,
            });
        case AdministratorActionTypes.ActivateAdministratorSuccess:
            return Object.assign({}, adapter.updateOne(action.payload, state), {
                isLoading: AdminState.ACTIVATE_ADMIN_OK,
            });
        case AdministratorActionTypes.ActivateAdministratorFailure:
            return Object.assign({}, state, {
                isLoading: AdminState.ACTIVATE_ADMIN_KO,
                errorMsg: action.payload.error
            }); 
        // Change administrator as technician
        case AdministratorActionTypes.ChangeRoleAsTechnician:
            return Object.assign({}, state, {
                isLoading: AdminState.SWITCH_TO_TECH,
            });
        case AdministratorActionTypes.ChangeRoleAsTechnicianSuccess:
            return Object.assign({}, adapter.removeOne(action.payload.identifier, state), {
                isLoading: AdminState.SWITCH_TO_TECH_OK,
            });
        case AdministratorActionTypes.ChangeRoleAsTechnicianFailure:
            return Object.assign({}, state, {
                isLoading: AdminState.SWITCH_TO_TECH_KO,
                errorMsg: action.payload.error
            });
        default:
            return state;
    }
}