import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router'; // à voir si on met en place les guards
import * as mainReducers from './reducers/index';
import { Bvuser, UserType } from './models/bvuser.model';
import * as bvuserActions from './store/bvuser/bvuser.actions';
import * as deviceTypeActions from './store/device-type/device-type.actions';
import * as adminActions from './store/administrator/administrator.actions';
import * as assetsOwnerActions from './store/assets-owner/assets-owner.actions';
import * as assetTypeActions  from './store/asset-type/asset-type.actions';
import * as assetActions from './store/asset/asset.actions';
import * as devicesActions from './store/device/device.actions';
import * as alertTypeActions from './store/alert-type/alert-type.actions';
import TokenUtil from './services/auth-service.service';
import { ApiService } from './services/api-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Device } from './models/device.model';
import { DeviceState } from './store/device/device.reducer';
import { AssetState } from './store/asset/asset.reducer';
import { Dictionary } from '@ngrx/entity';
import { Asset } from './models/asset.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  @ViewChild('mainContentContainer') mainContainer: ElementRef;

  navLinks = [
    { label: "Dashboard", path: "dashboard-assets", icon: "location_city" },
    { label: "Devices", path: "device-maintenance", icon: "wifi_tethering" },
  ];
  navLinksAssetOwner = [
    { label: "Technicians", path: "technicians", icon: "account_circle" },
    { label: "Settings", path: "settings", icon: "settings" },
  ];
  navLinksAdmin = [
    { label: "Organizations", path: "organizations", icon: "public" },
    { label: "Administrators", path: "administrators", icon: "security" },
    { label: "Technicians", path: "technicians", icon: "account_circle" },
    // { label: "Supervisors", path: "supervisors", icon: "visibility" }, // add in the futur
    { label: "Settings", path: "settings", icon: "settings" }, 
  ];
  
  public $isAuthenticated: Observable<boolean>;
  public $bvuser: Observable<Bvuser>;
  public $bvuserType: Observable<UserType>;
  public navLinksBottom = [];
  public isPhotoLoading: boolean = false
  public organizationPhoto: any;
  public devices: Device[]

  constructor(
    private router: Router,
    private store: Store<mainReducers.State>,
    private apiService: ApiService,
    private sanitizer: DomSanitizer, 
  ) {}

  ngOnInit() {
    // Auth checks
    this.store.dispatch(new bvuserActions.LoadTokenFromStorage())
    if (location.hash && location.hash.includes('access_token')) {
      this.store.dispatch(new bvuserActions.LoginBvuserSuccess(TokenUtil.parseToken(location.hash)));
    } 
    
    this.$isAuthenticated = this.store.select(mainReducers.selectFeatureBvuserIsAuthenticated)
    this.$isAuthenticated.subscribe(
      res => {
        if (res === true) {
          this.store.dispatch(new bvuserActions.LoadBvuser());
          this.store.dispatch(new bvuserActions.LoadUserPreferences());
          this.store.dispatch(new adminActions.LoadAdministrators());
          this.store.dispatch(new assetsOwnerActions.LoadAssetsOwners());
          this.store.dispatch(new assetTypeActions.LoadAssetTypes());
          this.store.dispatch(new deviceTypeActions.LoadDeviceTypes()); //Just model Bumblebee
          this.store.dispatch(new assetActions.LoadAssets());
          this.store.dispatch(new alertTypeActions.LoadAlertTypes());
          this.store.select(mainReducers.selectFeatureAssetsEntitiesAndLoadState).subscribe(
            (result: { entities: Dictionary<Asset>, state: AssetState }) => {
              if(result.state == AssetState.LOAD_OK) {
                this.store.dispatch(new devicesActions.LoadDevices(result.entities))
              }
            }
          )
        } else {
          // this.router.navigate(['login']);
          this.store.dispatch(new bvuserActions.LoginBvuser());
        }
      }
    )
    
    this.$bvuser = this.store.select(mainReducers.selectFeatureBvuserEntity)
    this.store.select(mainReducers.selectFeatureBvuserAdminOrganizationsEntities)
    .subscribe(
      adminOrganizations => {
        if(adminOrganizations != null && adminOrganizations.length && adminOrganizations[0].photo) {
          this.isPhotoLoading = true;
          this.apiService.getOrganizationPhoto(adminOrganizations[0].identifier)
          .subscribe(
            res => this.organizationPhoto = this.sanitizePhotoSrc(res),
            () => this.isPhotoLoading = false
          )
        }
      }
    )

    this.$bvuserType = this.store.select(mainReducers.selectFeatureBvuserType) 
    this.$bvuserType.subscribe(userType => {
      if(userType === UserType.SUPER_ADMIN || userType === UserType.ADMIN) {
        this.navLinksBottom = this.navLinksAdmin;
      } else if(userType === UserType.ASSETS_OWNER) {
        this.navLinksBottom = this.navLinksAssetOwner;
      }
    })
  }

  public sanitizePhotoSrc(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.organizationPhoto = this.sanitizer.bypassSecurityTrustUrl(reader.result.toString());
      this.isPhotoLoading = false
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }
    
  ngAfterViewInit() {
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd)
    )
    .subscribe((event: NavigationEnd) => {
      this.mainContainer.nativeElement.scrollTo(0,0)
    });
  }



}
