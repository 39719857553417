import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { AssetsOwner } from "src/app/models/bvuser.model";
import { AssetsOwnerActions, AssetsOwnerActionTypes } from "./assets-owner.actions";

export enum AssetsOwnerState {
    NOT_STARTED, 
    LOADING_ASSETS_OWNERS,
    LOAD_ASSETS_OWNERS_OK,
    LOAD_ASSETS_OWNERS_KO, 
    ADDING_ASSETS_OWNER,
    ADD_ASSETS_OWNER_OK,
    ADD_ASSETS_OWNER_KO,
    UPDATING_ASSETS_OWNER, 
    UPDATE_ASSETS_OWNER_OK, 
    UPDATE_ASSETS_OWNER_KO, 
    SUSPENDING_ASSETS_OWNER, 
    SUSPEND_ASSETS_OWNER_OK,
    SUSPEND_ASSETS_OWNER_KO,
    ACTIVATING_ASSETS_OWNER, 
    ACTIVATE_ASSETS_OWNER_OK,
    ACTIVATE_ASSETS_OWNER_KO, 
    SWITCH_TO_ADMIN,
    SWITCH_TO_ADMIN_OK,
    SWITCH_TO_ADMIN_KO
}

export interface State extends EntityState<AssetsOwner> {
    isLoading: AssetsOwnerState;
    errorMsg: any
}

export const adapter: EntityAdapter<AssetsOwner> = createEntityAdapter<AssetsOwner>({
    selectId: (assetOwner: AssetsOwner) => assetOwner.identifier,
    sortComparer: (a: AssetsOwner, b: AssetsOwner) => a.firstName.localeCompare(b.firstName)
})

export const initialState: State = adapter.getInitialState({
    isLoading: AssetsOwnerState.NOT_STARTED,
    errorMsg: null
})

export function reducer(
    state = initialState, 
    action: AssetsOwnerActions
): State {
    switch(action.type) {
        case AssetsOwnerActionTypes.LoadAssetsOwners:
            return Object.assign({}, state, { 
                isLoading: AssetsOwnerState.LOADING_ASSETS_OWNERS, 
            });
        case AssetsOwnerActionTypes.LoadAssetsOwnersSuccess:
            return Object.assign({}, adapter.addAll(action.payload, state), { 
                isLoading: AssetsOwnerState.LOAD_ASSETS_OWNERS_OK, 
            })
        case AssetsOwnerActionTypes.LoadAssetsOwnersFailure:
            return Object.assign({}, state, { 
                isLoading: AssetsOwnerState.LOAD_ASSETS_OWNERS_KO,
                errorMsg: action.payload.error
            });
        case AssetsOwnerActionTypes.AddAssetsOwner:
            return Object.assign({}, state, {
                isLoading: AssetsOwnerState.ADDING_ASSETS_OWNER, 
            });
        case AssetsOwnerActionTypes.AddAssetsOwnerSuccess:
            return Object.assign({}, adapter.addOne(action.payload, state), {
                isLoading: AssetsOwnerState.ADD_ASSETS_OWNER_OK,
            });
        case AssetsOwnerActionTypes.AddAssetsOwnerFailure:
            return Object.assign({}, state, {
                isLoading: AssetsOwnerState.ADD_ASSETS_OWNER_KO, 
                errorMsg: action.payload.error
            });
        case AssetsOwnerActionTypes.UpdateAssetsOwner:
            return Object.assign({}, state, {
                isLoading: AssetsOwnerState.UPDATING_ASSETS_OWNER, 
            });
        case AssetsOwnerActionTypes.UpdateAssetsOwnerSuccess:
            return Object.assign({}, adapter.updateOne(action.payload, state), {
                isLoading: AssetsOwnerState.UPDATE_ASSETS_OWNER_OK, 
            });
        case AssetsOwnerActionTypes.UpdateAssetsOwnerFailure:
            return Object.assign({}, state, {
                isLoading: AssetsOwnerState.UPDATE_ASSETS_OWNER_KO,
                errorMsg: action.payload.error
            });
        case AssetsOwnerActionTypes.SuspendAssetsOwner:
            return Object.assign({}, state, {
                isLoading: AssetsOwnerState.SUSPENDING_ASSETS_OWNER,
            });
        case AssetsOwnerActionTypes.SuspendAssetsOwnerSuccess:
            return Object.assign({}, adapter.updateOne(action.payload, state), {
                isLoading: AssetsOwnerState.SUSPEND_ASSETS_OWNER_OK,
            });
        case AssetsOwnerActionTypes.SuspendAssetsOwnerFailure:
            return Object.assign({}, state, {
                isLoading: AssetsOwnerState.SUSPEND_ASSETS_OWNER_KO,
                errorMsg: action.payload.error
            });
        case AssetsOwnerActionTypes.ActivateAssetsOwner:
            return Object.assign({}, state, {
                isLoading: AssetsOwnerState.ACTIVATING_ASSETS_OWNER,
            });
        case AssetsOwnerActionTypes.ActivateAssetsOwnerSuccess:
            return Object.assign({}, adapter.updateOne(action.payload, state), {
                isLoading: AssetsOwnerState.ACTIVATE_ASSETS_OWNER_OK, 
            });
        case AssetsOwnerActionTypes.ActivateAssetsOwnerFailure:
            return Object.assign({}, state, {
                isLoading: AssetsOwnerState.ACTIVATE_ASSETS_OWNER_KO,
                errorMsg: action.payload.error
            });
        case AssetsOwnerActionTypes.ChangeRoleAsAdmin:
            return Object.assign({}, state, {
                isLoading: AssetsOwnerState.SWITCH_TO_ADMIN, 
            });
        case AssetsOwnerActionTypes.ChangeRoleAsAdminSuccess:
            return Object.assign({}, adapter.removeOne(action.payload.identifier, state), {
                isLoading: AssetsOwnerState.SWITCH_TO_ADMIN_OK,  
            });
        case AssetsOwnerActionTypes.ChangeRoleAsAdminFailure:
            return Object.assign({}, state, {
                isLoading: AssetsOwnerState.SWITCH_TO_ADMIN_KO, 
                errorMsg: action.payload.error
            });
        default:
            return state;
    }
}