import { Action } from '@ngrx/store';
import { AssetType } from 'src/app/models/asset-type.model';

export enum AssetTypeActionTypes {
  LoadAssetTypes = '[AssetType] Load AssetTypes',
  LoadAssetTypesSuccess = '[AssetType] Load AssetTypes Success',
  LoadAssetTypesFailure = '[AssetType] Load AssetTypes Failure',
}

export class LoadAssetTypes implements Action {
  readonly type = AssetTypeActionTypes.LoadAssetTypes;
}

export class LoadAssetTypesSuccess implements Action {
  readonly type = AssetTypeActionTypes.LoadAssetTypesSuccess;
  constructor(public payload: AssetType[]) { }
}

export class LoadAssetTypesFailure implements Action {
  readonly type = AssetTypeActionTypes.LoadAssetTypesFailure;
  constructor(public payload: { error: any }) { }
}

export type AssetTypeActions = LoadAssetTypes | LoadAssetTypesSuccess | LoadAssetTypesFailure;

