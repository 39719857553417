import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './containers/login/login.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { AssetDetailComponent } from './containers/asset-detail/asset-detail.component';
import { DeviceMaintenanceComponent } from './containers/device-maintenance/device-maintenance.component';
import { PageNotFoundComponent } from './containers/page-not-found/page-not-found.component';
import { AdministratorsComponent } from './containers/administrators/administrators.component';
import { TechniciansComponent } from './containers/technicians/technicians.component';
import { SettingsComponent } from './containers/settings/settings.component';
import { OrganizationComponent } from './containers/organization/organization.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard-assets', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  { path: 'dashboard-assets', component: DashboardComponent,
    /*children: [
      {
        path: '',
        children: [
          {
            path: 'asset/:identifier',
            component: AssetDetailComponent
          }
        ]
      }
    ]*/
  },
  { path: 'dashboard-assets/asset/:identifier', component: AssetDetailComponent },
  { path: 'device-maintenance', component: DeviceMaintenanceComponent },
  { path: 'organizations', component: OrganizationComponent },
  { path: 'administrators', component: AdministratorsComponent },
  { path: 'technicians', component: TechniciansComponent },
  { path: 'settings', component: SettingsComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true }) ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
