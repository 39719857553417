
import { BvuserActions, BvuserActionTypes } from './bvuser.actions';
import { Bvuser, PrefState, UserPreferences, UserType } from '../../models/bvuser.model'
import { AccessToken } from 'src/app/services/auth-service.service';

export interface State {
  bvuserEntity: Bvuser,
  bvuserType: UserType,
  isAuthenticated: boolean,
  accessToken: AccessToken,
  errorMessage: string,
  preferences: UserPreferences,
  prefState: PrefState
}

export const initialState: State = {
  bvuserEntity: null,
  bvuserType: null,
  isAuthenticated: false,
  accessToken: null,
  errorMessage: null,
  preferences: null,
  prefState: null
};

export function reducer(state = initialState, action: BvuserActions): State {
  switch (action.type) {
    //Load Bvuser
    case BvuserActionTypes.LoadBvuser:
      return state;

    case BvuserActionTypes.LoadBvuserSuccess, BvuserActionTypes.UpdateBvuser:
      const bvuser: Bvuser = action.payload;
      const userType: UserType = 
            bvuser.identifier.startsWith('assets_monitoring@administrator')? UserType.SUPER_ADMIN:
            bvuser.asAdmin? UserType.ADMIN:
            bvuser.asSupervisor? UserType.SUPERVISOR:
            UserType.ASSETS_OWNER;
      return Object.assign({}, state, {
        bvuserEntity: bvuser,
        bvuserType: userType
      });

    case BvuserActionTypes.LoadBvuserFailure:
      return Object.assign({}, state, {
        bvuserEntity: null
      });

    // Login    
    case BvuserActionTypes.LoginBvuser:
      return state;

    case BvuserActionTypes.LoginBvuserSuccess:
      const accessTokenLogin: AccessToken = action.payload;
      return Object.assign({}, state, {
        accessToken: accessTokenLogin,
        isAuthenticated: true,
        errorMessage: null
      });

    case BvuserActionTypes.LoginBvuserFailure:
      return Object.assign({}, state, {
        accessToken: null,
        isAuthenticated: false,
        errorMessage: 'Invalid email and/or password'
      });

    // Logout
    case BvuserActionTypes.LogoutBvuser:
      return Object.assign({}, state, { // return initial state ?
        accessToken: null,
        isAuthenticated: false,
        errorMessage: null
      });
    
    // Load Token from storage    
    case BvuserActionTypes.LoadTokenFromStorage:
      return state;

    case BvuserActionTypes.LoadTokenFromStorageSuccess:
      const accessTokenStorage: AccessToken = action.payload;
      return Object.assign({}, state, {
        accessToken: accessTokenStorage,
        isAuthenticated: true,
        errorMessage: null
      });

    case BvuserActionTypes.LoadTokenFromStorageFailure:
      return Object.assign({}, state, {
        accessToken: null,
        isAuthenticated: false,
        //errorMessage: 'Your auth token has expired'
      });

    // User preferences
    case BvuserActionTypes.LoadUserPreferences: 
      return state;

    case BvuserActionTypes.LoadUserPreferencesSuccess: 
      return Object.assign({}, state, {
          preferences: action.payload,
          prefState: PrefState.CREATED
      });
    
    case BvuserActionTypes.LoadUserPreferencesFailure: {
      const errorMessage = action.payload.error
      return Object.assign({}, state, {
          preferences: action.payload,
          prefState: PrefState.ERROR,
          errorMessage: errorMessage
      });
    }
    case BvuserActionTypes.UpdateUserPreferences: 
      return state;

    case BvuserActionTypes.UpdateUserPreferencesSuccess:
      return Object.assign({}, state, {
          preferences: action.payload,
          prefState: PrefState.UPDATED
      });

    case BvuserActionTypes.UpdateUserPreferencesFailure: {
      const errorMessage = action.payload.error
      return Object.assign({}, state, {
          preferences: action.payload,
          prefState: PrefState.ERROR,
          errorMessage: errorMessage
      });
    }
    case BvuserActionTypes.CreateOrganization, BvuserActionTypes.UpdateOrganization: 
      return state;
    case BvuserActionTypes.CreateOrganizationFailure, BvuserActionTypes.UpdateOrganizationFailure: {
      const errorMessage = action.payload.error
      return Object.assign({}, state, {
        errorMessage: errorMessage
      });
    }
    default:
      return state;
    }
}

export const getBvUserEntity = (state: State) => state.bvuserEntity;
export const getBvuserPreferences = (state: State) => state.preferences;
export const getBvUserIsAuthenticated = (state: State) => state.isAuthenticated;
export const getBvUserErrorMesage = (state: State) => state.errorMessage;
