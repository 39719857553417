import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Association } from 'src/app/models/association.model';
import { DeviceType } from 'src/app/models/device-type.model';
import { Device } from 'src/app/models/device.model';
import { Organization } from 'src/app/models/organization.model';
import * as mainReducers from '../../reducers/index';

@Component({
  selector: 'app-device-dialog',
  templateUrl: './device-dialog.component.html',
  styleUrls: ['./device-dialog.component.scss']
})
export class DeviceDialogComponent implements OnInit {

  deviceForm: FormGroup
  actionType: string = 'new'; // 'new' or 'edit'
  errorMsg: string = null
  deviceType: DeviceType 
  hasAssociation: boolean 
  public deviceTypes$: Observable<DeviceType[]>
  public organizations$: Observable<Organization[]>

  constructor(
    public dialogRef: MatDialogRef<DeviceDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: Store<mainReducers.State>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.deviceTypes$ = this.store.select(mainReducers.selectFeatureDeviceTypeEntities);
    this.organizations$ = this.store.select(mainReducers.selectFeatureBvuserManagingOrganizations);
    if(this.data.action) {
      this.actionType = this.data.action;
    }
    if(this.data.entity) {
      if(this.data.entity.associations != null && this.data.entity.associations.length) {
        this.hasAssociation = false
        this.data.entity.associations.forEach((association: Association) => {
            if(association.end == null || Date.parse(association.end) > Date.now()) {
              this.hasAssociation = true;
            }
        })
      }

      this.deviceForm = this.fb.group({
        serial: [this.data.entity.serial, Validators.required],
        deviceType: [this.data.entity.deviceType?this.data.entity.deviceType.identifier:'', Validators.required],
        ownerOrganization: [this.data.entity.ownerOrganization?this.data.entity.ownerOrganization.identifier:'', Validators.required],
      })
    } else {
      this.deviceForm = this.fb.group({
        serial: ['', Validators.required],
        deviceType: ['', Validators.required],
        ownerOrganization: ['', Validators.required]
      })
    }
    
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    this.errorMsg = ''
    if(this.hasAssociation) {
      this.errorMsg = 'Please dissociate this device before change organization.\n';
    }
    let ownerOrganization = {}
    if(!this.deviceForm.value.ownerOrganization) {
      this.errorMsg = this.errorMsg + 'Please choose an organization.\n';
      ownerOrganization = {}
    } else {
      ownerOrganization = { identifier: this.deviceForm.value.ownerOrganization }
    }
    let deviceType: { identifier?: string } = {}
    if(!this.deviceForm.value.deviceType) {
      this.errorMsg = this.errorMsg + 'Please confirm the device type.\n';
      deviceType = {}
    } else {
      deviceType = { identifier: this.deviceForm.value.deviceType }
    }
    let serialNumber: string = this.deviceForm.value.serial.toLowerCase()
    if(this.actionType == 'new' && deviceType.identifier && !deviceType.identifier.endsWith('valve') && serialNumber.length != 16) {
      this.errorMsg = this.errorMsg + 'Please make sure the serial length is 16.\n'
    }

    let create: Partial<Device> = {
      serial: serialNumber,
      deviceType: deviceType,
      ownerOrganization: ownerOrganization
    }
    console.info('device: ', create)
    if(this.errorMsg.length == 0) {
      if(this.actionType == 'edit') {
        create.identifier = this.data.entity.identifier
      }
      this.dialogRef.close(create)
    }
  }

}
