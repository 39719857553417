import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Asset } from '../../models/asset.model';
import { AssetActions, AssetActionTypes } from './asset.actions';

export enum AssetState {
  NOT_STARTED, 
  LOADING,
  LOAD_OK,
  LOAD_KO,
  SELECTED,
  UNSELECTED,
  ADDING,
  ADD_OK,
  ADD_KO,
  UPDATING, 
  UPDATE_OK, 
  UPDATE_KO,
  DELETING, 
  DELETE_OK,
  DELETE_KO,
  UNDEFINED,
}

export interface State extends EntityState<Asset> {
  // additional entities state properties
  isLoading: AssetState
  selectedId: string
}

export const adapter: EntityAdapter<Asset> = createEntityAdapter<Asset>({
  selectId: selectAssetId,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  isLoading: AssetState.NOT_STARTED,
  selectedId: null
});

export function selectAssetId(asset: Asset): string {
  //In this case this would be optional since primary key is id
  return asset.identifier;
}

export function reducer(
  state = initialState,
  action: AssetActions
): State {
  switch (action.type) {
    case AssetActionTypes.LoadAssets: {
      return Object.assign({}, state, {
        isLoading: AssetState.LOADING,
      });
    }

    case AssetActionTypes.LoadAssetsSuccess: {
      return Object.assign({}, adapter.addAll(action.payload, state), {
        isLoading: AssetState.LOAD_OK,
      });
    }

    case AssetActionTypes.LoadAssetsFailure: {
      return Object.assign({}, state, {
        isLoading: AssetState.LOAD_KO,
      });
    }

    case AssetActionTypes.SetSelectedAsset: {
      return Object.assign({}, state, {
        selectedId: action.payload.identifier,
        isLoading: AssetState.SELECTED
      });
    }

    case AssetActionTypes.UnsetSelectedAsset: {
      return Object.assign({}, state, {
        selectedId: null,
        isLoading: AssetState.UNSELECTED
      });
    }

    // Add
    case AssetActionTypes.AddAsset: {
      return Object.assign({}, state, {
        isLoading: AssetState.ADDING,
      });
    }

    case AssetActionTypes.AddAssetSuccess: {
      return Object.assign({}, adapter.addOne(action.payload, state), {
        isLoading: AssetState.ADD_OK,
      });
    }

    case AssetActionTypes.AddAssetFailure: {
      return Object.assign({}, state, {
        isLoading: AssetState.ADD_KO,
      });
    }

    // Update
    case AssetActionTypes.UpdateAsset: {
      return Object.assign({}, state, {
        isLoading: AssetState.UPDATING,
      });
    }

    case AssetActionTypes.UpdateAssetSuccess: {
      return Object.assign({}, adapter.updateOne(action.payload, state), {
        isLoading: AssetState.UPDATE_OK,
      });
    }

    case AssetActionTypes.UpdateAssetFailure: {
      return Object.assign({}, state, {
        isLoading: AssetState.UPDATE_KO,
      });
    }

    // Delete
    case AssetActionTypes.DeleteAsset: {
      return Object.assign({}, state, {
        isLoading: AssetState.DELETING,
      });
    }

    case AssetActionTypes.DeleteAssetSuccess: {
      return Object.assign({}, adapter.removeOne(action.payload.id, state), {
        isLoading: AssetState.DELETE_OK,
      });
    }

    case AssetActionTypes.DeleteAssetFailure: {
      return Object.assign({}, state, {
        isLoading: AssetState.DELETE_KO,
      });
    }

    // NOT USED
    case AssetActionTypes.UpsertAsset: {
      return Object.assign({}, adapter.upsertOne(action.payload.asset, state), {
        isLoading: AssetState.UNDEFINED,
      });
    }

    case AssetActionTypes.AddAssets: {
      return Object.assign({}, adapter.addMany(action.payload.assets, state), {
        isLoading: AssetState.UNDEFINED,
      });
    }

    case AssetActionTypes.UpsertAssets: {
      return Object.assign({}, adapter.upsertMany(action.payload.assets, state), {
        isLoading: AssetState.UNDEFINED,
      });
    }

    case AssetActionTypes.UpdateAssets: {
      return Object.assign({}, adapter.updateMany(action.payload.assets, state), {
        isLoading: AssetState.UNDEFINED,
      });
    }

    case AssetActionTypes.DeleteAssets: {
      return Object.assign({}, adapter.removeMany(action.payload.ids, state), {
        isLoading: AssetState.UNDEFINED,
      });
    }

    case AssetActionTypes.ClearAssets: {
      return Object.assign({}, adapter.removeAll(state), {
        isLoading: AssetState.UNDEFINED,
      });
    }

    default: {
      return Object.assign({}, state, {
        isLoading: AssetState.UNDEFINED,
      });
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
