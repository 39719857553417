import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import * as mainReducers from '../../reducers/index';
import { Organization } from 'src/app/models/organization.model';
import { AssetType } from 'src/app/models/asset-type.model';
import { Asset } from 'src/app/models/asset.model';

@Component({
  selector: 'app-asset-dialog',
  templateUrl: './asset-dialog.component.html',
  styleUrls: ['./asset-dialog.component.scss']
})
export class AssetDialogComponent implements OnInit {

  ASSET_TYPE_OIL_WELL_ID: string = "assets_monitoring@asset_type-gvvmixovzg";
  assetForm: FormGroup

  actionType: string = 'new'
  asset: Asset
  organizations$: Observable<Organization[]>
  assetTypes$: Observable<AssetType[]>
  assetTypes: AssetType[]

  constructor(
    public dialogRef: MatDialogRef<AssetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private fb: FormBuilder,
    private store: Store<mainReducers.State>,
    ) {}

  ngOnInit() {
    if (this.data.action) { 
      this.actionType = this.data.action
    }

    if (this.data.asset) {
      this.assetForm = this.fb.group({
        name: [this.data.asset.reference, Validators.required],
        //identifier: [this.data.asset.id, Validators.required], //identifier: [{value: this.data.asset.id, disabled: true}, Validators.required],
        // type: [this.data.asset.type.identifier],
        organization: [this.data.asset.organization.identifier],
        location: this.fb.group({
          latitude: [this.data.asset.latitude],
          longitude: [this.data.asset.longitude]
        })
      });
    } else {
      this.assetForm = this.fb.group({
        name: ['', Validators.required],
        //identifier: ['', Validators.required],
        // type: [''],
        organization: [''],
        location: this.fb.group({
          latitude: [''],
          longitude: ['']
        })
      });
    }
      
    this.organizations$ = this.store.select(mainReducers.selectFeatureBvuserManagingOrganizations)
    this.assetTypes$ = this.store.select(mainReducers.selectFeatureAssetTypeEntities)
    this.assetTypes$
      .subscribe(
        res => this.assetTypes = res
      )
    
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    let asset: Asset = {
      reference: this.assetForm.value.name,
      //id: this.assetForm.value.identifier,
      organization: {identifier: this.assetForm.value.organization},
      type: this.assetTypes
                .filter(assetType => (assetType.identifier == this.ASSET_TYPE_OIL_WELL_ID))[0]
      ,
      latitude: this.assetForm.value.location.latitude,
      longitude: this.assetForm.value.location.longitude
    }

    if (this.actionType == 'edit') {
      asset.identifier = this.data.asset.identifier
      this.dialogRef.close(Object.assign(this.data.asset, asset))
    } else {
      asset.id = asset.identifier
      delete asset.identifier
      this.dialogRef.close(asset)
    }
    
  }

}
