import { Action } from "@ngrx/store";
import { SortFilterDevice } from "src/app/models/device.model";

// Pre-define
export enum SortFilterActionTypes {
    SortFilterAdmin = '[SortFilter] Sort and Filter Administrator',
    SortFilterAsset = '[SortFilter] Sort and Filter Asset',
    SortFilterDevice = '[SortFilter] Sort and Filter Device',
    SortFilterMeasurementPoint = '[SortFilter] Sort and Filter Measurement Point',
    SortFilterOrganization = '[SortFilter] Sort and Filter Organization',
    SortFilterTech = '[SortFilter] Sort and Filter Technician',
}

export class SortAndFilterDevice implements Action {
    readonly type = SortFilterActionTypes.SortFilterDevice
    constructor(public payload: SortFilterDevice) {}
}

export type SortFilterActions = 
    SortAndFilterDevice;
  