import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { concatMap, catchError, map } from "rxjs/operators";
import { AssetsOwner } from "src/app/models/bvuser.model";
import { ApiService } from "src/app/services/api-service.service";
import { ActivateAssetsOwnerFailure, ActivateAssetsOwnerSuccess, AddAssetsOwnerFailure, AddAssetsOwnerSuccess, AssetsOwnerActions, AssetsOwnerActionTypes, ChangeRoleAsAdmin, ChangeRoleAsAdminFailure, ChangeRoleAsAdminSuccess, LoadAssetsOwnersFailure, LoadAssetsOwnersSuccess, SuspendAssetsOwnerFailure, SuspendAssetsOwnerSuccess, UpdateAssetsOwnerFailure, UpdateAssetsOwnerSuccess } from "./assets-owner.actions";

@Injectable()
export class AssetsOwnersEffects {

    @Effect()
    loadAssetsOwners$ = this.actions$.pipe(
        ofType(AssetsOwnerActionTypes.LoadAssetsOwners),
        concatMap(() =>
            this.apiService.getAssetsOwners()
            .pipe(
                map((assetOwners: AssetsOwner[]) => new LoadAssetsOwnersSuccess(assetOwners)),
                catchError(error => of(new LoadAssetsOwnersFailure({ error })))
            )
        )
    )

    @Effect()
    addAssetsOwner$ = this.actions$.pipe(
        ofType(AssetsOwnerActionTypes.AddAssetsOwner),
        map(action => action.payload),
        concatMap((assetsOwner: AssetsOwner) => 
            this.apiService.addAssetsOwner(assetsOwner)
            .pipe(
                map((newAssetsOwner: AssetsOwner) => new AddAssetsOwnerSuccess(newAssetsOwner)),
                catchError(error => of(new AddAssetsOwnerFailure({ error })))
            )
        )
    ) 

    @Effect()
    updateAssetsOwner$ = this.actions$.pipe(
        ofType(AssetsOwnerActionTypes.UpdateAssetsOwner),
        map(action => action.payload),
        concatMap((assetsOwner: AssetsOwner) => 
            this.apiService.updateAssetsOwner(assetsOwner)
            .pipe(
                map((newAssetsOwner: AssetsOwner) => new UpdateAssetsOwnerSuccess({id: newAssetsOwner.identifier, changes: newAssetsOwner})),
                catchError(error => of(new UpdateAssetsOwnerFailure({ error })))
            )
        )
    ) 

    @Effect()
    suspendAssetsOwner$ = this.actions$.pipe(
        ofType(AssetsOwnerActionTypes.SuspendAssetsOwner),
        map(action => action.payload),
        concatMap((assetsOwnerId: string) => 
            this.apiService.suspendAssetsOwner(assetsOwnerId)
            .pipe(
                map((newAssetsOwner: AssetsOwner) => new SuspendAssetsOwnerSuccess({id: newAssetsOwner.identifier, changes: newAssetsOwner})),
                catchError(error => of(new SuspendAssetsOwnerFailure({ error })))
            )
        )
    ) 

    @Effect()
    activateAssetsOwner$ = this.actions$.pipe(
        ofType(AssetsOwnerActionTypes.ActivateAssetsOwner),
        map(action => action.payload),
        concatMap((assetsOwnerId: string) => 
            this.apiService.activateAssetsOwner(assetsOwnerId)
            .pipe(
                map((newAssetsOwner: AssetsOwner) => new ActivateAssetsOwnerSuccess({id: newAssetsOwner.identifier, changes: newAssetsOwner})),
                catchError(error => of(new ActivateAssetsOwnerFailure({ error })))
            )
        )
    ) 

    @Effect()
    changeRoleAsAdmin$ = this.actions$.pipe(
        ofType(AssetsOwnerActionTypes.ChangeRoleAsAdmin),
        map(action => action.payload),
        concatMap((assetsOwner: AssetsOwner) => 
            this.apiService.updateAssetsOwner(assetsOwner)
            .pipe(
                map((res: AssetsOwner) => new ChangeRoleAsAdminSuccess(res)),
                catchError(error => of(new ChangeRoleAsAdminFailure({ error })))
            )
        )
    ) 

    constructor(private actions$: Actions<AssetsOwnerActions>, private apiService: ApiService) {}

}

