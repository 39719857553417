import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as mainReducers from '../../reducers';
import * as bvuserActions from '../../store/bvuser/bvuser.actions';
import { Observable } from 'rxjs';

// Deprecated: replace by sirius-authorization-server login page 
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  $isAuthenticated: Observable<boolean>
  $errorMessage: Observable<string>

  constructor(
    private store: Store<mainReducers.State>,
  ) {}

  
  ngOnInit() {
    this.$isAuthenticated = this.store.select(mainReducers.selectFeatureBvuserIsAuthenticated)
    this.$errorMessage = this.store.select(mainReducers.selectFeatureBvuserErrorMessage)
  }
   
  submit() {
    if (this.form.valid) {
      // this.store.dispatch(new bvuserActions.LoginBvuser({'username' : this.form.value.email, 'password': this.form.value.password }))
    }
  }
}
