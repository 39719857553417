import { Action } from '@ngrx/store';
import { DeviceType } from 'src/app/models/device-type.model';

export enum DeviceTypeActionTypes {
  LoadDeviceTypes = '[DeviceType] Load DeviceTypes',
  LoadDeviceTypesSuccess = '[DeviceType] Load DeviceTypes Success',
  LoadDeviceTypesFailure = '[DeviceType] Load DeviceTypes Failure',
}

export class LoadDeviceTypes implements Action {
  readonly type = DeviceTypeActionTypes.LoadDeviceTypes;
}

export class LoadDeviceTypesSuccess implements Action {
  readonly type = DeviceTypeActionTypes.LoadDeviceTypesSuccess;
  constructor(public payload: DeviceType[]) { }
}

export class LoadDeviceTypesFailure implements Action {
  readonly type = DeviceTypeActionTypes.LoadDeviceTypesFailure;
  constructor(public payload: { error: any }) { }
}

export type DeviceTypeActions = LoadDeviceTypes | LoadDeviceTypesSuccess | LoadDeviceTypesFailure;

