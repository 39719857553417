import { Component, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';

import * as mainReducers from '../../reducers/index';
import { ApiService } from '../../services/api-service.service';
import { AlertTemplate } from '../../models/alert-template.model';
import { MeasurementPoint } from '../../models/measurement-point.model';
import { AlertType } from '../../models/alert-type.model';
import { ALERT_COLOR_LABELS } from '../../shared/alert-color-labels'
import { GlobalVariablesService } from 'src/app/shared/global-variables.service';
import { PRESSURE_UNITS, TEMPERATURE_UNITS, Utils } from 'src/app/shared/common';

@Component({
  selector: 'alert-settings-dialog',
  templateUrl: './alert-settings-dialog.component.html',
  styleUrls: ['./alert-settings-dialog.component.scss']
})
export class AlertSettingsDialogComponent implements OnInit {

  dynamicForm: FormArray
  alertTypes$: Observable<AlertType[]>
  alertTemplates$: Observable<AlertTemplate[]>
  currentAlertTemplates: AlertTemplate[]
  suspendedAlertTemplates: AlertTemplate[]
  colorLabels = ALERT_COLOR_LABELS
  savingStatus: string = null
  savingStatusMessage: string = null
  units: string[] = ['Bar', '°C']
  deviceType: string
  hasActiveAlert: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AlertSettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action: string, measurementPoint: MeasurementPoint, alertTemplate: AlertTemplate}, 
    private fb: FormBuilder,
    private apiService: ApiService,
    private globalVariables: GlobalVariablesService,
    private store: Store<mainReducers.State>
  ) {}

  ngOnInit() {
    this.deviceType = this.data.measurementPoint.lastMeasurement.device_type;
    this.alertTypes$ = this.store.select(mainReducers.selectFeatureAlertTypeEntities)
    this.alertTemplates$ = this.apiService.getAlertTemplatesFromMeasurementPoint(this.data.measurementPoint.identifier)
    if(this.globalVariables.getPressureUnit() != PRESSURE_UNITS[0]) {
      this.units[0] = 'Psi';
    }
    if(this.globalVariables.getTemperatureUnit() != TEMPERATURE_UNITS[0]) {
      this.units[1] = '°F';
    }
    
    this.dynamicForm = this.fb.array(
      []
    )

    this.alertTemplates$
      .subscribe(
        res => {
          this.currentAlertTemplates = res.filter(alertTemplate => alertTemplate.active)
          this.suspendedAlertTemplates = res.filter(alertTemplate => !alertTemplate.active)
          this.loadItems(this.currentAlertTemplates)
        }
      )
    
  }

  addItem(alertTemplate: AlertTemplate = null) {
    let alertTemplateFormGroup: FormGroup = this.fb.group({
      label: ['', Validators.required],
      operator: ['', Validators.required],
      value: ['', Validators.required],
      unit: ['', Validators.required]
    });

    if(alertTemplate !== null) {
      if (alertTemplate.parameters) {
        let operatorParameter = alertTemplate.parameters
          .filter(parameter => parameter.key == "comparator")
          .shift()

        let valueParameter = alertTemplate.parameters
          .filter(parameter => parameter.key == "threshold")
          .shift()

        let unitParameter = this.deviceType=='2' && alertTemplate.alertType && alertTemplate.alertType.identifier 
          && alertTemplate.alertType.identifier=='assets_monitoring@alert_type-threshold_2'?'temperature_unit':'pressure_unit';
        
        let value = valueParameter.value
        if(this.deviceType == '2') {
          if(this.units[0] == 'Psi' && unitParameter == 'pressure_unit') {
            value = Utils.barToPsi(Number(valueParameter.value)) + '';
          }
          if(this.units[1] == '°F' && unitParameter == 'temperature_unit') {
            value = Utils.celsiusToFahrenheit(Number(valueParameter.value)) + '';
          }
        } else if(this.deviceType == '1' && this.units[1] == '°F') {
          value = Utils.celsiusToFahrenheit(Number(valueParameter.value)) + '';
        }
          
        if (operatorParameter && valueParameter) {
          alertTemplateFormGroup = this.fb.group({
            label: [alertTemplate.label, Validators.required],
            operator: [operatorParameter.value, Validators.required],
            value: [value, Validators.required],
            unit: [unitParameter, Validators.required]
          });
        } else {
          alertTemplateFormGroup.patchValue({label: alertTemplate.label})
        }
      } else {
        alertTemplateFormGroup.patchValue({label: alertTemplate.label})
      }
    }
    
    this.dynamicForm.push(alertTemplateFormGroup)
  }

  loadItems(alertTemplates: AlertTemplate[]) {
    alertTemplates.forEach((alertTemplate: AlertTemplate) => {
      if (alertTemplate.identifier) {
          this.addItem(alertTemplate)
        }
      } 
    )
  }

  // we deactive alert template instead of deletion
  deleteAlertTemplate(alertTemplateIndex: number) {
    if (this.currentAlertTemplates[alertTemplateIndex]) {
      let alertTemplate = this.currentAlertTemplates[alertTemplateIndex]
      // If has active alert of this alert template
      this.hasActiveAlert = false;
      if(this.data.measurementPoint.activeAlerts && this.data.measurementPoint.activeAlerts.length) {
        this.data.measurementPoint.activeAlerts.forEach(activeAlert => {
          if(activeAlert.state == 'active' 
              && activeAlert.alertTemplate && activeAlert.alertTemplate.identifier == alertTemplate.identifier) {
                this.hasActiveAlert = true;
          }
        })
      }
      if(!this.hasActiveAlert) {
        alertTemplate.active = false;
        this.apiService.updateAlertTemplateToMeasurementPoint(alertTemplate, this.data.measurementPoint.identifier)
        .subscribe(
          () => {
            this.currentAlertTemplates.splice(alertTemplateIndex, 1)
            this.dynamicForm.removeAt(alertTemplateIndex)
          },
          err => console.log(err)
        )
      }
    } else {
      this.dynamicForm.removeAt(alertTemplateIndex)
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    this.savingStatus = null
    this.savingStatusMessage = null
    this.dynamicForm.controls.forEach((subForm: FormGroup, index) => { 
        let toCreate: boolean = true;
        let alertTemplate: AlertTemplate
        if(!this.currentAlertTemplates[index]) {
          const toUpdate: AlertTemplate = this.suspendedAlertTemplates.filter(res => res.label == subForm.controls.label.value)[0]
          if(toUpdate) {
            toCreate = false;
            alertTemplate = {
              identifier: toUpdate.identifier,
              label: toUpdate.label,
              active: true,
              alertType: toUpdate.alertType
            }
          } else {
            toCreate = true;
            alertTemplate = {
              label: subForm.controls.label.value,
              active: true,
              alertType: null
            } 
          }
        } else {
          toCreate = false;
          alertTemplate = {
            identifier: this.currentAlertTemplates[index].identifier,
            label: subForm.controls.label.value,
            active: this.currentAlertTemplates[index].active,
            alertType: this.currentAlertTemplates[index].alertType
          }
        }

        // convert back to default unit Bar/°C before updating alert settings parameters to backend
        let threshold = subForm.controls.value.value
        if(this.deviceType == '2') {
          if(this.units[0] == 'Psi' && subForm.controls.units.value == 'pressure_unit') {
            threshold = Utils.psiToBar(Number(subForm.controls.value.value)) + '';
          }
          if(this.units[1] == '°F' && subForm.controls.units.value == 'temperature_unit') {
            threshold = Utils.fahrenheitToCelsius(Number(subForm.controls.value.value)) + '';
          }
        } else if(this.deviceType == '1' && this.units[1] == '°F') {
          threshold = Utils.fahrenheitToCelsius(Number(subForm.controls.value.value)) + '';
        }

        this.alertTypes$
          .pipe(
            map(alertTypes => Object.assign({}, alertTemplate, { 
              alertType: subForm.controls.unit && subForm.controls.unit.value == 'temperature_unit'? alertTypes.filter(alertType => alertType.label == 'threshold_2')[0].identifier : alertTypes.filter(alertType => alertType.label == 'threshold')[0].identifier })),
            switchMap(
              (res: AlertTemplate) => toCreate? this.apiService.addAlertTemplateToMeasurementPoint(res, this.data.measurementPoint.identifier) :
                                                this.apiService.updateAlertTemplateToMeasurementPoint(res, this.data.measurementPoint.identifier)
            ),
            switchMap(
              res => this.apiService.addOrUpdateAlertTemplateParameter({comparator: subForm.controls.operator.value, threshold: threshold}, res.identifier, this.data.measurementPoint.identifier),
              (alertTemplate, parameter) => alertTemplate
            )
          ).subscribe(
            res => {
              if(toCreate) {
                this.currentAlertTemplates.push(res)
              } else {
                this.currentAlertTemplates[index] = res
              }
              if (this.savingStatus !== "save-error") {
                this.savingStatus = "save-ok"
                this.savingStatusMessage = "Saved"
              }
            },
            err => {
              console.warn(err)
              this.savingStatus = "save-error"
              this.savingStatusMessage = "Error : " + err.error.message
            }
          )
      }
    )
    
  }

}
