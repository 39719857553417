import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'battery-indicator',
  templateUrl: './battery-indicator.component.html',
  styleUrls: ['./battery-indicator.component.scss']
})
export class BatteryIndicatorComponent implements OnInit {
  @Input() batteryLevel: number;

  threshold1: number = 50 // higher threshold
  threshold2: number = 30 // lower threshold

  constructor() { }

  ngOnInit() {
  }

}
