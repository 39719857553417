import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { MeasurementPoint } from '../../models/measurement-point.model';
import { MeasurementPointActions, MeasurementPointActionTypes } from './measurement-point.actions';

export interface State extends EntityState<MeasurementPoint> {
  // additional entities state properties
  isLoading: boolean
}

export const adapter: EntityAdapter<MeasurementPoint> = createEntityAdapter<MeasurementPoint>({
  selectId: selectMeasurementPointId,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  isLoading: false
});

export function selectMeasurementPointId(measurementPoint: MeasurementPoint): string {
  return measurementPoint.identifier;
}

export function reducer(
  state = initialState,
  action: MeasurementPointActions
): State {
  switch (action.type) {
    case MeasurementPointActionTypes.LoadMeasurementPoints: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case MeasurementPointActionTypes.LoadMeasurementPointsSuccess: {
      return Object.assign({}, adapter.addAll(action.payload, state), {
        isLoading: false,
      });
    }
    case MeasurementPointActionTypes.LoadMeasurementPointsFailure: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    case MeasurementPointActionTypes.AddMeasurementPoint: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case MeasurementPointActionTypes.AddMeasurementPointSuccess: {
      return Object.assign({}, adapter.addOne(action.payload, state), {
        isLoading: false,
      });
    }
    case MeasurementPointActionTypes.AddMeasurementPointFailure: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    case MeasurementPointActionTypes.UpdateMeasurementPoint: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case MeasurementPointActionTypes.UpdateMeasurementPointSuccess: {
      return Object.assign({}, adapter.updateOne(action.payload, state), {
        isLoading: false,
      });
    }
    case MeasurementPointActionTypes.UpdateMeasurementPointFailure: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    case MeasurementPointActionTypes.UpsertMeasurementPoint: {
      return adapter.upsertOne(action.payload.measurementPoint, state);
    }
    case MeasurementPointActionTypes.AddMeasurementPoints: {
      return adapter.addMany(action.payload.measurementPoints, state);
    }
    case MeasurementPointActionTypes.UpsertMeasurementPoints: {
      return adapter.upsertMany(action.payload.measurementPoints, state);
    }
    // Do Not delete, used
    case MeasurementPointActionTypes.UpdateMeasurementPointsUI: {
      return adapter.updateMany(action.payload, state);
    }
    case MeasurementPointActionTypes.DeleteMeasurementPoint: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case MeasurementPointActionTypes.DeleteMeasurementPointSuccess: {
      return Object.assign({}, adapter.removeOne(action.payload.id, state), {
        isLoading: false,
      });
    }
    case MeasurementPointActionTypes.UpdateMeasurementPointFailure: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    case MeasurementPointActionTypes.DeleteMeasurementPoints: {
      return adapter.removeMany(action.payload.ids, state);
    }
    case MeasurementPointActionTypes.ClearMeasurementPoints: {
      return adapter.removeAll(state);
    }
    case MeasurementPointActionTypes.AttachMeasurementPoint: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case MeasurementPointActionTypes.AttachMeasurementPointFailure: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    case MeasurementPointActionTypes.DetachMeasurementPoint: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }
    case MeasurementPointActionTypes.DetachMeasurementPointFailure: {
      return Object.assign({}, state, {
        isLoading: false,
      });
    }
    default: {
      return state;
    }
  }
}

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
