import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Organization } from 'src/app/models/organization.model';
import * as mainReducers from '../../reducers/index';

@Component({
  selector: 'app-organization-dialog',
  templateUrl: './organization-dialog.component.html',
  styleUrls: ['./organization-dialog.component.scss']
})
export class OrganizationDialogComponent implements OnInit {

  orgForm: FormGroup
  actionType: string = 'new'; // 'new' or 'edit';
  errorMsg: string = null;
  public organizations$: Observable<Organization[]>
  
  constructor(
    public dialogRef: MatDialogRef<OrganizationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<mainReducers.State>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    if(this.data.action) {
      this.actionType = this.data.action
    }
    if(this.data.entity) {
      this.orgForm = this.fb.group({
        name: [this.data.entity.name, Validators.required],
        partOf: [this.data.entity.partOf?[this.data.entity.partOf.identifier]:[], Validators.required]
      })
    } else {
      this.orgForm = this.fb.group({
        name: ['', Validators.required],
        partOf: ['', Validators.required]
      })
    }
    this.organizations$ = this.store.select(mainReducers.selectFeatureBvuserManagingOrganizations);
  }

  onCancel() {
    this.dialogRef.close()
  }

  onSave() {
    let partOf: Organization = {};
    if(this.orgForm.value.partOf.length != 1) {
      this.errorMsg = 'You should choose just one parent organization.'
      partOf = {}
    } else {
      this.errorMsg = null;
      partOf = this.orgForm.value.partOf.map((id: string) => { 
        let part_of: Organization = { identifier: id }
        return part_of
      })[0]
      if (this.actionType == 'edit' && partOf.identifier == this.data.entity.identifier) {
        this.errorMsg = 'You shouldn\'t choose the same organization as a parent organization.'
        partOf = {}
      }
    }
    let newOrg: Organization = {
      name: this.orgForm.value.name,
      partOf: partOf
    } 

    if(this.errorMsg == null) {
      if(this.actionType == 'edit') {
        newOrg.identifier = this.data.entity.identifier;
      }
      this.dialogRef.close(newOrg)
    }
  }

}
